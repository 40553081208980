.woocommerce-cart {
  .woocommerce {
    max-width: 1290px;
    margin: 0 auto;

    table.shop_table {
      border: 0;
      border-radius: 0;

      thead {
        font-size: 18px;

        @include md {
          display: none;
        }
      }

      tbody {
        @include md {
          border-top: 1px solid #e8e8e8;
        }

        .cart_item {
          @include md {
            border-bottom: 1px solid #dede;
            display: flex;
            flex-wrap: wrap;
            padding-left: 120px;
            position: relative;
            align-items: flex-start;
          }
        }
      }

      td {
        border-top: 1.5px solid #757882;
        border-bottom: 0;
        background-color: transparent !important;

        @include md {
          border-top: 0;
        }

        &::before {
          @include md {
            content: none;
          }
        }
      }

      .product-quantity {
        @include md {
          flex: 0 50%;
        }

        .qib-button-wrapper div.quantity.wqpmb_quantity {
          height: 50px;
          min-width: auto;
        }

        .qib-button {
          margin: 0;

          .qib-button {
            width: 30px;
            height: 50px;
            background-color: #F2F3F7;
            border: 0;
            color: $secondary-color;
            font-weight: bold;
            font-family: $primary-font;
            font-size: 15px;
            line-height: 24px;
          }

          .wqpmb_input_text {
            height: 50px !important;
            background-color: #F2F3F7 !important;
            border: 0 !important;
            margin: 0px 5px !important;
            color: $secondary-color;
            font-weight: bold;
            font-family: $primary-font;
            font-size: 15px !important;
            line-height: 24px !important;
          }
        }
      }

      .product-thumbnail {
        @include md {
          border-top: 0;
          left: 0;
          float: left;
          position: absolute;
          display: block;
          display: inline-block;
          text-align: left !important;
          max-width: 130px;
          background: #fff;
        }

        img {
          border: 2px solid #F2F3F7;
          width: 100px;
          height: 100px;
          object-fit: contain;
          padding: 10px;
        }
      }

      .product-name {
        @include md {
          text-align: left !important;
          border: 0;
          background-color: transparent;
          width: 96%;
        }

        a {
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.6px;
          text-decoration: none;

          @include md {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .product-category {
          display: block;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;

          @include md {
            font-size: 11px;
            line-height: 16px;
          }
        }

      }

      .product-price,
      .product-subtotal {
        span {
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.6px;
        }

        .text {
          display: block;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          font-weight: normal;
          text-align: left;
        }

        @include md {
          background-color: transparent;
        }
      }

      .product-price {
        @include md {
          padding-right: 30px;
        }
      }

      .product-subtotal {
        @include md {
          display: none;
        }
      }

      .product-remove {
        @include md {
          border: 0 !important;
          width: 90%;

        }
      }

      .remove {
        font-size: 0;
        width: 34px;
        height: 34px;
        margin: 0;
        position: relative;

        @include md {
          float: right;
          position: absolute;
          right: 30px;
          top: 30px;
        }

        &:after {
          content: '';
          width: 34px;
          height: 34px;
          display: block;
          font-weight: normal;
          font-family: $primary-font;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.5px;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url(../../assets/images/remove.svg);
        }

        &:hover {
          background-color: $bg-color;
        }
      }
    }

    .actions {
      border-top: 0;
      margin-top: 20px;

      label {
        display: none;
      }

      .coupon {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include md {
          width: 100%;
          flex-direction: column;
        }

        .input-text {
          width: 55%;

          @include md {
            width: 100% !important;
            margin-bottom: 10px !important;
          }
        }

        .btn {
          border-radius: 0;
          font-family: $primary-font;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.6px;

          @include md {
            width: 100%;
          }
        }
      }

      .btn {
        border-radius: 0;
        font-family: $primary-font;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.6px;
      }

      .button {
        @include md {
          font-size: 16px;
          line-height: 20px;
          color: #777;
          border-color: #777;
          font-weight: normal;
          background-color: transparent;
          padding: 0;
          width: 100% !important;
        }
      }

      .button[name=update_cart] {
        display: none;
      }
    }

    .button:disabled[disabled] {
      background: transparent;
      font-weight: normal;

      @include md {
        text-align: left;
        display: none !important;
      }
    }

    .cart-collaterals {
      display: flex;
      flex-direction: column-reverse;

      @include md {
        width: 90%;
        margin: 0 auto;
      }

      .cart_totals {
        align-self: flex-end;

        h2 {
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.7px;
          text-transform: uppercase;
        }

        th,
        td {
          border: 0;
          background: transparent;
        }

        tbody {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%;

          @include md {
            max-width: 100%;
          }

          tr {
            display: flex;
            justify-content: space-between;
            width: 100%;

            th {
              @include md {
                display: block;
              }
            }
          }
        }
      }

      .cross-sells {
        width: 100%;
        margin-bottom: 80px;

        &:before {
          width: 100%;
          left: 0;
          right: 0;
          content: '';
          background: #F4F4F7;
          display: block;
          position: absolute;
          height: 170%;
        }

        h2 {
          position: relative;
          margin-top: 80px;
          margin-bottom: 40px;
        }

        .products {
          width: 100%;
          display: flex;
          justify-content: space-between;

          @include md {
            flex-direction: column;
          }

          .product {
            width: 100%;
            float: left;
            margin: 0;
            max-width: 23%;

            @include md {
              max-width: 100% !important;
              margin-bottom: 20px !important;
            }

            .add_to_cart_button {
              background-color: $primary-color;
              font-size: 15px;
              position: relative;
              top: auto;
              left: 0;
              border-radius: 0;
              width: 100%;
              text-align: center;
              color: #fff;

              &:after {
                content: none;
              }
            }

            .wp-element-button.product_type_variable.add_to_cart_button {
              display: none !important;
            }
          }
        }
      }
    }

    .wc-proceed-to-checkout a.checkout-button {
      width: 70%;
      background-color: #FFB600 !important;
      border-radius: 0;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.6px;
      font-family: $primary-font;
      font-weight: bold;
      float: right;

      &:hover,
      &:active,
      &:focus {
        background-color: #FFCD51;
      }

      @include md {
        width: 100%;
      }
    }
  }

  .woocommerce-info {
    @include md {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 20px !important;
    }

    .button {
      @include md {
        flex: 100%;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.woofc-area.woofc-style-02 .woofc-area-bot .woofc-continue span {
  text-decoration: none;
  background: $primary-color;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  font-weight: bold;
  text-transform: none;
  font-family: $primary-font;
  font-size: 16px;
  line-height: 24px;
}

body .qib-button.qib-button-wrapper {
  margin: 0px 5px;

  @include md {
    float: left !important;
  }
}

span.xoo-cp-icon-check.xoo-cp-added {
  display: none !important;
}

a.added_to_cart.wc-forward {
  display: none;
}

.woocommerce-checkout {
  .wp-block-group__inner-container {
    h2 {
      padding-bottom: 20px;
      border-bottom: 1px solid;
      margin-bottom: 50px;

      @include md {
        margin-bottom: 20px;
      }
    }
  }

  .checkout-top {
    .woocommerce-info {
      background: transparent;
      padding: 0;

      &::before {
        display: none;
      }
    }

    .row {
      @include md {
        padding: 0;
      }
    }
  }

  h3 {
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include md {
      margin-bottom: 15px;
    }
  }

  .woocommerce-billing-fields,
  .shipping_address {
    label {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.5px;
      font-weight: normal;
      color: $secondary-color;
    }

    input,
    textarea {
      border: 1px solid #CCCDD1;
      border-radius: 0;
    }

  }

  h3#ship-to-different-address {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.6px;
    font-weight: normal;
    margin-left: 4px;

    label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 30px 0px;
    }
  }

  .row {
    @include md {
      padding: 0;
    }
  }
}

.woocommerce form .form-row textarea {
  border-radius: 0;
  border: 1px solid #CCCDD1;
  padding: 10px;
}

.woocommerce {
  .payment_methods {
    background-color: #fff;
  }

  #place_order {
    background-color: $third-color !important;
    width: 100%;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }

  .checkout-review-order-table-wrapper {
    .order-total {
      justify-content: space-between;
      display: flex;
      border-top: 1px solid;
      padding-top: 30px;
      width: 100%;

      .total-price {
        font-size: 30px;
      }

      p {
        font-weight: bold;
      }
    }
  }
}

.form-row.place-order {
  background: #fff;
}

.checkout-review-order-table-wrapper {
  .cart_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include md {
      display: table-row;
    }

    .product-name {
      display: flex;
      max-width: 85%;
      justify-content: space-between;

      @include md {
        max-width: 100%;
        border: 0;
      }
    }

    .product-total {
      @include md {
        border: 0;
      }

      span {
        font-weight: bold;
      }
    }

    .product-quantity {
      font-weight: normal;
      margin-left: 0;
      min-width: 50px;
    }
  }
}

.checkout .woocommerce-shipping-methods label {
  margin: 0;
  margin-left: 5px;
  font-weight: 400;
  margin-bottom: 0;
  position: relative;
  padding-left: 18px;
  cursor: pointer;

  &:before {
    position: absolute;
    left: 0;
    top: 6px;
    width: 12px;
    height: 12px;
    border: 1px solid #868686;
    content: "";
    border-radius: 50%;
  }
}

.checkout .woocommerce-shipping-methods .shipping_method {
  display: none;
}

#shipping_method li {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
}

.checkout .woocommerce-shipping-methods .shipping_method:checked+label:before {
  background: $primary-color;
  border-color: #000;
}

.validate-required input[type="checkbox"]:before {
  content: "";
  border: 0;
  background: #fff;
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #ccc;
}

.validate-required input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 1px solid #27262c;
  margin-right: 10px;
  position: relative;
  margin-top: 5px;
}

.validate-required.woocommerce-validated input[type="checkbox"]:after {
  content: "";
  background: transparent;
  position: absolute;
  display: inline-block;
  vertical-align: text-top;
  width: 5px;
  height: 10px;
  border-radius: 0;
  top: 3px;
  left: 7px;
  border: 2px solid $primary-color;
  border-top: none;
  border-left: none;
  transform: rotate(40deg);
}

.woocommerce-checkout #payment div.payment_box::before {
  content: none;
}

.checkout ul.payment_methods li {
  width: 100%;
  clear: both;
}

.checkout .woocommerce-checkout-payment .payment_methods li {
  padding: 17px 0 0;
  margin-bottom: 0;
}

.checkout .woocommerce-checkout-payment .payment_methods input.input-radio {
  display: none;
}

.checkout .woocommerce-checkout-payment .payment_methods li .input-radio:checked+label {
  color: #000;
}

.checkout .woocommerce-checkout-payment .payment_methods li>label {
  float: unset;
  margin: 0;
  padding-left: 18px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  color: #000;
}

.checkout .woocommerce-checkout-payment .payment_methods li>label:before {
  position: absolute;
  left: 0;
  top: calc(50% - 6px);
  width: 12px;
  height: 12px;
  border: 1px solid #868686;
  content: "";
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.checkout .woocommerce-checkout-payment .payment_methods li .input-radio:checked+label:before {
  background: $primary-color;
  border-color: #000;

  &:before {
    position: absolute;
    left: 0;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border: 1px solid #868686;
    content: "";
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}

#payment ul.wc_payment_methods li.wc_payment_method .tpay-amPmCheckbox input[type="checkbox"]+label {
  background: none !important;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    left: 0;
    top: 5px;
  }
}

#payment ul.wc_payment_methods li.wc_payment_method .tpay-amPmCheckbox input[type="checkbox"]:checked+label {
  &:before {
    content: "";
    background: transparent;
    position: absolute;
    display: inline-block;
    vertical-align: text-top;
    width: 6px;
    height: 12px;
    border-radius: 0;
    top: 7px;
    left: 7px;
    border: 2px solid $secondary-color;
    border-top: none;
    border-left: none;
    transform: rotate(40deg);
  }
}

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
  border-color: $primary-color;
}

.woocommerce-checkout #payment ul.payment_methods {
  padding: 0;
}

.thank-you {
  text-align: center;

  h3 {
    color: $secondary-color;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: $primary-font;
    font-weight: bold;
    text-transform: none;
    margin: 0;

    @include md {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.8px;
    }

    &.blue {
      color: $primary-color;
    }
  }
}

tr.woocommerce-shipping-totals.shipping {
  @include md {
    display: flex;
    flex-direction: column;
  }

  th {
    border: 0;
  }

}

.woocommerce table.shop_table {
  border: 0 !important;

  .cart_item {
    .product-name {
      display: flex;
      flex-direction: column;

      .ywp-product-image {
        min-width: 120px;
        width: 120px !important;
      }

      img {
        height: 100%;
        width: auto;
      }
    }

    .product-quantity {
      padding-left: 10px;
    }
  }
}

.cart-subtotal {
  @include md {
    td {
      min-width: 100px;
    }
  }
}

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th {
  border-top: 0 !important;
}

.woocommerce-page {
  display: flex;
  flex-direction: column;

  .col2-set {
    width: 60% !important;
    float: left;

    @include md {
      width: 100% !important;
    }

    .col-1,
    .col-2 {
      width: 100% !important;
      max-width: 100%;
    }
  }

  .woocommerce-shipping-totals {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .tpay-insidebg {
    margin: 0;
  }

  .woocommerce-checkout-review-order-table {
    width: 100%;

    tr.cart-subtotal {
      background: #f1f1f1;
    }

    #order_review_heading {
      margin-left: 20px;
      margin-top: 40px;
    }
  }

  .woocommerce-checkout-payment {
    width: 100%;
  }

  .form-row.place-order {
    margin: 0;
  }

  &.woocommerce-checkout {
    .woocommerce {
      max-width: 100%;
    }

    .wp-block-group__inner-container {
      position: relative;
    }

    .order-details-uniszki {
      position: absolute;
      right: 0;
      top: 100px;
      width: 400px;
    }

    .woocommerce-form-coupon-toggle {
      display: none;
    }

    #order_review {
      width: 35%;
      float: left;
      margin-left: 5%;

      @include md {
        width: 100%;
        margin-left: 0;
      }

      .order-total {
        background: #f1f1f1;
      }
    }
  }
}

.product-template-default .woocommerce-notices-wrapper {
  margin-top: 15px;
}

.product-template-default .woocommerce-notices-wrapper .woocommerce-message {
  padding: 30px;
}

.product-template-default .woocommerce-notices-wrapper .woocommerce-message .button {
  background: #fff;
  color: #000;
  margin-top: -10px;
}

.newsletter-wrapper .column {
  padding-right: 2%;
}

.newsletter-wrapper:after {
  bottom: -7px;
  top: auto;
}

@media(max-width:769px) {
  .newsletter-wrapper .column {
    padding-right: 0;
  }

  .product-template-default .woocommerce-notices-wrapper .woocommerce-message {
    display: flex;
    flex-direction: column-reverse;
  }

  .product-template-default .woocommerce-notices-wrapper .woocommerce-message .button {
    margin-top: 10px;
  }

  .woocommerce-cart .woocommerce table.shop_table .product-name {
    width:  80%;
  }

  .woocommerce-cart .woocommerce table.shop_table .product-price {
    padding-right: 30px;
  }

  .woocommerce-cart .woocommerce table.shop_table .product-remove {
    border: 0 !important;
    width: 90%;
  }

  .woocommerce-cart .woocommerce table.shop_table .product-remove .remove {
    float: right;
  }

  .woocommerce-cart .woocommerce-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px !important;
  }

  .woocommerce-cart .woocommerce-info .button {
    flex: 100%;
    width: 100%;
    text-align: center;
  }
}

#shipping_method li {
  flex-direction: column;
}

#paczkomat_id_wrapper label {
  display: none !important;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 10px;
}

span.select2.select2-container.select2-container--default {
  max-width: 80%;
}

.woocommerce form .form-row .select2-container {
  width: 100% !important;
}

.reviews-wrapper .comment-text .star-rating {
  width: 86px;
}

.reviews-wrapper .comment-text .star-rating:before {
  font-size: 16px;
}

.woocommerce .star-rating span:before {
  font-size: 16px;
  margin-left: 1px;
}

.woocommerce-error {
  color: #fff !important;
  text-transform: none;
  font-weight: normal;
  margin: 0px 20px !important;

  &::before {
    content: none !important;
  }
}

input#shipping_method_0_local_pickup6:checked + label[for=shipping_method_0_local_pickup6]::after {
  display: block !important;
  content: "Poniedziałek- piątek godziny 7:00-15:00" !important;
  font-size: 14px;
  margin-top: 10px;
}