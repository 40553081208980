.cooperation-columns-wrapper {
  margin-top: 17px;
  margin-bottom: 100px;
  h2 {
    font-size: 22px;
    line-height: 32px;
    color: #242424;
    font-family: $primary-font;
    margin-bottom: 92px;
    @include md {
      margin-bottom: 30px;
    }
  }
  .col-md-4 {
    text-align: center;
    img {
      width: 100%;
    }
    .title {
      h4 {
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
        font-weight: bold;
        color: #242424;
        font-family: $primary-font;
        margin-top: 35px;
      }
    }
  }
}

.cooperation-columns4 {
  text-align: center;
  h2 {
    margin-bottom: 60px;
    @include md {
      margin-bottom: 30px;
    }
  }
  .col-md-3 {
    @include md {
      margin-bottom: 30px;
    }
    .number {
      font-size: 80px;
      line-height: 155px;
      letter-spacing: 3.2px;
      font-weight: bold;
      color: #242424;
      font-family: $primary-font;
      margin-bottom: 15px;
      @include md {
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
      }
    }
    h4 {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.7px;
      font-weight: bold;
      color: #242424;
      margin-bottom: 14px;
    }
    .text {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.6px;
      color: #242424;
      min-height: 300px;
      @include md {
        min-height: auto;
      }
    }
  }
}

.wrapper-why-us-cooperation {
  margin: 130px 0px;
  text-align: center;
  @include md {
    margin: 40px 0px;
    text-align: center;
  }
  .title {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-weight: bold;
    color: #242424;
    font-family: $primary-font;
    margin-bottom: 65px;
    @include md  {
      margin-bottom: 20px;
    }
  }
  .icon {
    margin-bottom: 35px;
    .title {
      max-width: 305px;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.7px;
      font-weight: bold;
      color: #242424;
      font-family: $primary-font;
      margin: 15px auto;
    }
  }
}