.text-and-image {
  margin-bottom: 100px;

  @include md {
    margin-bottom: 30px;
    padding: 0;
  }

  .row {
    justify-content: space-between;
    align-items: center;

    &.left {
      flex-direction: row-reverse;

      &:first-child {
        align-items: flex-start;
        @include lg {
          justify-content: center;
        }

        @include md {
          justify-content: flex-end;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
      }

      .img {
        align-items: flex-start;
      }
    }

    &.right {
      flex-direction: row;

      .img {
        align-items: flex-end;
      }

      .text {
        align-items: flex-start;
      }
    }

    .h3,
    h3 {
      max-width: 525px;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      color: $secondary-color;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 30px;

      @include lg {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px;
      }
    }

    .text {
      max-width: 525px;

      p {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.8px;
        color: $secondary-color;
        margin-bottom: 30px;

        @include md {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.6px;
        }
      }
    }

    img {
      max-width: 528px;
      height: auto;
      width: 100%;

      @include md {
        width: 100%;
      }
    }
  }
}

.our-team-section {
  .h2 {
    text-align: center;
    font-weight: bold;
    margin: 0 auto 80px auto;

    @include md {
      margin: 0 auto 30px auto;
    }
  }

  .col-md-3 {
    @include lg {
      flex: 0 50%;
      max-width: 50%;
    }

    @include md {
      flex: 100%;
      max-width: 500%;
    }

    img {
      margin-bottom: 50px !important;

      @include lg {
        margin-bottom: 20px;
        width: 100%;
        height: auto;
      }
    }

    .h3 {
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      color: $secondary-color;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 21px;

      @include md {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px;
      }
    }

    .text {
      p {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.7px;
        color: $secondary-color;
      }
    }
  }
}

.wrapper-wspolpraca-kontakt {
  .section-wrapper {
    padding: 46px 86px;
    position: relative;

    @include lg {
      padding: 25px 35px;
      position: relative;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include md {
      padding: 25px;
    }

    h3 {
      max-width: 515px;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      color: $bg-color;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 22px;

      @include md {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px;
      }
    }

    .opis {
      p {
        max-width: 660px;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.8px;
        color: $bg-color;
        font-family: $primary-font;
        margin-bottom: 0;

        @include lg {
          max-width: 50%;
        }

        @include md {
          max-width: 100%;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.6px;
        }
      }
    }

    .btn {
      position: absolute;
      bottom: 48px;
      right: 65px;

      @include md {
        position: relative;
        bottom: unset;
        right: unset;
        margin-top: 15px;
        padding: 15px 45px 15px 25px;
      }

      &:after {
        @include md {
          top: 22px;
          right: 10px;
        }
      }
    }
  }
}