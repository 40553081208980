h1, h2, h3, h4, h5, h6, body, p {
  font-family: $primary-font;
  font-display: swap;
  @include sm {
    word-break: break-word;
  }
}

body, p {
  font-size: 16px;
  color: $secondary-color;
  line-height: 26px;
  font-weight: normal;
  font-family: $primary-font;
  font-display: swap;
  @include md {
    font-size: 16px;
    line-height: 25px;
  }
}

a, button, input, textarea {
    box-shadow: none !important;
    font-display: swap;
}

.h2, h2 {
  color: $secondary-color;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: $primary-font;
  font-weight: bold;
  @include md {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.8px;
  }
}

.h3, h3 {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.8px;
  color: $secondary-color;
  font-weight: bold;
}

h2.has-text-align-center {
  max-width: 636px;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  margin: 0 auto 45px auto;
  color: $secondary-color;  
  @include md {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.8px;
    margin: 0 auto 20px auto;
  }
  a {
    color: $secondary-color; 
    &:hover {
      text-decoration: none;
      color: $primary-color;
    }
  }
  &.no-margin {
    margin: 0 auto;
  }
}

.wp-block-group {
  &.container {
    h1 {
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      color: $secondary-color;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 17px;
    }
  }
}

.has-text-align-center {
  text-align: center;
}