.single-post {
  .entry-header {
    .entry-title {
      margin-bottom: 30px;
      color: $secondary-color;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      font-family: $primary-font;
      font-weight: bold;

      @include md {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.8px;
      }

      a {
        color: $secondary-color;
        text-decoration: none;
      }
    }
  }

  .post-img {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    height: 490px;

    @include md {
      height: 290px;
    }

    .tag-links {
      position: absolute;
      top: 6px;
      display: block;
      z-index: 2;
      font-size: 0;

      .tag {
        background-color: $secondary-color;
        color: $white;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: $primary-font;
        padding: 10px;
        margin-right: 5px;
        text-decoration: none;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .entry-content {
    a {
      color: #000;
    }

    p {
      color: #757882;
      font-size: 15px;
      line-height: 32px;
      letter-spacing: 0.6px;
      font-family: $primary-font;

      a {
        color: #000;
      }
    }
  }

  .wp-block-product-category {
    .wc-block-grid__products {
      max-width: 1296px;
      margin: 0 auto 58px auto;
      display: block;

      .wc-block-grid__product {
        padding: 10px;
        background-color: #fff;
        margin: 0px 18px;
        border: 0;
        flex: 100%;
        max-width: 100%;
        display: block;
        float: left;
        flex: 1 0 22%;
        max-width: 22%;

        @include lg {
          margin: 0px 14px;
        }

        @media(max-width:992px) {
          flex: 100%;
          max-width: 100%;
        }

        .wc-block-grid__product-image {
          margin: 0 0 13px 0;
          border: 2px solid $bg-color;
          height: 330px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }

        a {
          text-decoration: none;
          color: #000;
        }

        .wc-block-grid__product-title {
          min-height: 48px;
          text-align: left;
          color: $secondary-color;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.6px;
          margin-bottom: 4px;
          text-decoration: none !important;
          margin: 10px 0px;

          @include lg {
            font-size: 13px;
            line-height: 20px;
          }
        }

        .wc-block-grid__product-price {
          text-align: left;
          color: $secondary-color;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: 1.28px;
          font-weight: bold;
          margin: 10px 0px;

          @include lg {
            font-size: 18px;
            line-height: 22px;
          }
        }

        &:hover {
          .wc-block-grid__product-title {
            color: $primary-color;
          }
        }

        &:hover {
          .wc-block-grid__product-image {
            img {
              transform: scale(1.1);
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }


}

.category-posts {
  margin: 120px 0;

  @include lg {
    margin-top: 50px 0;
  }

  @include md {
    margin: 30px 0;
  }

  .wp-block-latest-posts__list {
    max-width: 1290px;
    margin: 0 auto;
    display: flex;

    li {
      width: 32% !important;
      margin: 0;

      &:nth-child(2) {
        margin: 0 24px;

        @include lg {
          margin: 0 18px;
        }
      }

      @include md {
        width: 100% !important;
        margin-bottom: 30px;
      }

      .wp-block-latest-posts__featured-image {
        margin-bottom: 20px;
        overflow: hidden;

        img {
          width: 100%;
          height: 280px;
          object-fit: cover;
        }
      }

      .wp-block-latest-posts__post-title {
        display: block;
        min-height: 46px;
        color: $secondary-color;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: $primary-font;
        font-weight: bold;
        margin-bottom: 11px;
        padding: 0 20px;
      }

      .wp-block-latest-posts__post-excerpt {
        color: $secondary-color;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        padding: 0 20px;
      }

      .btn {
        display: block;
        overflow: hidden;
        float: right;
        position: relative;
        margin-top: 15px;
        color: $white !important;
      }

      &:hover {
        box-shadow: 0px 3px 10px #E1ECEC;
        cursor: pointer;

        .wp-block-latest-posts__featured-image {
          img {
            transform: scale(1.2);
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}