/*!
Theme Name: Uniszki
Theme URI: https://them.es/starter
Author: roxart
Author URI: https://roxart.pl/
Description: 
Version: 2.3.9
Requires at least: 5.0
Tested up to: 5.5
Requires PHP: 7.0
License: GPL version 2 or later
License URI: https://www.gnu.org/licenses/gpl-2.0
Tags: custom-background, custom-colors, featured-images, flexible-header, microformats, post-formats, rtl-language-support, theme-options, translation-ready, accessibility-ready
Text Domain: uniszki-theme
*/

// Compile "main.scss" as "css/main.css"


/**
 * Add custom Bootstrap styles
 */

// Bootstrap 4 Customization options: https://getbootstrap.com/docs/4.0/getting-started/theming/#sass-options
/*
// Options
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

$spacer:                    1rem !default;

// Grayscale vars
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

// Color vars
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;


/**
 * Import Bootstrap sources
 */

//@import "../node_modules/bootstrap/scss/bootstrap";

// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Optional: Uncomment all components that are not needed to reduce the filesize
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";


/**
 * General
 */

img {
  vertical-align: middle;
}


/**
 * WordPress customization
 */


/* If WP-Adminbar is visible */
body.admin-bar {
  #header.fixed-top {
    top: 32px;
  }

  .edit-link a {
    color: #FFF !important;
    font-weight: normal !important;
    text-decoration: none !important;

    &::before {
      content: '\270E';
      display: inline-block;
      margin-right: 5px;
    }
  }
}

/* WordPress Embeds */

.post-thumbnail,
.wp-video,
.embed-responsive {
  margin: 5px 0;
  width: 100% !important;
}

.post,
.post-thumbnail {
  img {
    max-width: 100%;
    height: auto;
  }
}


/* Header */

.navbar-static-top {
  margin-bottom: $grid-gutter-width;
}


/* Main */

// #main {
// 	margin-top: $spacer;
// }




/* Posts */

.sticky {
  .card {
    background-color: $gray-100;
  }
}

.entry-meta {
  font-size: small;
  margin-top: $grid-gutter-width/2;
}

.single {

  .entry-content,
  .post-navigation,
  #comments-title {
    margin: $grid-gutter-width 0;
  }
}

#comments {
  margin-top: $grid-gutter-width;
}

.comment-meta {
  height: 40px;
}

.comment-reply-title {
  margin: $grid-gutter-width 0 $grid-gutter-width/2;
}

.commentlist {
  list-style: none;
  margin: 0 auto;
  width: 68.9%;

  >li.comment {
    margin: 0 0 1.625em;
    padding: 1.625em;
    position: relative;
  }

  .children {
    list-style: none;
    margin-top: $grid-gutter-width;

    >li.comment {
      border-top: 1px solid $gray-300;
      padding-top: $grid-gutter-width;
      position: relative;
    }
  }

  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 1px 2px $gray-600;
    padding: 0;
    position: absolute;
    top: 0;
    left: -102px;
  }

}


/* WordPress Blocks */

// Buttons

.wp-block-button {
  &.is-style-outline {
    .wp-block-button__link {
      @extend .btn;
      @extend .btn-outline-primary;
    }
  }

  &.btn-lg {
    .wp-block-button__link {
      @extend .btn-lg;
    }
  }

  &.btn-sm {
    .wp-block-button__link {
      @extend .btn-sm;
    }
  }

  .wp-block-button__link {
    @extend .btn;
    @extend .btn-primary;
    text-decoration: none;
  }
}

// Table

.wp-block-table {

  td,
  th {
    border: inherit;
  }

  table {
    @extend .table;
  }
}

// Image, Media and Text

.wp-block-image,
.wp-block-media-text__media {

  img,
  video {
    height: auto;
  }
}


/**
 * Responsive Design using "mixins/_breakpoints.scss"
 */

@media (max-width: breakpoint-max(sm)) {

  #sidebar {
    margin-top: 60px;
  }

}

/** Import Font **/
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;700&display=swap');



/**
 * Theme custom styles
 */
@import "scss/variables";
@import "scss/colors";
@import "scss/breakpoints";
@import "scss/mixing";
@import "scss/slick";
@import "scss/grid";
@import "scss/typography";
@import "scss/header";
@import "scss/footer";
@import "scss/buttons";
@import "scss/homepage";
@import "scss/global";
@import "scss/contact-page";
@import "scss/about_us";
@import "scss/account";
@import "scss/cart";
@import "scss/product";
@import "scss/blog";
@import "scss/blog_post";
@import "scss/category";
@import "scss/b2b";
@import "scss/_ajax_cart";
@import "scss/_popup";
@import "scss/_dioz";