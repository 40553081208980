.woocommerce-page {
  .products {
    .product {
      padding: 10px;
      background-color: #fff;
      margin: 0px 18px;
      border: 0;
      flex: 100%;
      max-width: 100%;
      display: block;
      float: left;

      @include md {
        width: 48% !important;
        margin-bottom: 20px !important;
      }

      @include sm {
        width: 100% !important;
      }

      .attachment-woocommerce_thumbnail {
        margin: 10px 13px !important;
        width: 90%;
        border: 2px solid $bg-color;
        height: 370px;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        object-fit: contain;
      }

      a {
        text-decoration: none;
        color: $secondary-color;
        font-size: 14px;
        line-height: 20px;

        &:nth-child(2) {
          padding-left: 20px;
        }
      }

      .woocommerce-loop-product__title {
        text-align: left;
        color: $secondary-color;
        font-size: 16px !important;
        line-height: 24px;
        letter-spacing: 0.6px;
        margin-bottom: 4px;
        text-decoration: none !important;
        margin: 0px !important;
        min-height: 58px;
        padding: 0px 20px 0px 20px !important;

        @include md {
          min-height: auto;
        }
      }

      .star-rating {
        display: none !important;
      }

      .price {
        padding: 0px 20px !important;
        text-align: left;
        color: $secondary-color !important;
        font-size: 22px !important;
        line-height: 26px;
        letter-spacing: 1.28px;
        font-weight: bold !important;

        ins {
          text-decoration: none;
        }
      }

      &:hover {
        .woocommerce-loop-product__title {
          color: $primary-color;
        }
      }

      .new-badge {
        background: #FFB600;
        color: #fff;
        font-size: 15px;
        line-height: 24px;
        padding: 5px 20px;
        position: absolute;
        left: 0px;
        top: 15px;
        z-index: 2;
      }

      .onsale {
        background: #ff3c00 !important;
        border-radius: 0 !important;
        left: 0 !important;
        width: 91px;
        top: 60px !important;
        margin: 0 !important;
        height: 34px;
      }

      &:hover {
        box-shadow: 0px 3px 10px #E1ECEC;
      }

      .category-list {
        padding: 0px 20px 10px 20px !important;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: .3px;
        font-weight: 400;
      }

      .description {
        text-align: left;
        color: $secondary-color;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.56px;
        font-weight: normal;
        padding: 0px 20px 5px 20px !important;
      }

      .add_to_cart_button {
        display: none;
        position: absolute;
        top: 310px;
        right: 30px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important;

        &:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0;
          left: 0;

        }

        &:hover {
          &::after {
            background-image: url(../../assets/images/koszyk2.svg);
          }
        }
      }

      .yith-wcqv-button {
        position: absolute;
        top: 310px;
        right: 30px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important;

        &:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0;
          left: 0;

        }

        &:hover {
          &::after {
            background-image: url(../../assets/images/koszyk2.svg);
          }
        }
      }

      &.product_cat-dla-dioz {
        &::before {
          content: '';
          background-image: url(../../assets/images/dioz-produkt.png);
          width: 50px;
          height: 50px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 12px;
          right: 16px;
        }

        .new-badge {
          display: none;
        }
      }
    }
  }

  .woocommerce-products-header {
    .page-title {
      color: $secondary-color;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      font-family: $primary-font;
      font-weight: bold;
      margin-top: 33px;
      margin-bottom: 50px;

      @include md {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px;
        margin-bottom: 33px;
      }
    }
  }

  .woocommerce-result-count {
    display: none;
  }

  .woocommerce-ordering {
    margin-bottom: 40px;

    .orderby {
      border: 0;
      color: #242934;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.6px;
      width: auto;
      outline: none !important;
    }
  }
}

#top {
  .woocommerce-pagination {
    border: 0;

    .page-numbers {
      border: 0;
      margin: 0;
      padding: 0;
      float: right;

      li {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          border: 0;
          border-radius: 0;
          background-color: transparent;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.6px;
          font-weight: bold;
          font-family: $primary-font;
          color: $secondary-color;
        }

        .prev.page-numbers {
          background: $secondary-color;
          font-size: 0;
          width: 40px;
          height: 40px;
          position: relative;

          &:after {
            content: url(../../assets/images/menu.svg);
            transform: rotate(90deg);
            position: absolute;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .next.page-numbers {
          background: $secondary-color;
          font-size: 0;
          width: 40px;
          height: 40px;
          position: relative;

          &:after {
            content: url(../../assets/images/menu.svg);
            transform: rotate(-90deg);
            position: absolute;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }


      }
    }
  }
}

.shop-sidebar {
  @include md {
    display: none;
  }

  &.active {
    @include md {
      display: block;
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      a {
        color: #242934;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-weight: bold;
      }

      ul {
        list-style: none;
        padding-left: 10px;

        li {
          a {
            color: #757882;
            font-size: 15px;
            line-height: 24px;
            font-weight: normal;
          }
        }
      }

      &.current-menu-item {
        >a {
          color: $primary-color;
        }
      }

      &.dioz {
        >a {
          position: relative;

          &:after {
            content: '';
            width: 20px;
            height: 20px;
            display: block;
            background-size: cover;
            background-image: url(../../assets/images/dioz-logo.png);
            margin-left: 10px;
            position: absolute;
            left: 65px;
            top: 0;
          }
        }
      }
    }
  }
  input[type=checkbox]{
    &::before , &::after {
      content: none;
    }
  }
}


.wc-block-components-checkbox .wc-block-components-checkbox__input[type=checkbox] {
  border: 0;
  border-radius: 0;
}

.wc-block-components-checkbox .wc-block-components-checkbox__mark {
  display: none;
}

.wc-block-components-checkbox .wc-block-components-checkbox__input[type=checkbox]:focus {
  outline: none !important;
  outline-offset: 0 !important;
}

.wc-block-components-price-slider__controls .wc-block-components-price-slider__amount {
  width: auto;
  max-width: 50% !important;
  border-radius: 0;
  border: 0;
  color: #757882;
  font-size: 15px;
}

input.wc-block-formatted-money-amount.wc-block-components-formatted-money-amount.wc-block-price-filter__amount.wc-block-price-filter__amount--max.wc-block-form-text-input.wc-block-components-price-slider__amount.wc-block-components-price-slider__amount--max {
  text-align: right;
}

.category-description {
  margin-top: 100px;

  @include md {
    margin-top: 40px;
  }

  span {
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 13px;
  }
}

.wc-block-components-checkbox .wc-block-components-checkbox__input[type=checkbox] {
  border: 0px !important;
}

.category-info-icons {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;

  @include lg {
    flex-wrap: wrap;
  }

  @include md {
    margin-top: 20px;
    display: block;
  }

  .icon-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 30px;

    @include lg {
      margin-right: 0;
      width: 50%;
    }

    @include md {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    img {
      margin-right: 10px;
    }

    .title {
      max-width: 190px;

      @include md {
        max-width: 100%;
      }
    }
  }
}

.filters-mobile {
  display: none;

  @include md {
    display: block;
    width: 100%;
    padding: 6px;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.category-sidebar {
  input[type=checkbox] {
    &:before {
      left: -2px;
      top: 2px;
      position: absolute;
    }

    &:focus {
      outline: none;
    }
  }
}

.wc-block-attribute-filter__actions {
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 40px;
}

.tax-product_cat {

  &.term-legowiska,
  &.term-akcesoria {
    .shop-sidebar {

      #block-40,
      #block-39 {
        display: none;
      }
    }
  }
}