//
// Container sizes
// --------------------------------------------------

/*
  Overwriting default bootstrap container widths
 */
 .container {
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 $layout-indent__width;
  @include media-breakpoint-down(lg) {
    max-width: $sch-container-md;
    padding: 0px 20px;
  }
  @include media-breakpoint-down(md) {
    //overflow: hidden;
      .row {
        padding: 0px 20px;
      }
  }
  @include media-breakpoint-down(xs) {
    max-width: $sch-container-sm;
    padding: 0 $layout-indent__width-xs;
    .row {
      padding: 0px 20px;
    }
  }
}
.container-fluid {
  max-width: rem(1900px);
}

/*
 Distances and spacing
 */
.pt-xxl {
}

.pt-xl {
  padding-top: 50px;
}

.pt-md {
  padding-top: 32px;
}

.pt-sm {

}

.pt-xs {

}

.pb-xxl {

}

.pb-xl {
padding-bottom: 50px;
  @include md {
    padding-bottom: 10px;
    }
}

@include md {
  .wp-block-spacer {
    height: 30px !important;
}
.wp-block-columns {
  padding: 0px 20px;
}

}

.pb-md {

}

.pb-sm {

}

.pb-xs {
 
}

.hide {
  display: none;
}

