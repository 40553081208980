@charset "UTF-8";
/*!
Theme Name: Uniszki
Theme URI: https://them.es/starter
Author: roxart
Author URI: https://roxart.pl/
Description: 
Version: 2.3.9
Requires at least: 5.0
Tested up to: 5.5
Requires PHP: 7.0
License: GPL version 2 or later
License URI: https://www.gnu.org/licenses/gpl-2.0
Tags: custom-background, custom-colors, featured-images, flexible-header, microformats, post-formats, rtl-language-support, theme-options, translation-ready, accessibility-ready
Text Domain: uniszki-theme
*/
/**
 * Add custom Bootstrap styles
 */
/*
// Options
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

$spacer:                    1rem !default;

// Grayscale vars
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

// Color vars
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;


/**
 * Import Bootstrap sources
 */
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;700&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table, .wp-block-table table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th, .wp-block-table table th,
  .table td,
  .wp-block-table table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th, .wp-block-table table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody, .wp-block-table table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th, .wp-block-table table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th, .wp-block-table table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .wp-block-button.is-style-outline .wp-block-button__link, .wp-block-button .wp-block-button__link {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .wp-block-button.is-style-outline .wp-block-button__link, .wp-block-button .wp-block-button__link {
      transition: none; } }
  .btn:hover, .wp-block-button .wp-block-button__link:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .wp-block-button .wp-block-button__link:focus, .btn.focus, .wp-block-button .focus.wp-block-button__link {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .wp-block-button .disabled.wp-block-button__link, .btn:disabled, .wp-block-button .wp-block-button__link:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .wp-block-button .wp-block-button__link:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled, .wp-block-button a.disabled.wp-block-button__link,
fieldset:disabled a.btn,
fieldset:disabled .wp-block-button a.wp-block-button__link,
.wp-block-button fieldset:disabled a.wp-block-button__link {
  pointer-events: none; }

.btn-primary, .wp-block-button .wp-block-button__link {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover, .wp-block-button .wp-block-button__link:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .wp-block-button .wp-block-button__link:focus, .btn-primary.focus, .wp-block-button .focus.wp-block-button__link {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .wp-block-button .disabled.wp-block-button__link, .btn-primary:disabled, .wp-block-button .wp-block-button__link:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .wp-block-button .wp-block-button__link:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .wp-block-button .wp-block-button__link:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle,
  .wp-block-button .show > .dropdown-toggle.wp-block-button__link {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .wp-block-button .wp-block-button__link:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .wp-block-button .wp-block-button__link:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .wp-block-button .show > .dropdown-toggle.wp-block-button__link:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary, .wp-block-button.is-style-outline .wp-block-button__link {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover, .wp-block-button.is-style-outline .wp-block-button__link:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .wp-block-button.is-style-outline .wp-block-button__link:focus, .btn-outline-primary.focus, .wp-block-button.is-style-outline .focus.wp-block-button__link {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .wp-block-button.is-style-outline .disabled.wp-block-button__link, .btn-outline-primary:disabled, .wp-block-button.is-style-outline .wp-block-button__link:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .wp-block-button.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .wp-block-button.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle,
  .wp-block-button.is-style-outline .show > .dropdown-toggle.wp-block-button__link {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .wp-block-button.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .wp-block-button.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus,
    .wp-block-button.is-style-outline .show > .dropdown-toggle.wp-block-button__link:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .wp-block-button .btn-group-lg > .wp-block-button__link, .wp-block-button.btn-lg .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .wp-block-button .btn-group-sm > .wp-block-button__link, .wp-block-button.btn-sm .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .wp-block-button .btn-group > .wp-block-button__link,
  .btn-group-vertical > .btn,
  .wp-block-button .btn-group-vertical > .wp-block-button__link {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .wp-block-button .btn-group > .wp-block-button__link:hover,
    .btn-group-vertical > .btn:hover,
    .wp-block-button .btn-group-vertical > .wp-block-button__link:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .wp-block-button .btn-group > .wp-block-button__link:focus, .btn-group > .btn:active, .wp-block-button .btn-group > .wp-block-button__link:active, .btn-group > .btn.active, .wp-block-button .btn-group > .active.wp-block-button__link,
    .btn-group-vertical > .btn:focus,
    .wp-block-button .btn-group-vertical > .wp-block-button__link:focus,
    .btn-group-vertical > .btn:active,
    .wp-block-button .btn-group-vertical > .wp-block-button__link:active,
    .btn-group-vertical > .btn.active,
    .wp-block-button .btn-group-vertical > .active.wp-block-button__link {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .wp-block-button .btn-group > .wp-block-button__link:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .wp-block-button .btn-group > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.wp-block-button .btn-group > .btn-group:not(:last-child) > .wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .wp-block-button .btn-group > .wp-block-button__link:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.wp-block-button .btn-group > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .wp-block-button .btn-group-sm > .wp-block-button__link + .dropdown-toggle-split, .wp-block-button.btn-sm .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .wp-block-button .btn-group-lg > .wp-block-button__link + .dropdown-toggle-split, .wp-block-button.btn-lg .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .wp-block-button .btn-group-vertical > .wp-block-button__link,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .wp-block-button .btn-group-vertical > .wp-block-button__link:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .wp-block-button .btn-group-vertical > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .wp-block-button .btn-group-vertical > .btn-group:not(:last-child) > .wp-block-button__link {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .wp-block-button .btn-group-vertical > .wp-block-button__link:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .wp-block-button .btn-group-vertical > .btn-group:not(:first-child) > .wp-block-button__link {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .wp-block-button .btn-group-toggle > .wp-block-button__link,
.btn-group-toggle > .btn-group > .btn,
.wp-block-button .btn-group-toggle > .btn-group > .wp-block-button__link {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .wp-block-button .btn-group-toggle > .wp-block-button__link input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .wp-block-button .btn-group-toggle > .wp-block-button__link input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .wp-block-button .btn-group-toggle > .btn-group > .wp-block-button__link input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .wp-block-button .btn-group-toggle > .btn-group > .wp-block-button__link input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .wp-block-button .wp-block-button__link, .wp-block-button .input-group-prepend .wp-block-button__link,
  .input-group-append .btn,
  .input-group-append .wp-block-button .wp-block-button__link,
  .wp-block-button .input-group-append .wp-block-button__link {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .wp-block-button .wp-block-button__link:focus, .wp-block-button .input-group-prepend .wp-block-button__link:focus,
    .input-group-append .btn:focus,
    .input-group-append .wp-block-button .wp-block-button__link:focus,
    .wp-block-button .input-group-append .wp-block-button__link:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .wp-block-button .wp-block-button__link + .btn, .wp-block-button .input-group-prepend .wp-block-button__link + .btn, .input-group-prepend .wp-block-button .btn + .wp-block-button__link, .wp-block-button .input-group-prepend .btn + .wp-block-button__link, .input-group-prepend .wp-block-button .wp-block-button__link + .wp-block-button__link, .wp-block-button .input-group-prepend .wp-block-button__link + .wp-block-button__link,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .wp-block-button .wp-block-button__link + .input-group-text,
  .wp-block-button .input-group-prepend .wp-block-button__link + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .wp-block-button .input-group-text + .wp-block-button__link,
  .wp-block-button .input-group-prepend .input-group-text + .wp-block-button__link,
  .input-group-append .btn + .btn,
  .input-group-append .wp-block-button .wp-block-button__link + .btn,
  .wp-block-button .input-group-append .wp-block-button__link + .btn,
  .input-group-append .wp-block-button .btn + .wp-block-button__link,
  .wp-block-button .input-group-append .btn + .wp-block-button__link,
  .input-group-append .wp-block-button .wp-block-button__link + .wp-block-button__link,
  .wp-block-button .input-group-append .wp-block-button__link + .wp-block-button__link,
  .input-group-append .btn + .input-group-text,
  .input-group-append .wp-block-button .wp-block-button__link + .input-group-text,
  .wp-block-button .input-group-append .wp-block-button__link + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .wp-block-button .input-group-text + .wp-block-button__link,
  .wp-block-button .input-group-append .input-group-text + .wp-block-button__link {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.wp-block-button .input-group-lg > .input-group-prepend > .wp-block-button__link,
.input-group-lg > .input-group-append > .btn,
.wp-block-button .input-group-lg > .input-group-append > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.wp-block-button .input-group-sm > .input-group-prepend > .wp-block-button__link,
.input-group-sm > .input-group-append > .btn,
.wp-block-button .input-group-sm > .input-group-append > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .wp-block-button .input-group > .input-group-prepend > .wp-block-button__link,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.wp-block-button .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .wp-block-button__link,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.wp-block-button .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .wp-block-button__link,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.wp-block-button .input-group > .input-group-append:last-child > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .wp-block-button .input-group > .input-group-append > .wp-block-button__link,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.wp-block-button .input-group > .input-group-prepend:not(:first-child) > .wp-block-button__link,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.wp-block-button .input-group > .input-group-prepend:first-child > .wp-block-button__link:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .wp-block-button .wp-block-button__link .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table, .wp-block-table table {
    border-collapse: collapse !important; }
    
    .table td,
    .wp-block-table table td, .table th, .wp-block-table table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th, .wp-block-table table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/**
 * General
 */
img {
  vertical-align: middle; }

/**
 * WordPress customization
 */
/* If WP-Adminbar is visible */
body.admin-bar #header.fixed-top {
  top: 32px; }

body.admin-bar .edit-link a {
  color: #FFF !important;
  font-weight: normal !important;
  text-decoration: none !important; }
  body.admin-bar .edit-link a::before {
    content: '\270E';
    display: inline-block;
    margin-right: 5px; }

/* WordPress Embeds */
.post-thumbnail,
.wp-video,
.embed-responsive {
  margin: 5px 0;
  width: 100% !important; }

.post img,
.post-thumbnail img {
  max-width: 100%;
  height: auto; }

/* Header */
.navbar-static-top {
  margin-bottom: 30px; }

/* Main */
/* Posts */
.sticky .card {
  background-color: #f8f9fa; }

.entry-meta {
  font-size: small;
  margin-top: 15px; }

.single .entry-content,
.single .post-navigation,
.single #comments-title {
  margin: 30px 0; }

#comments {
  margin-top: 30px; }

.comment-meta {
  height: 40px; }

.comment-reply-title {
  margin: 30px 0 15px; }

.commentlist {
  list-style: none;
  margin: 0 auto;
  width: 68.9%; }
  .commentlist > li.comment {
    margin: 0 0 1.625em;
    padding: 1.625em;
    position: relative; }
  .commentlist .children {
    list-style: none;
    margin-top: 30px; }
    .commentlist .children > li.comment {
      border-top: 1px solid #dee2e6;
      padding-top: 30px;
      position: relative; }
  .commentlist .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 1px 2px #6c757d;
    padding: 0;
    position: absolute;
    top: 0;
    left: -102px; }

/* WordPress Blocks */
.wp-block-button .wp-block-button__link {
  text-decoration: none; }

.wp-block-table td,
.wp-block-table th {
  border: inherit; }

.wp-block-image img,
.wp-block-image video,
.wp-block-media-text__media img,
.wp-block-media-text__media video {
  height: auto; }

/**
 * Responsive Design using "mixins/_breakpoints.scss"
 */
@media (max-width: 767.98px) {
  #sidebar {
    margin-top: 60px; } }

/** Import Font **/
/**
 * Theme custom styles
 */
/* content max-width */
/*--------------------------------------------------------------
# UNISZKI Theme Colors
--------------------------------------------------------------*/
/*----------------------------------- */
/*----------------------------------- */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  display: block;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

/*
  Overwriting default bootstrap container widths
 */
.container {
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 0px; }
  @media (max-width: 1199.98px) {
    .container {
      max-width: 980px;
      padding: 0px 20px; } }
  @media (max-width: 991.98px) {
    .container .row {
      padding: 0px 20px; } }
  @media (max-width: 575.98px) {
    .container {
      max-width: 740px;
      padding: 0 20px; }
      .container .row {
        padding: 0px 20px; } }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: rem(1900px); }

/*
 Distances and spacing
 */
.pt-xl {
  padding-top: 50px; }

.pt-md {
  padding-top: 32px; }

.pb-xl {
  padding-bottom: 50px; }
  @media (max-width: 769px) {
    .pb-xl {
      padding-bottom: 10px; } }

@media (max-width: 769px) {
  .wp-block-spacer {
    height: 30px !important; }
  .wp-block-columns {
    padding: 0px 20px; } }

.hide {
  display: none; }

h1, h2, h3, h4, h5, h6, body, p {
  font-family: "Encode Sans", sans-serif;
  font-display: swap; }
  @media (max-width: 575px) {
    h1, h2, h3, h4, h5, h6, body, p {
      word-break: break-word; } }

body, p {
  font-size: 16px;
  color: #242934;
  line-height: 26px;
  font-weight: normal;
  font-family: "Encode Sans", sans-serif;
  font-display: swap; }
  @media (max-width: 769px) {
    body, p {
      font-size: 16px;
      line-height: 25px; } }

a, button, input, textarea {
  box-shadow: none !important;
  font-display: swap; }

.h2, h2 {
  color: #242934;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold; }
  @media (max-width: 769px) {
    .h2, h2 {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.8px; } }

.h3, h3 {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.8px;
  color: #242934;
  font-weight: bold; }

h2.has-text-align-center {
  max-width: 636px;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  margin: 0 auto 45px auto;
  color: #242934; }
  @media (max-width: 769px) {
    h2.has-text-align-center {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin: 0 auto 20px auto; } }
  h2.has-text-align-center a {
    color: #242934; }
    h2.has-text-align-center a:hover {
      text-decoration: none;
      color: #0EB1A4; }
  h2.has-text-align-center.no-margin {
    margin: 0 auto; }

.wp-block-group.container h1 {
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  color: #242934;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 17px; }

.has-text-align-center {
  text-align: center; }

.site-header {
  z-index: 999; }

.navbar.primary-navbar {
  background: #242934;
  padding: 0;
  display: block;
  z-index: 999; }
  @media (max-width: 769px) {
    .navbar.primary-navbar.scrolled {
      top: 0 !important; } }
  @media (max-width: 769px) {
    .navbar.primary-navbar .container {
      padding: 10px; } }
  .navbar.primary-navbar .navbar-toggler {
    border: 0; }
  .navbar.primary-navbar .navbar-brand {
    margin: 0;
    padding: 20px 0px; }
    @media (max-width: 992px) {
      .navbar.primary-navbar .navbar-brand {
        flex: 30%;
        padding: 0; } }
    @media (max-width: 1250px) and (min-width: 769px) {
      .navbar.primary-navbar .navbar-brand img {
        padding: 10px;
        max-width: 200px; } }
    @media (max-width: 769px) {
      .navbar.primary-navbar .navbar-brand img {
        max-width: 155px; } }
    @media (max-width: 400px) {
      .navbar.primary-navbar .navbar-brand img {
        max-width: 125px; } }
  .navbar.primary-navbar .top-main-navbar {
    margin-right: 20px; }
    @media (max-width: 1350px) {
      .navbar.primary-navbar .top-main-navbar {
        margin-right: 30px; } }
    @media (max-width: 992px) {
      .navbar.primary-navbar .top-main-navbar {
        flex: 40%;
        margin-right: 20px; } }
  .navbar.primary-navbar .navbar-nav {
    margin: 0 auto; }
    @media (max-width: 992px) {
      .navbar.primary-navbar .navbar-nav {
        flex-direction: row;
        justify-content: flex-end; } }
    .navbar.primary-navbar .navbar-nav li a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.64px;
      color: #fff !important;
      padding: 0px 22px;
      margin: 0px; }
      @media (max-width: 992px) {
        .navbar.primary-navbar .navbar-nav li a {
          font-size: 10px;
          line-height: 15px;
          padding: 0px 6px; } }
      .navbar.primary-navbar .navbar-nav li a:hover, .navbar.primary-navbar .navbar-nav li a:active, .navbar.primary-navbar .navbar-nav li a:focus {
        color: #0EB1A4 !important;
        text-decoration: none; }
    .navbar.primary-navbar .navbar-nav li.active a {
      color: #0EB1A4 !important; }
    .navbar.primary-navbar .navbar-nav li:last-child a {
      padding-right: 0; }
    .navbar.primary-navbar .navbar-nav li.my-account a:before {
      content: '';
      width: 34px;
      height: 34px;
      background-size: cover;
      background-image: url(../../assets/images/paw.svg);
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
      -o-filter: brightness(0) invert(1);
      -ms-filter: brightness(0) invert(1); }
      @media (max-width: 992px) {
        .navbar.primary-navbar .navbar-nav li.my-account a:before {
          width: 25px;
          height: 25px; } }
    .navbar.primary-navbar .navbar-nav li.logout a:before {
      content: '';
      width: 34px;
      height: 34px;
      background-size: cover;
      background-image: url(../../assets/images/logout.svg);
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
      -o-filter: brightness(0) invert(1);
      -ms-filter: brightness(0) invert(1); }
      @media (max-width: 992px) {
        .navbar.primary-navbar .navbar-nav li.logout a:before {
          width: 25px;
          height: 25px; } }
    .navbar.primary-navbar .navbar-nav li.cart a:before {
      width: 34px;
      height: 34px;
      background-size: cover;
      background-image: url(../../assets/images/cart.svg);
      content: ''; }
      @media (max-width: 992px) {
        .navbar.primary-navbar .navbar-nav li.cart a:before {
          width: 25px;
          height: 25px; } }
    .navbar.primary-navbar .navbar-nav li.wpmenucartli a {
      height: 100%;
      width: 100%;
      justify-content: space-between; }
      .navbar.primary-navbar .navbar-nav li.wpmenucartli a:before {
        width: 34px;
        height: 34px;
        background-size: cover;
        background-image: url(../../assets/images/cart.svg);
        content: ''; }
        @media (max-width: 992px) {
          .navbar.primary-navbar .navbar-nav li.wpmenucartli a:before {
            width: 25px;
            height: 25px; } }
      .navbar.primary-navbar .navbar-nav li.wpmenucartli a:after {
        content: 'koszyk';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .64px;
        color: #fff !important;
        left: 8px; }
        @media (max-width: 992px) {
          .navbar.primary-navbar .navbar-nav li.wpmenucartli a:after {
            font-size: 10px;
            line-height: 15px;
            padding: 0;
            position: initial; } }
      .navbar.primary-navbar .navbar-nav li.wpmenucartli a i {
        display: none; }
    .navbar.primary-navbar .navbar-nav li.wpmenucartli span.cartcontents {
      background: #FF3C00;
      border-radius: 50px;
      width: 20px;
      height: 20px;
      text-align: center;
      font-size: 12px;
      position: absolute;
      right: -7px;
      top: 0; }
      @media (max-width: 992px) {
        .navbar.primary-navbar .navbar-nav li.wpmenucartli span.cartcontents {
          width: 15px;
          height: 15px;
          right: 0;
          font-size: 10px; } }
      .navbar.primary-navbar .navbar-nav li.wpmenucartli span.cartcontents .small {
        display: none !important; }
    .navbar.primary-navbar .navbar-nav li .wpmenucart-contents:hover {
      color: #fff !important; }

.navbar.secondary-navbar {
  background: #0EB1A4 !important;
  top: 140px;
  padding: 0; }
  @media (max-width: 992px) {
    .navbar.secondary-navbar {
      top: 95px;
      padding: 7px 20px; } }
  @media (max-width: 600px) {
    .navbar.secondary-navbar {
      top: 125px; } }
  @media (max-width: 992px) {
    .navbar.secondary-navbar .navbar-collapse {
      position: fixed;
      background: #0000009e;
      z-index: 999;
      left: 0;
      top: 108px;
      width: 100%;
      height: 100vh; } }
  .navbar.secondary-navbar .navbar-collapse .navbar-nav {
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 992px) {
      .navbar.secondary-navbar .navbar-collapse .navbar-nav {
        width: 80%;
        height: 100vh;
        background-color: #fff;
        padding: 30px 0px;
        text-align: left;
        padding-bottom: 50vh;
        overflow: scroll; } }
    .navbar.secondary-navbar .navbar-collapse .navbar-nav li {
      position: initial; }
      .navbar.secondary-navbar .navbar-collapse .navbar-nav li a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff !important;
        letter-spacing: 0.64px;
        padding: 15px 20px;
        margin: 0px; }
        @media (max-width: 1250px) and (min-width: 769px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li a {
            font-size: 13px;
            padding: 15px 10px; } }
        @media (max-width: 992px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li a {
            color: #000 !important;
            justify-content: flex-start;
            font-size: 16px;
            line-height: 20px;
            padding: 15px 0;
            margin: 5px 20px;
            border-bottom: 1px solid #000;
            justify-content: space-between; } }
        .navbar.secondary-navbar .navbar-collapse .navbar-nav li a:hover, .navbar.secondary-navbar .navbar-collapse .navbar-nav li a:active, .navbar.secondary-navbar .navbar-collapse .navbar-nav li a:focus {
          background-color: #00847B;
          text-decoration: none; }
          @media (max-width: 992px) {
            .navbar.secondary-navbar .navbar-collapse .navbar-nav li a:hover, .navbar.secondary-navbar .navbar-collapse .navbar-nav li a:active, .navbar.secondary-navbar .navbar-collapse .navbar-nav li a:focus {
              background-color: #fff; } }
        .navbar.secondary-navbar .navbar-collapse .navbar-nav li a .caret {
          margin-left: 10px; }
      .navbar.secondary-navbar .navbar-collapse .navbar-nav li.active a {
        background-color: #00847B; }
        @media (max-width: 769px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li.active a {
            background-color: #fff;
            color: #0EB1A4 !important; } }
      .navbar.secondary-navbar .navbar-collapse .navbar-nav li.active ul li a {
        background-color: transparent !important; }
      .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu {
        box-shadow: 0px 3px 6px #5b5b7429;
        border: 0;
        border-radius: 0;
        width: 1450px;
        justify-content: flex-start;
        padding: 36px;
        margin: 0 auto;
        left: 0;
        right: 0; }
        @media (max-width: 1450px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu {
            width: 100%;
            padding: 36px 0; } }
        @media (max-width: 1250px) and (min-width: 769px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu {
            width: 100%; } }
        @media (max-width: 992px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu {
            display: block;
            box-shadow: none;
            width: 100%;
            padding: 0;
            margin-bottom: 15px; } }
        @media (max-width: 769px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu {
            display: block; } }
        .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li {
          margin: 0px 30px; }
          @media (max-width: 992px) {
            .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li {
              margin: 0;
              padding-left: 10px; } }
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li a {
            font-size: 18px;
            text-transform: none;
            justify-content: flex-start;
            color: #242424 !important;
            padding: 0;
            margin-bottom: 20px; }
            @media (max-width: 992px) {
              .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li a {
                width: auto;
                border: 0;
                font-size: 15px;
                margin-bottom: 15px; } }
            .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li a:hover {
              background-color: #fff;
              color: #0EB1A4 !important; }
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu {
            box-shadow: none;
            display: flex;
            width: auto;
            position: initial;
            text-align: left;
            align-items: flex-start;
            flex-direction: column;
            padding: 0;
            margin: 0; }
            @media (max-width: 992px) {
              .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu {
                margin-bottom: 15px; } }
            .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu li {
              margin: 0;
              margin-bottom: 10px; }
              .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu li a {
                font-size: 15px;
                font-weight: normal;
                line-height: 24px;
                padding: 0;
                margin-bottom: 0; }
              .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu li.active a {
                background-color: transparent;
                color: #0EB1A4 !important; }
            .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu .dropdown-menu .menu-item.active a {
              background-color: transparent;
              color: #0EB1A4 !important; }
            .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu .dropdown-submenu {
              margin: 0; }
              .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu .dropdown-submenu a {
                font-size: 15px;
                font-weight: normal;
                line-height: 24px;
                padding: 0;
                margin-bottom: 0; }
              .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu .dropdown-submenu.active a {
                background-color: transparent;
                color: #0EB1A4 !important; }
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu li.active a {
            background-color: transparent;
            color: #0EB1A4 !important; }
        .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu.show {
          display: flex; }
          @media (max-width: 992px) {
            .navbar.secondary-navbar .navbar-collapse .navbar-nav li .dropdown-menu.show {
              display: block; } }
      .navbar.secondary-navbar .navbar-collapse .navbar-nav li.menu-item-has-children:hover .dropdown-menu {
        display: flex; }
        @media (max-width: 992px) {
          .navbar.secondary-navbar .navbar-collapse .navbar-nav li.menu-item-has-children:hover .dropdown-menu {
            display: block; } }
      .navbar.secondary-navbar .navbar-collapse .navbar-nav li.dioz a:after {
        content: '';
        width: 22px;
        height: 22px;
        display: block;
        background-size: cover;
        background-image: url(../../assets/images/dioz-logo-white.png);
        margin-left: 10px; }

.navbar .header-search {
  display: flex;
  width: 45%; }
  @media (max-width: 992px) {
    .navbar .header-search {
      display: none;
      width: 80%; } }
  @media (max-width: 769px) {
    .navbar .header-search {
      overflow: scroll; } }
  .navbar .header-search.mobile {
    display: none; }
    @media (max-width: 992px) {
      .navbar .header-search.mobile {
        display: block; }
        .navbar .header-search.mobile .header-search {
          display: block; } }
  .navbar .header-search .wp-block-search__inside-wrapper {
    display: flex; }
  .navbar .header-search .widget {
    width: 100%; }
    .navbar .header-search .widget .wp-block-search {
      width: 100%; }
      .navbar .header-search .widget .wp-block-search__input {
        width: 100%;
        border-radius: 0;
        height: 40px;
        border: 0;
        outline: none;
        padding: 5px 15px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.56px;
        text-transform: lowercase;
        color: #757882; }
      .navbar .header-search .widget .wp-block-search__button {
        height: 40px;
        width: 40px;
        font-size: 0;
        border: 0;
        background-color: #fff;
        margin: 0;
        position: relative; }
        .navbar .header-search .widget .wp-block-search__button::before {
          content: url(../../assets/images/search.svg);
          position: absolute;
          right: 6px;
          top: 5px;
          max-height: 40px; }

.navbar .container-extra {
  width: 100%;
  display: block;
  overflow: hidden;
  background: #ffb600;
  text-align: center; }
  .navbar .container-extra p {
    color: #000;
    margin: 0;
    padding: 10px 0px;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 992px) {
      .navbar .container-extra p {
        max-width: 90%;
        margin: 0 auto;
        font-size: 13px;
        line-height: 18px; } }
  .navbar .container-extra a:hover {
    text-decoration: none; }
    .navbar .container-extra a:hover p {
      color: #fff; }

.navbar-toggler {
  padding: 0; }
  .navbar-toggler .navbar-toggler-icon {
    background-image: url(../../assets/images/menu-toggle.svg) !important; }

.logged-in.admin-bar .primary-navbar {
  top: 32px; }

.dropdown-toggle:after {
  content: url(../../assets/images/menu.svg);
  border: 0;
  vertical-align: unset;
  margin: 0;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 3px; }
  @media (max-width: 992px) {
    .dropdown-toggle:after {
      filter: brightness(0);
      transform: rotate(-90deg); } }

.woofc-menu-item {
  position: relative; }
  .woofc-menu-item span.woofc-menu-item-inner-subtotal {
    display: none; }
  .woofc-menu-item:after {
    content: 'koszyk';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .64px;
    color: #fff !important;
    padding: 0 22px; }
    @media (max-width: 992px) {
      .woofc-menu-item:after {
        font-size: 12px;
        line-height: 15px;
        padding: 0;
        position: initial; } }
  .woofc-menu-item .woofc-icon-cart8 {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    justify-content: center;
    margin-left: 15px;
    text-align: center;
    align-items: center; }
    @media (max-width: 992px) {
      .woofc-menu-item .woofc-icon-cart8 {
        margin: 0; } }
    .woofc-menu-item .woofc-icon-cart8:before {
      width: 34px;
      height: 34px;
      background-size: cover;
      background-image: url(../../assets/images/cart.svg);
      content: ''; }
      @media (max-width: 992px) {
        .woofc-menu-item .woofc-icon-cart8:before {
          width: 25px;
          height: 25px; } }

.woofc-menu-item .woofc-menu-item-inner:after {
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: "Encode Sans", sans-serif;
  text-transform: uppercase;
  left: unset;
  right: -6px;
  top: 0px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF3C00;
  border-radius: 50%; }

.footer-wrapper {
  background: #242934;
  color: #fff;
  padding: 60px 0 30px 0px;
  position: relative; }
  .footer-wrapper p, .footer-wrapper span {
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.56px;
    margin: 0; }
    @media (max-width: 769px) {
      .footer-wrapper p, .footer-wrapper span {
        text-align: center; } }
  .footer-wrapper .widgettitle {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 23px;
    margin-top: 52px; }
    @media (max-width: 769px) {
      .footer-wrapper .widgettitle {
        margin-top: 15px;
        text-align: center; } }
  .footer-wrapper .menu {
    list-style: none;
    padding: 0; }
    .footer-wrapper .menu li {
      line-height: 24px;
      margin-bottom: 14px; }
      @media (max-width: 769px) {
        .footer-wrapper .menu li {
          max-width: 100%;
          text-align: center; } }
      .footer-wrapper .menu li a {
        color: #fff;
        font-size: 15px;
        font-weight: 400; }
        .footer-wrapper .menu li a:hover {
          text-decoration: underline;
          color: #fff; }
  .footer-wrapper .footer-column-text {
    justify-content: center;
    align-items: center;
    display: flex; }
  .footer-wrapper .footer-column1 .wp-block-image {
    margin-bottom: 55px; }
    @media (max-width: 769px) {
      .footer-wrapper .footer-column1 .wp-block-image {
        margin-bottom: 25px; } }
    .footer-wrapper .footer-column1 .wp-block-image img {
      width: 275px;
      height: auto; }
      @media (max-width: 769px) {
        .footer-wrapper .footer-column1 .wp-block-image img {
          margin: 0 auto;
          display: block; } }
  .footer-wrapper .footer-column4 p {
    margin: 0;
    line-height: 18px; }
    @media (max-width: 769px) {
      .footer-wrapper .footer-column4 p {
        text-align: center; } }
  .footer-wrapper .footer-column5 p {
    margin: 0;
    line-height: 28px; }
    @media (max-width: 769px) {
      .footer-wrapper .footer-column5 p {
        text-align: center; } }
  .footer-wrapper .footer-column5 h2 {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 23px;
    margin-top: 52px; }
    @media (max-width: 769px) {
      .footer-wrapper .footer-column5 h2 {
        margin-top: 15px;
        text-align: center; } }
  @media (max-width: 769px) {
    .footer-wrapper .footer-column5 .wp-block-spacer {
      display: none; } }
  @media (max-width: 992px) {
    .footer-wrapper .col-md-2 {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 769px) {
    .footer-wrapper .col-md-2 {
      max-width: 100%;
      flex: 0 0 100%; } }
  .footer-wrapper .col-md-2:first-child {
    max-width: 22%;
    flex: 0 0 22%; }
    @media (max-width: 992px) {
      .footer-wrapper .col-md-2:first-child {
        max-width: 100%;
        flex: 0 0 100%; } }
    @media (max-width: 769px) {
      .footer-wrapper .col-md-2:first-child {
        max-width: 100%;
        flex: 0 0 100%; } }
  @media (max-width: 769px) {
    .footer-wrapper .col-md-2:last-child {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap; } }
  .footer-wrapper .col-md-2:last-child .widget_media_image {
    display: inline-block; }
    @media (max-width: 769px) {
      .footer-wrapper .col-md-2:last-child .widget_media_image {
        float: left; } }
    .footer-wrapper .col-md-2:last-child .widget_media_image img {
      margin-right: 11px;
      max-width: 80px; }
  @media (max-width: 769px) {
    .footer-wrapper .col-md-2:last-child #block-6 {
      width: 100%; } }

.copyright {
  color: #CCCDD1;
  font-size: 11px;
  line-height: 20px; }
  .copyright p, .copyright span {
    color: #CCCDD1;
    font-size: 11px;
    line-height: 20px; }
  @media (max-width: 769px) {
    .copyright {
      text-align: center; } }
  .copyright .footer-column-copyright {
    display: inline-block; }

.realization {
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  @media (max-width: 769px) {
    .realization {
      text-align: center;
      justify-content: center; } }
  .realization a {
    display: flex;
    color: #fff; }
    .realization a:hover {
      color: #fff; }
  .realization p {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0; }
  .realization img {
    margin-left: 15px; }

a.toTop {
  position: absolute;
  background: #0eb1a4;
  width: 50px;
  height: 50px;
  z-index: 4;
  right: 50px;
  top: -80px;
  border-radius: 50%; }
  @media (max-width: 769px) {
    a.toTop {
      right: 20px;
      top: -15px; } }
  a.toTop:after {
    content: url(../../assets/images/menu.svg);
    width: 50px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg); }
  a.toTop:hover {
    box-shadow: 0px 3px 10px #E1ECEC; }
    a.toTop:hover:after {
      height: 35px;
      transition: all 0.3s ease-in-out; }

#menu-social-media {
  padding: 0;
  list-style: none;
  margin-top: 35px;
  margin-bottom: 45px;
  display: block;
  overflow: hidden; }
  @media (max-width: 769px) {
    #menu-social-media {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex; } }
  #menu-social-media li {
    float: left;
    margin-right: 10px; }
    #menu-social-media li a {
      font-size: 0;
      position: relative;
      width: 25px;
      height: 25px;
      display: block; }
      #menu-social-media li a:before {
        content: '';
        display: block;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    #menu-social-media li.facebook a:before {
      background-image: url(../../assets/images/facebook.png); }
    #menu-social-media li.instagram a:before {
      background-image: url(../../assets/images/instagram.png); }
    #menu-social-media li.tiktok a:before {
      background-image: url(../../assets/images/tiktok.png); }
    #menu-social-media li.youtube a:before {
      background-image: url(../../assets/images/youtube.png); }

a, button, .btn, .wp-block-button.is-style-outline .wp-block-button__link, .wp-block-button .wp-block-button__link, input {
  transition: all 0.3s ease-in-out;
  outline: none; }

.btn-primary, .wp-block-button .wp-block-button__link, input[type=submit] {
  background-color: #0EB1A4;
  border-color: #0EB1A4;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  border-radius: 0px;
  letter-spacing: 0.6px;
  padding: 12px 55px;
  position: relative;
  border: 0;
  padding-right: 75px; }
  .btn-primary:hover, .wp-block-button .wp-block-button__link:hover, .btn-primary:active, .wp-block-button .wp-block-button__link:active, .btn-primary:focus, .wp-block-button .wp-block-button__link:focus, input[type=submit]:hover, input[type=submit]:active, input[type=submit]:focus {
    text-decoration: none;
    background: #00847B !important; }
  .btn-primary:after, .wp-block-button .wp-block-button__link:after, input[type=submit]:after {
    content: '' !important;
    background-image: url(../../assets/images/menu.svg);
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 9;
    top: 10px;
    margin-left: 10px;
    background-size: cover;
    transform: rotate(-90deg); }
  .btn-primary.white:hover, .wp-block-button .white.wp-block-button__link:hover, input[type=submit].white:hover {
    text-decoration: none;
    color: #0EB1A4;
    background: #fff; }

.btn.btn-secondary, .wp-block-button .btn-secondary.wp-block-button__link {
  min-height: 70px;
  background: transparent;
  color: #242934;
  font-size: 16px;
  line-height: 20px;
  padding: 12px;
  font-weight: bold;
  border-radius: 0;
  border: 3px solid #0EB1A4;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 260px;
  margin: 0 auto; }
  .btn.btn-secondary:hover, .wp-block-button .btn-secondary.wp-block-button__link:hover, .btn.btn-secondary:active, .wp-block-button .btn-secondary.wp-block-button__link:active, .btn.btn-secondary:focus, .wp-block-button .btn-secondary.wp-block-button__link:focus {
    text-decoration: none;
    color: #fff;
    background: #0EB1A4 !important; }

.page .woocommerce button.button {
  border-radius: 0;
  font-family: "Encode Sans", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.6px; }
  @media (max-width: 769px) {
    .page .woocommerce button.button {
      width: 100%; } }

.wp-block-button .wp-block-button__link:after {
  content: '';
  background-image: url(../../assets/images/menu.svg);
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 9;
  top: 10px;
  margin-left: 10px;
  background-size: cover;
  transform: rotate(-90deg); }
  @media (max-width: 769px) {
    .wp-block-button .wp-block-button__link:after {
      margin-left: 0; } }

@media (max-width: 769px) {
  .wp-block-button .wp-block-button__link {
    font-size: 14px;
    padding: 12px 25px 12px 15px; } }

.homepage-slider {
  height: 350px;
  margin-bottom: 60px; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .homepage-slider {
      margin-bottom: 25px; } }
  @media (max-width: 769px) {
    .homepage-slider {
      margin-bottom: 20px;
      height: auto; } }
  @media (max-width: 769px) {
    .homepage-slider .col-md-12 {
      padding: 0; } }
  @media (max-width: 769px) {
    .homepage-slider .desctop {
      display: none; } }
  .homepage-slider .mobile {
    display: none; }
    @media (max-width: 769px) {
      .homepage-slider .mobile {
        display: block;
        object-position: center center;
        object-fit: cover;
        width: 100%; } }
  .homepage-slider .slide-img {
    display: none; }
    .homepage-slider .slide-img:first-child {
      display: block; }
    .homepage-slider .slide-img.slick-slide {
      display: block; }
    .homepage-slider .slide-img img {
      height: 350px; }
      @media (max-width: 769px) {
        .homepage-slider .slide-img img {
          height: auto;
          object-fit: contain; } }
      @media (max-width: 1250px) and (min-width: 769px) {
        .homepage-slider .slide-img img {
          height: 250px;
          object-position: center center;
          object-fit: cover;
          width: 100%; } }
  @media (max-width: 1250px) and (min-width: 769px) {
    .homepage-slider .slick-dots {
      bottom: 8px; } }
  @media (max-width: 769px) {
    .homepage-slider .slick-dots {
      bottom: -30px; } }
  .homepage-slider .slick-dotted.slick-slider {
    margin-bottom: 0; }
    @media (max-width: 992px) {
      .homepage-slider .slick-dotted.slick-slider {
        margin-bottom: 20px; } }

.home-under-slider {
  margin-bottom: 55px; }
  @media (max-width: 769px) {
    .home-under-slider {
      padding: 0;
      margin-bottom: 25px; } }
  .home-under-slider .h2 {
    max-width: 415px;
    color: #242934;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    margin: 0 auto 35px auto;
    text-align: center; }
    @media (max-width: 769px) {
      .home-under-slider .h2 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px;
        margin: 0 auto 20px auto; } }
  .home-under-slider .contact-text p {
    max-width: 1076px;
    color: #242934;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.8px;
    font-family: "Encode Sans", sans-serif;
    text-align: center;
    margin: 0 auto 60px auto; }
    @media (max-width: 769px) {
      .home-under-slider .contact-text p {
        font-size: 16px;
        line-height: 25px;
        margin: 0 auto 20px auto; } }
  .home-under-slider .icon-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #242934;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    font-weight: bold; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .home-under-slider .icon-info {
        margin-bottom: 15px; } }
    @media (max-width: 769px) {
      .home-under-slider .icon-info {
        margin-bottom: 15px; } }
    .home-under-slider .icon-info img {
      margin-right: 10px; }

.uniszki-bestsellers {
  overflow: hidden;
  display: block;
  background-color: #F4F4F7;
  padding: 84px 0; }
  @media (max-width: 769px) {
    .uniszki-bestsellers {
      padding: 30px 0; } }
  .uniszki-bestsellers .wc-block-grid__products {
    max-width: 1296px;
    margin: 0 auto 58px auto;
    display: block; }
    .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product {
      padding: 10px;
      background-color: #fff;
      margin: 0px 18px;
      border: 0;
      flex: 100%;
      max-width: 100%;
      display: block;
      float: left; }
      @media (max-width: 769px) {
        .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product {
          padding: 20px !important; } }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-image {
        margin: 0 0 13px 0;
        border: 2px solid #F4F4F7;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product a {
        text-decoration: none; }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-title {
        min-height: 48px;
        text-align: left;
        color: #242934;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        margin-bottom: 4px;
        text-decoration: none !important;
        margin: 0 0 10px 0; }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product .description {
        text-align: left;
        color: #242934;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.56px;
        font-weight: normal;
        margin-bottom: 13px; }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product .add_to_cart_button {
        position: absolute;
        top: 275px;
        right: 20px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important; }
        .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product .add_to_cart_button:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0; }
        .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product .add_to_cart_button:hover::after {
          background-image: url(../../assets/images/koszyk2.svg); }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-price {
        text-align: left;
        color: #242934;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 1.28px;
        font-weight: bold;
        margin: 10px 0px; }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product:hover .wc-block-grid__product-title {
        color: #0EB1A4; }
      .uniszki-bestsellers .wc-block-grid__products .wc-block-grid__product:hover .wc-block-grid__product-image img {
        transform: scale(1.1);
        transition: all 0.3s ease-in-out; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .uniszki-bestsellers .slick-dots {
      bottom: -40px; } }

.homepage_products {
  overflow: hidden;
  display: block;
  background-color: #f4f4f7;
  padding: 84px 0; }
  .homepage_products h2 {
    text-align: center;
    margin-bottom: 45px; }
  .homepage_products .wp-block-button {
    text-align: center;
    margin-top: 45px; }
  .homepage_products ul {
    list-style: none;
    padding: 0; }
    .homepage_products ul li {
      padding: 10px;
      background-color: #fff;
      margin: 0px 18px;
      border: 0;
      flex: 100%;
      max-width: 100%;
      display: block;
      float: left;
      position: relative; }
      @media (max-width: 769px) {
        .homepage_products ul li {
          padding: 20px !important; } }
      .homepage_products ul li .category-list {
        text-align: left;
        color: #242934;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.3px;
        font-weight: normal; }
      .homepage_products ul li img {
        margin: 0 0 13px 0;
        border: 2px solid #F4F4F7;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        min-height: 330px;
        object-fit: contain; }
      .homepage_products ul li a {
        text-decoration: none; }
      .homepage_products ul li .woocommerce-loop-product__title {
        min-height: 48px;
        text-align: left;
        color: #242934;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        margin-bottom: 4px;
        text-decoration: none !important;
        margin: 0 0 10px 0; }
      .homepage_products ul li .description {
        text-align: left;
        color: #242934;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.56px;
        font-weight: normal; }
      .homepage_products ul li .add_to_cart_button {
        display: none; }
      .homepage_products ul li .yith-wcqv-button {
        position: absolute;
        top: 275px;
        right: 20px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important; }
        .homepage_products ul li .yith-wcqv-button:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0; }
        .homepage_products ul li .yith-wcqv-button:hover::after {
          background-image: url(../../assets/images/koszyk2.svg); }
      .homepage_products ul li .star-rating {
        display: none; }
      .homepage_products ul li .price {
        text-align: left;
        color: #242934;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 1.28px;
        font-weight: bold;
        margin: 10px 0px; }
      .homepage_products ul li:hover .woocommerce-loop-product__title {
        color: #0EB1A4; }
      .homepage_products ul li.product_cat-dla-dioz::before {
        content: '';
        background-image: url(../../assets/images/dioz-produkt.png);
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 9;
        background-size: cover;
        transform: none;
        top: 10px;
        right: 10px; }
      .homepage_products ul li.product_cat-dla-dioz .new-badge {
        display: none; }

@media (max-width: 769px) {
  .newsletter-section-wrapper {
    padding: 0;
    overflow: hidden; } }

.wrapper-why-us-section {
  padding: 80px 0;
  height: 900px;
  background-size: cover;
  background-position: center; }
  @media (max-width: 1199px) {
    .wrapper-why-us-section {
      height: auto;
      background-image: none !important; } }
  .wrapper-why-us-section .title {
    max-width: 415px;
    color: #242934;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    margin: 0 auto 20px auto;
    font-weight: bold;
    text-align: center; }
  .wrapper-why-us-section .row {
    justify-content: space-between; }
    .wrapper-why-us-section .row .col-sm-12:first-child, .wrapper-why-us-section .row .col-sm-12:nth-child(2) {
      margin-bottom: 114px; }
      @media (max-width: 1199px) {
        .wrapper-why-us-section .row .col-sm-12:first-child, .wrapper-why-us-section .row .col-sm-12:nth-child(2) {
          margin-bottom: 20px; } }
    @media (max-width: 1199px) {
      .wrapper-why-us-section .row .col-sm-12 {
        margin-bottom: 20px; } }
    .wrapper-why-us-section .row .icon {
      max-width: 330px;
      margin-bottom: rem(120px); }
      @media (max-width: 1024px) and (min-width: 320px) {
        .wrapper-why-us-section .row .icon {
          max-width: 100%; } }
      .wrapper-why-us-section .row .icon img {
        margin-bottom: 20px; }
      .wrapper-why-us-section .row .icon .title {
        max-width: 100%;
        margin: 0;
        color: #FFB600;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.8px;
        text-align: left;
        font-weight: bold;
        margin-bottom: 10px; }
      .wrapper-why-us-section .row .icon .opis p {
        letter-spacing: 0.6px; }
    .wrapper-why-us-section .row .col-lg-6:nth-child(even) {
      justify-content: flex-end;
      display: flex; }
      @media (max-width: 769px) {
        .wrapper-why-us-section .row .col-lg-6:nth-child(even) {
          justify-content: flex-start; } }

.newsletter-wrapper {
  min-height: 340px;
  margin: 100px 0;
  display: flex;
  flex-direction: row;
  padding: 57px;
  position: relative; }
  @media (max-width: 769px) {
    .newsletter-wrapper {
      margin: 30px 0px; } }
  .newsletter-wrapper::after {
    content: url(../../assets/images/newsletter.png);
    position: absolute;
    right: 0;
    top: -28px;
    bottom: -7px; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .newsletter-wrapper::after {
        top: auto;
        bottom: -7px; } }
    @media (max-width: 769px) {
      .newsletter-wrapper::after {
        content: none; } }
  @media (max-width: 769px) {
    .newsletter-wrapper {
      flex-direction: column;
      padding: 25px; } }
  .newsletter-wrapper .column {
    width: 38%;
    padding-right: 2%; }
    @media (max-width: 769px) {
      .newsletter-wrapper .column {
        width: 100%;
        display: block;
        overflow: hidden;
        padding-right: 0; } }
    .newsletter-wrapper .column .title p {
      color: #fff;
      font-size: 26px;
      line-height: 40px;
      letter-spacing: 1.4px;
      text-align: left; }
    .newsletter-wrapper .column .text p {
      color: #fff;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.7px;
      font-family: "Encode Sans", sans-serif; }

.index-blog-posts .blog-post {
  padding: 0 0 43px 0;
  margin: 0px 15px;
  max-width: 30%;
  flex: 30%; }
  @media (max-width: 992px) {
    .index-blog-posts .blog-post {
      margin: 0 0 20px 0;
      max-width: 100%;
      flex: 100%; } }
  .index-blog-posts .blog-post .post-img {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    height: 280px; }
    .index-blog-posts .blog-post .post-img .tag-links {
      position: absolute;
      top: 6px;
      display: block;
      z-index: 4;
      font-size: 0; }
      .index-blog-posts .blog-post .post-img .tag-links .tag {
        background-color: #242934;
        color: #fff;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: "Encode Sans", sans-serif;
        padding: 10px;
        margin-right: 5px;
        text-decoration: none; }
    .index-blog-posts .blog-post .post-img img {
      width: 100%;
      object-fit: cover;
      height: 100%; }
  .index-blog-posts .blog-post .title {
    display: block;
    min-height: 46px;
    color: #242934;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 11px;
    padding: 0 20px;
    text-align: left; }
  .index-blog-posts .blog-post .post-text {
    color: #242934;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    padding: 0 20px; }
    .index-blog-posts .blog-post .post-text a {
      color: #242934;
      text-decoration: none !important; }
  .index-blog-posts .blog-post .btn, .index-blog-posts .blog-post .wp-block-button .wp-block-button__link, .wp-block-button .index-blog-posts .blog-post .wp-block-button__link {
    display: block;
    overflow: hidden;
    float: right;
    position: relative;
    margin-top: 15px;
    width: 50%;
    justify-content: flex-end;
    left: auto;
    right: 0;
    position: absolute;
    color: #fff !important; }
    @media (max-width: 769px) {
      .index-blog-posts .blog-post .btn, .index-blog-posts .blog-post .wp-block-button .wp-block-button__link, .wp-block-button .index-blog-posts .blog-post .wp-block-button__link {
        width: 90%;
        float: none;
        right: auto; } }
    .index-blog-posts .blog-post .btn:after, .index-blog-posts .blog-post .wp-block-button .wp-block-button__link:after, .wp-block-button .index-blog-posts .blog-post .wp-block-button__link:after {
      content: '';
      background-image: url(../../assets/images/menu.svg);
      width: 25px;
      height: 25px;
      position: absolute;
      z-index: 9;
      top: 10px;
      margin-left: 10px;
      background-size: cover;
      transform: rotate(-90deg); }
  .index-blog-posts .blog-post:hover {
    box-shadow: 0px 3px 10px #E1ECEC;
    cursor: pointer; }
    .index-blog-posts .blog-post:hover .wp-block-latest-posts__featured-image img {
      transform: scale(1.2);
      transition: all 0.3s ease-in-out; }

.opinie_slider .title {
  text-align: center;
  margin-bottom: 54px; }
  @media (max-width: 769px) {
    .opinie_slider .title {
      margin-bottom: 25px; } }

.opinie_slider .slide-item {
  background-color: #fff;
  border: 2px solid #F5F5F5;
  padding: 85px 30px;
  text-align: center;
  margin-top: 50px !important; }
  @media (max-width: 769px) {
    .opinie_slider .slide-item {
      margin-top: 0px !important; } }
  .opinie_slider .slide-item .h3 {
    color: #242934;
    margin-bottom: 27px;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.8px;
    font-weight: bold; }
  .opinie_slider .slide-item .text,
  .opinie_slider .slide-item p {
    color: #242934;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.5px; }
  .opinie_slider .slide-item.slick-center {
    box-shadow: 0px 3px 10px #E1ECEC;
    margin-top: 0 !important; }

.opinie_slider .slick-slide {
  margin: 0 50px; }
  @media (max-width: 769px) {
    .opinie_slider .slick-slide {
      margin: 0; } }

.opinie_slider .slick-list {
  margin: 0 -50px; }
  @media (max-width: 769px) {
    .opinie_slider .slick-list {
      margin: 0; } }

.opinie_slider .slick-dots {
  bottom: -10px; }
  @media (max-width: 769px) {
    .opinie_slider .slick-dots {
      bottom: -35px; } }

.opinie_slider .slick-arrow {
  top: 50%; }
  .opinie_slider .slick-arrow.slick-prev {
    left: 25px; }
    @media (max-width: 769px) {
      .opinie_slider .slick-arrow.slick-prev {
        left: -15px; } }
  .opinie_slider .slick-arrow.slick-next {
    right: 25px; }
    @media (max-width: 769px) {
      .opinie_slider .slick-arrow.slick-next {
        right: -15px; } }

.description-toggle__more {
  display: none;
  position: relative;
  float: right;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  font-family: "Encode Sans", sans-serif;
  color: #242934;
  cursor: pointer;
  padding-right: 25px; }
  @media (max-width: 769px) {
    .description-toggle__more {
      display: block; } }
  .description-toggle__more:hover {
    color: #0EB1A4;
    text-decoration: none; }
  .description-toggle__more:after {
    content: url(../../assets/images/menu.svg);
    filter: brightness(0);
    display: -ms-flexbox;
    display: flex;
    height: 25px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 3px;
    width: 10px; }
  .description-toggle__more.active .first {
    display: none; }
  .description-toggle__more.active .hide {
    display: block; }
  .description-toggle__more.active:after {
    transform: rotate(180deg);
    top: -3px; }

#main {
  overflow: hidden; }

@media (max-width: 992px) {
  .main.first {
    padding-top: 136px !important; } }

.wp-block-buttons {
  justify-content: center; }
  .wp-block-buttons .wp-block-button {
    text-align: center; }

@media (max-width: 769px) {
  .wp-block-group__inner-container .has-text-align-center.no-margin {
    max-width: 98%; } }

.flaticon-back {
  transform: rotate(90deg); }
  .flaticon-back:before {
    content: url(../../assets/images/menu.svg) !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center; }

.flaticon-right-arrow {
  transform: rotate(-90deg); }
  .flaticon-right-arrow:before {
    content: url(../../assets/images/menu.svg) !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center; }

@media (max-width: 1250px) and (min-width: 769px) {
  .row {
    margin: 0; } }

.small-12 {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto; }

p.woocommerce-info.woocommerce-no-products-found {
  border-radius: 0;
  font-weight: bold; }
  p.woocommerce-info.woocommerce-no-products-found::before {
    content: none; }

body {
  scroll-behavior: smooth; }
  body .slick-arrow {
    z-index: 9;
    width: 40px;
    height: 40px;
    top: 45%;
    background-color: #242934; }
    body .slick-arrow:hover {
      background-color: #0EB1A4; }
    @media (max-width: 769px) {
      body .slick-arrow {
        top: 40%; } }
    body .slick-arrow.slick-prev {
      left: 0;
      transform: rotate(90deg); }
      body .slick-arrow.slick-prev:before {
        opacity: 1;
        content: url(../../assets/images/menu.svg); }
    body .slick-arrow.slick-next {
      right: 0;
      transform: rotate(-90deg); }
      body .slick-arrow.slick-next:before {
        opacity: 1;
        content: url(../../assets/images/menu.svg); }
  body .slick-dots {
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 769px) {
      body .slick-dots {
        bottom: 15px; } }
    body .slick-dots li {
      display: flex;
      justify-content: center;
      align-items: center; }
      body .slick-dots li button {
        width: 19px;
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center; }
        body .slick-dots li button::before {
          content: '';
          width: 14px;
          height: 14px;
          font-size: 0;
          opacity: 1;
          border-radius: 50%;
          top: 3px;
          background-color: #6BD9D1;
          left: 0;
          right: 0;
          margin: 0 auto; }
      body .slick-dots li.slick-active button::before {
        width: 19px;
        height: 19px;
        top: 0;
        background-color: #00847B; }
  body.overflow {
    overflow: hidden; }

.gray-bg {
  background-color: #F4F4F7;
  padding: 90px 0;
  margin-bottom: 80px; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .gray-bg {
      padding: 55px 0;
      margin-bottom: 40px; } }
  @media (max-width: 769px) {
    .gray-bg {
      padding: 25px 0;
      margin-bottom: 30px; } }
  .gray-bg.seo_text-parent {
    margin-bottom: 0; }
    .gray-bg.seo_text-parent .text p {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.5px; }
    .gray-bg.seo_text-parent .seo_text {
      overflow: hidden; }
      @media (max-width: 769px) {
        .gray-bg.seo_text-parent .seo_text {
          height: 330px; } }
      .gray-bg.seo_text-parent .seo_text.active {
        height: 100%; }

.bg-dark {
  background-color: #242934 !important; }

@media (max-width: 1250px) and (min-width: 769px) {
  .breadcrumbs {
    padding: 0; } }

@media (max-width: 769px) {
  .breadcrumbs {
    padding: 0; } }

.breadcrumbs .breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  padding: 20px 0px; }
  .breadcrumbs .breadcrumb li {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.4px;
    font-family: "Encode Sans", sans-serif;
    color: #242934; }
    .breadcrumbs .breadcrumb li a {
      color: #242934;
      text-decoration: none; }
      .breadcrumbs .breadcrumb li a:hover {
        color: #0EB1A4; }
    .breadcrumbs .breadcrumb li.active {
      text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:before {
  content: ">";
  color: #242934; }

nav.woocommerce-breadcrumb {
  max-width: 1290px;
  margin: 0 auto !important;
  font-size: 12px !important; }
  @media (max-width: 992px) {
    nav.woocommerce-breadcrumb {
      margin: 0px 20px !important; } }
  nav.woocommerce-breadcrumb a {
    font-size: 12px;
    position: relative;
    color: #242934;
    text-decoration: none;
    position: relative;
    padding-right: 5px;
    margin-right: 5px; }
    nav.woocommerce-breadcrumb a:after {
      content: '>';
      position: absolute;
      background: #fff;
      right: -15px;
      width: 15px;
      top: -3px; }
    nav.woocommerce-breadcrumb a:hover {
      color: #0EB1A4; }

.history_timeline {
  overflow: hidden; }
  .history_timeline .title-main {
    text-align: center;
    color: #0EB1A4;
    margin-bottom: 30px; }
  .history_timeline .history_timeline-wrapper {
    position: relative;
    overflow: hidden; }
    .history_timeline .history_timeline-wrapper:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -2px;
      height: 100%;
      width: 2px;
      background-color: #0EB1A4; }
      @media (max-width: 769px) {
        .history_timeline .history_timeline-wrapper:before {
          left: 0;
          margin-left: 20px; } }
    .history_timeline .history_timeline-wrapper .cd-timeline-icon.first {
      display: block;
      position: absolute;
      background: #0EB1A4;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      left: 50%;
      margin-left: -16px;
      top: 0; }
      @media (max-width: 769px) {
        .history_timeline .history_timeline-wrapper .cd-timeline-icon.first {
          left: 0;
          margin-left: 7px; } }
    .history_timeline .history_timeline-wrapper .cd-timeline-icon.last {
      display: block;
      position: absolute;
      background: #0EB1A4;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      left: 50%;
      margin-left: -16px;
      bottom: 0; }
      @media (max-width: 769px) {
        .history_timeline .history_timeline-wrapper .cd-timeline-icon.last {
          left: 0;
          margin-left: 7px; } }
    .history_timeline .history_timeline-wrapper .cd-timeline-block {
      padding-left: 100px;
      margin: 4em auto;
      overflow: hidden;
      width: 100%;
      position: relative; }
      @media (max-width: 1250px) and (min-width: 769px) {
        .history_timeline .history_timeline-wrapper .cd-timeline-block {
          padding: 0; } }
      @media (max-width: 769px) {
        .history_timeline .history_timeline-wrapper .cd-timeline-block {
          padding: 0; } }
      .history_timeline .history_timeline-wrapper .cd-timeline-block:nth-child(odd) {
        margin-top: -270px; }
        @media (max-width: 769px) {
          .history_timeline .history_timeline-wrapper .cd-timeline-block:nth-child(odd) {
            margin-top: 0; } }
      .history_timeline .history_timeline-wrapper .cd-timeline-block.before-one::before {
        background-image: url(../../assets/images/icon-before.png);
        content: '';
        position: absolute;
        width: 60px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 70px;
        left: 30px; }
        @media (max-width: 769px) {
          .history_timeline .history_timeline-wrapper .cd-timeline-block.before-one::before {
            content: none; } }
      .history_timeline .history_timeline-wrapper .cd-timeline-block.before-two::before {
        background-image: url(../../assets/images/icon-before.png);
        content: '';
        position: absolute;
        width: 60px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 120px;
        left: 30px; }
        @media (max-width: 769px) {
          .history_timeline .history_timeline-wrapper .cd-timeline-block.before-two::before {
            content: none; } }
      .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-icon {
        width: 20px;
        height: 20px;
        left: 50%;
        margin-left: -11px;
        margin-top: 100px;
        background: #0EB1A4;
        border-radius: 50%;
        position: absolute; }
        @media (max-width: 769px) {
          .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-icon {
            left: 0;
            margin-left: 11px; } }
      .history_timeline .history_timeline-wrapper .cd-timeline-block:nth-child(odd) .cd-timeline-content {
        float: right;
        align-items: flex-start; }
        @media (max-width: 769px) {
          .history_timeline .history_timeline-wrapper .cd-timeline-block:nth-child(odd) .cd-timeline-content {
            float: left; } }
      .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-content {
        width: 42%;
        margin: 0 3%;
        display: flex;
        flex-direction: column; }
        @media (max-width: 769px) {
          .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-content {
            width: 85%;
            margin: 0 0 0 15%; } }
        .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-content img {
          margin-bottom: 50px; }
          @media (max-width: 769px) {
            .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-content img {
              margin-bottom: 20px;
              height: auto;
              width: 100%;
              object-fit: cover; } }
        .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-content h3 {
          max-width: 415px;
          margin-bottom: 26px;
          font-weight: 400; }
          @media (max-width: 769px) {
            .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-content h3 {
              margin-bottom: 15px; } }
        .history_timeline .history_timeline-wrapper .cd-timeline-block .cd-timeline-content p {
          max-width: 415px;
          margin-bottom: 0; }

.navigation {
  width: 100%;
  display: block;
  overflow: hidden; }
  .navigation ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .navigation ul li a {
      width: 40px;
      height: 40px;
      font-weight: bold;
      color: #757882;
      padding: 10px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.6px;
      text-decoration: none; }
      .navigation ul li a:hover {
        color: #0EB1A4;
        text-decoration: none; }
    .navigation ul li.active a {
      color: #242934;
      text-decoration: none; }
    .navigation ul li .btn.pagination, .navigation ul li .wp-block-button .pagination.wp-block-button__link, .wp-block-button .navigation ul li .pagination.wp-block-button__link {
      font-size: 0;
      background-color: #242934;
      width: 40px;
      height: 40px;
      position: relative;
      border-radius: 0;
      transform: rotate(-90deg);
      padding: 0; }
      .navigation ul li .btn.pagination:before, .navigation ul li .wp-block-button .pagination.wp-block-button__link:before, .wp-block-button .navigation ul li .pagination.wp-block-button__link:before {
        width: 40px;
        height: 40px;
        opacity: 1;
        content: url(../../assets/images/menu.svg);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 5px; }
      .navigation ul li .btn.pagination:hover, .navigation ul li .wp-block-button .pagination.wp-block-button__link:hover, .wp-block-button .navigation ul li .pagination.wp-block-button__link:hover {
        background-color: #0EB1A4; }
    .navigation ul li:first-child .btn.pagination, .navigation ul li:first-child .wp-block-button .pagination.wp-block-button__link, .wp-block-button .navigation ul li:first-child .pagination.wp-block-button__link {
      transform: rotate(90deg); }

.commentlist {
  list-style: none;
  margin: 0 auto;
  width: 100%;
  padding: 0; }

.new-badge {
  background: #FFB600;
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  padding: 5px 20px;
  position: absolute;
  left: 0px;
  top: 25px;
  z-index: 2; }

@media (max-width: 1250px) and (min-width: 769px) {
  .wp-block-spacer {
    height: 30px !important; } }

@media (max-width: 769px) {
  .wp-block-spacer {
    height: 30px !important; } }

.wp-block-group__inner-container a,
.wp-block-column a {
  color: #000; }
  .wp-block-group__inner-container a:hover,
  .wp-block-column a:hover {
    color: #0EB1A4;
    text-decoration: none; }

.wp-block-navigation .wp-block-navigation-item {
  font-weight: bold; }
  .wp-block-navigation .wp-block-navigation-item:hover {
    color: #0EB1A4; }
  .wp-block-navigation .wp-block-navigation-item.current-menu-item a {
    color: #0EB1A4; }

.modal-content.review {
  border: 0;
  border-radius: 0; }
  .modal-content.review input,
  .modal-content.review textarea {
    width: 100%;
    border: 0;
    border-radius: 0;
    padding: 7px; }
  .modal-content.review .form-submit #submit {
    border-radius: 0; }
  .modal-content.review .modal-title {
    color: #242934;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold; }
    @media (max-width: 769px) {
      .modal-content.review .modal-title {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px; } }
  .modal-content.review .modal-body {
    background: #F2F3F7; }
  .modal-content.review .modal-header {
    background: #F2F3F7; }

@media (max-width: 769px) {
  .woocommerce-page .breadcrumbs {
    padding: 0 20px; } }

.woocommerce-checkout #payment div.payment_box {
  background: transparent;
  padding: 0; }

.image-wrap {
  text-align: left;
  font-size: 14px;
  line-height: 20px; }
  .image-wrap > a {
    font-size: 14px;
    line-height: 20px;
    text-align: left; }

.tag-links .blue {
  background-color: #0EB1A4 !important; }

.tag-links .yellow {
  background-color: #FFB600 !important; }

.woocommerce-message {
  background: #0eb1a4 !important;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0; }
  .woocommerce-message::before, .woocommerce-message::after {
    content: none !important; }

.wp-block-search__inside-wrapper {
  display: flex; }

.woocommerce-error {
  background: #dc3023 !important;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0; }
  .woocommerce-error::before, .woocommerce-error::after {
    content: none; }

.wc-block-components-price-slider__range-input-wrapper:before {
  background: #000; }

.wc-block-price-filter .wc-block-components-price-slider__range-input-wrapper {
  color: #0EB1A4; }

.podziekowanie-section {
  height: 100vh;
  position: relative; }
  .podziekowanie-section::before {
    content: '';
    background-image: url(../../assets/images/tlo.svg);
    width: 243px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
    @media (max-width: 769px) {
      .podziekowanie-section::before {
        width: 50px; } }
  .podziekowanie-section::after {
    content: '';
    background-image: url(../../assets/images/tlo.svg);
    width: 243px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
    @media (max-width: 769px) {
      .podziekowanie-section::after {
        width: 50px;
        right: -20px; } }
  .podziekowanie-section .content {
    max-width: 745px;
    margin: 0 auto;
    text-align: center;
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (max-width: 769px) {
      .podziekowanie-section .content {
        width: 100%;
        padding: 0px 20px 0px 20px; } }
  .podziekowanie-section .h2 {
    color: #0EB1A4;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 20px; }
    @media (max-width: 769px) {
      .podziekowanie-section .h2 {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.8px; } }
  .podziekowanie-section .text p {
    color: #242934;
    font-size: 26px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    font-weight: normal;
    margin-bottom: 20px; }
    @media (max-width: 769px) {
      .podziekowanie-section .text p {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.8px; } }
  @media (max-width: 769px) {
    .podziekowanie-section .text img {
      width: 100%;
      height: auto; } }
  .podziekowanie-section .btn-primary, .podziekowanie-section .wp-block-button .wp-block-button__link, .wp-block-button .podziekowanie-section .wp-block-button__link {
    background-color: #FFB600; }
    @media (max-width: 769px) {
      .podziekowanie-section .btn-primary, .podziekowanie-section .wp-block-button .wp-block-button__link, .wp-block-button .podziekowanie-section .wp-block-button__link {
        max-width: 280px;
        line-height: 22px; } }
    @media (max-width: 769px) {
      .podziekowanie-section .btn-primary:after, .podziekowanie-section .wp-block-button .wp-block-button__link:after, .wp-block-button .podziekowanie-section .wp-block-button__link:after {
        width: 30px;
        height: 30px;
        top: 20px; } }

.page-template-page_thankyou footer#footer {
  display: none; }

.page-template-page_thankyou header#masthead {
  display: none; }

.page-template-page_thankyou .main.first {
  padding: 0 !important; }

.woocommerce-error::before,
.woocommerce-info::before,
.woocommerce-message::before {
  content: none !important; }

div.grwf2-wrapper.wf2-embedded {
  z-index: 99 !important; }

.error-wrapper .entry-title {
  color: #0EB1A4;
  font-size: 170px;
  font-weight: bold;
  letter-spacing: 6px; }
  @media (max-width: 769px) {
    .error-wrapper .entry-title {
      font-size: 80px; } }
  .error-wrapper .entry-title span {
    display: block;
    color: #242934;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    text-transform: capitalize; }

.error-wrapper img {
  width: 100%; }

.error-wrapper a {
  color: #242934; }

.error-wrapper .entry-content {
  max-width: 415px; }
  .error-wrapper .entry-content p {
    color: #242934;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.8px;
    margin-bottom: 42px;
    font-weight: bold; }
  .error-wrapper .entry-content .btn, .error-wrapper .entry-content .wp-block-button .wp-block-button__link, .wp-block-button .error-wrapper .entry-content .wp-block-button__link {
    margin-bottom: 20px;
    width: 100%; }

.error-wrapper .search-title {
  font-weight: normal !important;
  letter-spacing: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 10px !important; }

.error-wrapper .search-form {
  width: 100%;
  position: relative;
  border: 1px solid #b3b3b3;
  margin-bottom: 62px; }
  .error-wrapper .search-form::before {
    content: '';
    background-image: url(../../assets/images/search.svg);
    position: absolute;
    right: 0px;
    top: 3px;
    width: 40px;
    height: 40px;
    display: block;
    z-index: 1;
    background-size: 80%;
    background-repeat: no-repeat; }
  .error-wrapper .search-form label {
    margin: 0; }
  .error-wrapper .search-form .search-field {
    width: 100%;
    border-radius: 0;
    height: 40px;
    border: 0;
    outline: none;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.56px;
    text-transform: lowercase;
    color: #757882; }
  .error-wrapper .search-form .search-submit {
    height: 40px;
    width: 47px;
    font-size: 0;
    border: 0;
    z-index: 3;
    background: transparent;
    margin: 0;
    position: relative;
    padding: 0 !important;
    float: right; }
    .error-wrapper .search-form .search-submit::after {
      display: none !important; }
    .error-wrapper .search-form .search-submit:hover {
      background-color: #0eb1a487 !important; }
  .error-wrapper .search-form .data-header {
    margin-top: 20px; }
  .error-wrapper .search-form .adress a {
    color: #242934; }

.error-wrapper #menu-social-media {
  justify-content: flex-start; }
  .error-wrapper #menu-social-media li a:before {
    filter: invert(1); }

.error-wrapper .row.d-flex.align-items-end {
  border-bottom: 3px solid #707070; }

.error-wrapper .data-header {
  padding-top: 60px; }

.woocommerce-Price-currencySymbol {
  margin-left: 7px; }

.page-template-page_black_week .black-week-banner .slide-item {
  text-align: center; }
  .page-template-page_black_week .black-week-banner .slide-item .mobile {
    display: none; }
    @media (max-width: 769px) {
      .page-template-page_black_week .black-week-banner .slide-item .mobile {
        display: block; } }
  @media (max-width: 769px) {
    .page-template-page_black_week .black-week-banner .slide-item .desctop {
      display: none; } }

.page-template-page_black_week .wc-block-product-category .wc-block-grid__product {
  padding: 10px;
  background-color: #fff;
  margin: 0px 18px;
  border: 0;
  flex: 100%;
  max-width: 100%;
  display: block;
  float: left; }
  @media (max-width: 769px) {
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product {
      width: 48% !important;
      margin-bottom: 20px !important; } }
  @media (max-width: 575px) {
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product {
      width: 100% !important; } }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .attachment-woocommerce_thumbnail {
    margin: 10px 13px !important;
    width: 90%;
    border: 2px solid #F4F4F7;
    height: 370px;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    object-fit: contain; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product a {
    text-decoration: none;
    color: #242934;
    font-size: 14px;
    line-height: 20px; }
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product a:nth-child(2) {
      padding-left: 20px; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .woocommerce-loop-product__title {
    text-align: left;
    color: #242934;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.6px;
    margin-bottom: 4px;
    text-decoration: none !important;
    margin: 0px !important;
    min-height: 58px;
    padding: 0px 20px 0px 20px !important; }
    @media (max-width: 769px) {
      .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .woocommerce-loop-product__title {
        min-height: auto; } }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .star-rating {
    display: none !important; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .price {
    padding: 0px 20px !important;
    text-align: left;
    color: #242934 !important;
    font-size: 22px !important;
    line-height: 26px;
    letter-spacing: 1.28px;
    font-weight: bold !important; }
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .price ins {
      text-decoration: none; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product:hover .woocommerce-loop-product__title {
    color: #0EB1A4; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .new-badge {
    background: #FFB600;
    color: #fff;
    font-size: 15px;
    line-height: 24px;
    padding: 5px 20px;
    position: absolute;
    left: 0px;
    top: 15px;
    z-index: 2; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .onsale {
    background: #ff3c00 !important;
    border-radius: 0 !important;
    left: 0 !important;
    width: 91px;
    top: 60px !important;
    margin: 0 !important;
    height: 34px; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product:hover {
    box-shadow: 0px 3px 10px #E1ECEC; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .category-list {
    padding: 0px 20px 10px 20px !important;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: .3px;
    font-weight: 400; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .description {
    text-align: left;
    color: #242934;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.56px;
    font-weight: normal;
    padding: 0px 20px 5px 20px !important; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .add_to_cart_button {
    display: none;
    position: absolute;
    top: 310px;
    right: 30px;
    width: 52px;
    height: 52px;
    background: transparent;
    font-size: 0;
    border-radius: 50% !important;
    padding: 0 !important; }
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .add_to_cart_button:after {
      content: '';
      background-image: url(../../assets/images/koszyk1.svg);
      width: 52px;
      height: 52px;
      position: absolute;
      z-index: 9;
      background-size: cover;
      transform: none;
      top: 0;
      left: 0; }
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .add_to_cart_button:hover::after {
      background-image: url(../../assets/images/koszyk2.svg); }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .yith-wcqv-button {
    position: absolute;
    top: 310px;
    right: 30px;
    width: 52px;
    height: 52px;
    background: transparent;
    font-size: 0;
    border-radius: 50% !important;
    padding: 0 !important; }
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .yith-wcqv-button:after {
      content: '';
      background-image: url(../../assets/images/koszyk1.svg);
      width: 52px;
      height: 52px;
      position: absolute;
      z-index: 9;
      background-size: cover;
      transform: none;
      top: 0;
      left: 0; }
    .page-template-page_black_week .wc-block-product-category .wc-block-grid__product .yith-wcqv-button:hover::after {
      background-image: url(../../assets/images/koszyk2.svg); }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product.product_cat-dla-dioz::before {
    content: '';
    background-image: url(../../assets/images/dioz-produkt.png);
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 9;
    background-size: cover;
    transform: none;
    top: 12px;
    right: 16px; }
  .page-template-page_black_week .wc-block-product-category .wc-block-grid__product.product_cat-dla-dioz .new-badge {
    display: none; }

.wpcf7 {
  max-width: 680px;
  padding: 0;
  margin: 0 auto; }
  .wpcf7 h2 {
    text-align: center;
    margin-bottom: 50px; }
  .wpcf7 p.full-input {
    overflow: hidden;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: column; }
    .wpcf7 p.full-input label {
      width: 100%;
      display: block;
      overflow: hidden;
      text-align: left; }
    .wpcf7 p.full-input.acceptance {
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      align-items: center; }
  .wpcf7 .half-input {
    width: 48%;
    float: left; }
    .wpcf7 .half-input:nth-child(odd) {
      margin-right: 2%; }
      @media (max-width: 769px) {
        .wpcf7 .half-input:nth-child(odd) {
          margin-right: 0; } }
    .wpcf7 .half-input:nth-child(even) {
      margin-left: 2%; }
      @media (max-width: 769px) {
        .wpcf7 .half-input:nth-child(even) {
          margin-left: 0; } }
    .wpcf7 .half-input.last {
      margin-top: 80px;
      margin-bottom: 50px; }
    @media (max-width: 769px) {
      .wpcf7 .half-input {
        width: 100%; } }
  .wpcf7 input,
  .wpcf7 textarea {
    border: 0px;
    width: 100%;
    color: #757882;
    padding: 12px 15px;
    border-radius: 0px;
    font-family: "Encode Sans", sans-serif;
    font-size: 14px;
    line-height: 17px; }
    .wpcf7 input::placeholder,
    .wpcf7 textarea::placeholder {
      color: #757882;
      font-family: "Encode Sans", sans-serif; }
    .wpcf7 input:focus,
    .wpcf7 textarea:focus {
      outline: none; }
  .wpcf7 input[type=submit] {
    width: auto;
    float: right;
    text-align: center;
    display: block;
    background-color: #0EB1A4;
    border: 0;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.6px;
    border-radius: 0;
    padding: 13px 55px;
    min-width: 195px; }
    .wpcf7 input[type=submit]:hover {
      text-decoration: none;
      background-color: #00847B; }
  .wpcf7 input[type="checkbox"] {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    margin: 0;
    opacity: 0;
    z-index: 1; }
    .wpcf7 input[type="checkbox"] + .wpcf7-list-item-label {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      width: 100%;
      display: block; }
      @media (max-width: 769px) {
        .wpcf7 input[type="checkbox"] + .wpcf7-list-item-label {
          padding-top: 0px;
          padding-left: 30px; } }
      .wpcf7 input[type="checkbox"] + .wpcf7-list-item-label::before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 18px;
        height: 18px;
        border-radius: 0;
        left: 0;
        top: 10px;
        transform: translateY(-50%);
        background: #fff;
        border: 0;
        position: absolute; }
        @media (max-width: 769px) {
          .wpcf7 input[type="checkbox"] + .wpcf7-list-item-label::before {
            width: 24px;
            height: 24px; } }
    .wpcf7 input[type="checkbox"]:checked + .wpcf7-list-item-label::before {
      content: '';
      background: #fff; }
    .wpcf7 input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
      content: '';
      background: transparent;
      position: absolute;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 5px;
      height: 10px;
      border-radius: 0;
      top: 3px;
      left: 7px;
      border: 2px solid #000;
      border-top: none;
      border-left: none;
      transform: rotate(40deg); }
      @media (max-width: 769px) {
        .wpcf7 input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
          left: 8px;
          height: 15px;
          top: 10px; } }
  .wpcf7 span.wpcf7-list-item {
    margin: 0;
    float: left; }
  .wpcf7 .text {
    display: block;
    margin-top: 0;
    float: left;
    font-size: 12px;
    font-weight: normal;
    font-family: "Encode Sans", sans-serif; }
    @media (max-width: 769px) {
      .wpcf7 .text {
        text-align: left; } }
    .wpcf7 .text a {
      display: inline-block !important;
      text-decoration: underline;
      color: #000 !important; }
      .wpcf7 .text a:hover {
        color: #0EB1A4; }
  .wpcf7 input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    position: relative;
    display: none; }

.wpcf7-not-valid-tip {
  text-align: left;
  font-size: 14px; }

@media (max-width: 1250px) and (min-width: 769px) {
  .contact-us-section {
    padding: 0; } }

@media (max-width: 769px) {
  .contact-us-section {
    padding: 0; } }

.contact-us-section .contact-text {
  max-width: 855px;
  font-size: 15px;
  line-height: 24px;
  color: #757882;
  letter-spacing: 0.6px;
  font-family: "Encode Sans", sans-serif;
  margin-bottom: 80px; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .contact-us-section .contact-text {
      margin-bottom: 30px; } }
  @media (max-width: 769px) {
    .contact-us-section .contact-text {
      margin-bottom: 30px; } }

.map-full-section .row {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: row; }
  .map-full-section .row .col-md-6 {
    padding: 0; }
    @media (max-width: 769px) {
      .map-full-section .row .col-md-6 {
        padding: 15px; } }
  .map-full-section .row .data-header {
    max-width: 415px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.6px;
    color: #242934;
    font-weight: bold;
    margin-bottom: 24px;
    font-family: "Encode Sans", sans-serif; }
  .map-full-section .row .adress {
    max-width: 330px;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #242934;
    margin-bottom: 24px;
    font-family: "Encode Sans", sans-serif; }
  .map-full-section .row p {
    margin: 0;
    color: #171717;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.5px; }
    .map-full-section .row p a {
      color: #171717; }
      .map-full-section .row p a:hover {
        color: #0EB1A4; }

.map-full-section #menu-social-media {
  justify-content: flex-start; }
  .map-full-section #menu-social-media li a:before {
    filter: invert(1); }

.contact-mapa {
  margin-bottom: -8px; }

.contact-wrapper.gray-bg {
  margin: 0; }

.col-lg-4.offset-lg-2.col-xxl-4.offset-xxl-2 {
  padding: 0; }
  @media (max-width: 769px) {
    .col-lg-4.offset-lg-2.col-xxl-4.offset-xxl-2 {
      padding: 15px; } }

.wp-block-columns.wp-container-4 {
  justify-content: center; }

.text-and-image {
  margin-bottom: 100px; }
  @media (max-width: 769px) {
    .text-and-image {
      margin-bottom: 30px;
      padding: 0; } }
  .text-and-image .row {
    justify-content: space-between;
    align-items: center; }
    .text-and-image .row.left {
      flex-direction: row-reverse; }
      .text-and-image .row.left:first-child {
        align-items: flex-start; }
        @media (max-width: 1250px) and (min-width: 769px) {
          .text-and-image .row.left:first-child {
            justify-content: center; } }
        @media (max-width: 769px) {
          .text-and-image .row.left:first-child {
            justify-content: flex-end; } }
      .text-and-image .row.left .text {
        display: flex;
        flex-direction: column; }
      .text-and-image .row.left .img {
        align-items: flex-start; }
    .text-and-image .row.right {
      flex-direction: row; }
      .text-and-image .row.right .img {
        align-items: flex-end; }
      .text-and-image .row.right .text {
        align-items: flex-start; }
    .text-and-image .row .h3,
    .text-and-image .row h3 {
      max-width: 525px;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      color: #242934;
      font-family: "Encode Sans", sans-serif;
      font-weight: bold;
      margin-bottom: 30px; }
      @media (max-width: 1250px) and (min-width: 769px) {
        .text-and-image .row .h3,
        .text-and-image .row h3 {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0.8px; } }
    .text-and-image .row .text {
      max-width: 525px; }
      .text-and-image .row .text p {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.8px;
        color: #242934;
        margin-bottom: 30px; }
        @media (max-width: 769px) {
          .text-and-image .row .text p {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.6px; } }
    .text-and-image .row img {
      max-width: 528px;
      height: auto;
      width: 100%; }
      @media (max-width: 769px) {
        .text-and-image .row img {
          width: 100%; } }

.our-team-section .h2 {
  text-align: center;
  font-weight: bold;
  margin: 0 auto 80px auto; }
  @media (max-width: 769px) {
    .our-team-section .h2 {
      margin: 0 auto 30px auto; } }

@media (max-width: 1250px) and (min-width: 769px) {
  .our-team-section .col-md-3 {
    flex: 0 50%;
    max-width: 50%; } }

@media (max-width: 769px) {
  .our-team-section .col-md-3 {
    flex: 100%;
    max-width: 500%; } }

.our-team-section .col-md-3 img {
  margin-bottom: 50px !important; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .our-team-section .col-md-3 img {
      margin-bottom: 20px;
      width: 100%;
      height: auto; } }

.our-team-section .col-md-3 .h3 {
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  color: #242934;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 21px; }
  @media (max-width: 769px) {
    .our-team-section .col-md-3 .h3 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px; } }

.our-team-section .col-md-3 .text p {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.7px;
  color: #242934; }

.wrapper-wspolpraca-kontakt .section-wrapper {
  padding: 46px 86px;
  position: relative; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .wrapper-wspolpraca-kontakt .section-wrapper {
      padding: 25px 35px;
      position: relative;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover; } }
  @media (max-width: 769px) {
    .wrapper-wspolpraca-kontakt .section-wrapper {
      padding: 25px; } }
  .wrapper-wspolpraca-kontakt .section-wrapper h3 {
    max-width: 515px;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    color: #F4F4F7;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 22px; }
    @media (max-width: 769px) {
      .wrapper-wspolpraca-kontakt .section-wrapper h3 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px; } }
  .wrapper-wspolpraca-kontakt .section-wrapper .opis p {
    max-width: 660px;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.8px;
    color: #F4F4F7;
    font-family: "Encode Sans", sans-serif;
    margin-bottom: 0; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .wrapper-wspolpraca-kontakt .section-wrapper .opis p {
        max-width: 50%; } }
    @media (max-width: 769px) {
      .wrapper-wspolpraca-kontakt .section-wrapper .opis p {
        max-width: 100%;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.6px; } }
  .wrapper-wspolpraca-kontakt .section-wrapper .btn, .wrapper-wspolpraca-kontakt .section-wrapper .wp-block-button .wp-block-button__link, .wp-block-button .wrapper-wspolpraca-kontakt .section-wrapper .wp-block-button__link {
    position: absolute;
    bottom: 48px;
    right: 65px; }
    @media (max-width: 769px) {
      .wrapper-wspolpraca-kontakt .section-wrapper .btn, .wrapper-wspolpraca-kontakt .section-wrapper .wp-block-button .wp-block-button__link, .wp-block-button .wrapper-wspolpraca-kontakt .section-wrapper .wp-block-button__link {
        position: relative;
        bottom: unset;
        right: unset;
        margin-top: 15px;
        padding: 15px 45px 15px 25px; } }
    @media (max-width: 769px) {
      .wrapper-wspolpraca-kontakt .section-wrapper .btn:after, .wrapper-wspolpraca-kontakt .section-wrapper .wp-block-button .wp-block-button__link:after, .wp-block-button .wrapper-wspolpraca-kontakt .section-wrapper .wp-block-button__link:after {
        top: 22px;
        right: 10px; } }

.woocommerce-account .woocommerce {
  max-width: 1290px;
  margin: 0 auto;
  padding-top: 80px; }

@media (max-width: 1024px) and (min-width: 320px) {
  .woocommerce-account .woocommerce {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px; } }

.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
  min-height: 360px;
  margin-bottom: 100px !important;
  padding: 30px !important;
  background: #F4F4F7;
  border: 0 !important;
  border-radius: 0 !important; }
  @media (max-width: 769px) {
    .woocommerce form.checkout_coupon,
    .woocommerce form.login,
    .woocommerce form.register {
      margin-bottom: 30px !important; } }

.woocommerce-account .woocommerce .col2-set .col-1,
.woocommerce-account .woocommerce-page .col2-set .col-1,
.woocommerce-account .woocommerce .col2-set .col-2,
.woocommerce-account .woocommerce-page .col2-set .col-2 {
  flex: 50% !important;
  max-width: 50% !important; }

@media (max-width: 1024px) and (min-width: 320px) {
  .woocommerce-account .woocommerce .col2-set .col-1,
  .woocommerce-account .woocommerce-page .col2-set .col-1,
  .woocommerce-account .woocommerce .col2-set .col-2,
  .woocommerce-account .woocommerce-page .col2-set .col-2 {
    flex: 100% !important;
    max-width: 100% !important; } }

.woocommerce-page.woocommerce-account .col2-set {
  width: 100% !important; }

.woocommerce-MyAccount-navigation ul {
  padding: 0; }

.woocommerce-MyAccount-navigation ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 35px;
  list-style: none; }

.woocommerce-MyAccount-navigation ul li a {
  color: #242934; }

.woocommerce-MyAccount-navigation ul li a:hover {
  color: #0EB1A4;
  text-decoration: none; }

.woocommerce-MyAccount-navigation ul li:before {
  position: absolute;
  top: 3px;
  left: 0;
  display: block; }

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  background-color: #F4F4F7;
  border-top: 0px !important;
  border-radius: 4px; }
  @media (max-width: 769px) {
    .woocommerce-error,
    .woocommerce-info,
    .woocommerce-message {
      padding: 20px !important; } }

.woocommerce-info::before {
  color: #0EB1A4; }

.flexible-shipping-notice-container span {
  font-weight: bold; }

.flexible-shipping-notice-container .woocommerce-info::before {
  content: none; }

.flexible-shipping-notice-container .flexible-shipping-free-shipping-button {
  background-color: transparent !important;
  color: #0EB1A4 !important;
  border: 2px solid #0EB1A4 !important; }

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  padding: 15px 30px !important;
  color: #ffffff !important;
  background-color: #0EB1A4 !important;
  border-radius: 0 !important; }

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover {
  background-color: #00847B !important; }

form.woocommerce-EditAccountForm.edit-account,
.woocommerce-address-fields {
  margin-bottom: 100px;
  padding: 30px;
  background: #F4F4F7;
  border-radius: 0; }

.woocommerce a {
  color: #0EB1A4; }

.woocommerce form .form-row {
  display: block; }

.woocommerce .form-row input {
  border: 0;
  border-radius: 0;
  padding: 10px; }

.woocommerce .form-row label {
  font-size: 14px;
  font-weight: bold;
  font-family: "Encode Sans", sans-serif;
  color: #242934; }

.woocommerce .lost_reset_password {
  padding: 30px;
  background: #F4F4F7;
  border: 0;
  border-radius: 0;
  margin-bottom: 50px; }

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 1px solid #27262c;
  margin-right: 10px;
  position: relative;
  margin-top: 0px; }
  input[type="checkbox"]:before {
    content: "";
    background: #fff;
    width: 22px;
    height: 22px;
    display: block;
    border: 1px solid #242934;
    top: -1px;
    left: -1px;
    position: absolute; }
  input[type="checkbox"]:checked:after {
    content: "";
    background: transparent;
    position: absolute;
    display: inline-block;
    vertical-align: text-top;
    width: 5px;
    height: 10px;
    border-radius: 0;
    top: 4px;
    left: 7px;
    border: 2px solid #242934;
    border-top: none;
    border-left: none;
    transform: rotate(40deg); }
  input[type="checkbox"]:focus {
    outline: none;
    outline-offset: 0; }

.woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme {
  display: flex;
  width: 100%;
  align-items: center; }

.woocommerce-order-received table.woocommerce-table.woocommerce-table--order-details.shop_table.order_details {
  border: 0px;
  border-radius: 0; }

.woocommerce-order-received .order_details {
  padding: 0; }

.woocommerce-order-received ul.order_details li {
  border-right: 1px solid; }
  @media (max-width: 769px) {
    .woocommerce-order-received ul.order_details li {
      margin-bottom: 20px;
      padding-right: 10px;
      margin-right: 10px; } }

.woocommerce-order-received .woocommerce-order-details .wc-item-meta {
  padding-left: 0; }

.woocommerce-order-received .woocommerce-order p {
  display: none; }

.woocommerce-order-received .woocommerce-order .woocommerce-order-details p {
  display: block; }

.woocommerce-cart .woocommerce {
  max-width: 1290px;
  margin: 0 auto; }
  .woocommerce-cart .woocommerce table.shop_table {
    border: 0;
    border-radius: 0; }
    .woocommerce-cart .woocommerce table.shop_table thead {
      font-size: 18px; }
      @media (max-width: 769px) {
        .woocommerce-cart .woocommerce table.shop_table thead {
          display: none; } }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table tbody {
        border-top: 1px solid #e8e8e8; } }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table tbody .cart_item {
        border-bottom: 1px solid #dede;
        display: flex;
        flex-wrap: wrap;
        padding-left: 120px;
        position: relative;
        align-items: flex-start; } }
    .woocommerce-cart .woocommerce table.shop_table td {
      border-top: 1.5px solid #757882;
      border-bottom: 0;
      background-color: transparent !important; }
      @media (max-width: 769px) {
        .woocommerce-cart .woocommerce table.shop_table td {
          border-top: 0; } }
      @media (max-width: 769px) {
        .woocommerce-cart .woocommerce table.shop_table td::before {
          content: none; } }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table .product-quantity {
        flex: 0 50%; } }
    .woocommerce-cart .woocommerce table.shop_table .product-quantity .qib-button-wrapper div.quantity.wqpmb_quantity {
      height: 50px;
      min-width: auto; }
    .woocommerce-cart .woocommerce table.shop_table .product-quantity .qib-button {
      margin: 0; }
      .woocommerce-cart .woocommerce table.shop_table .product-quantity .qib-button .qib-button {
        width: 30px;
        height: 50px;
        background-color: #F2F3F7;
        border: 0;
        color: #242934;
        font-weight: bold;
        font-family: "Encode Sans", sans-serif;
        font-size: 15px;
        line-height: 24px; }
      .woocommerce-cart .woocommerce table.shop_table .product-quantity .qib-button .wqpmb_input_text {
        height: 50px !important;
        background-color: #F2F3F7 !important;
        border: 0 !important;
        margin: 0px 5px !important;
        color: #242934;
        font-weight: bold;
        font-family: "Encode Sans", sans-serif;
        font-size: 15px !important;
        line-height: 24px !important; }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table .product-thumbnail {
        border-top: 0;
        left: 0;
        float: left;
        position: absolute;
        display: block;
        display: inline-block;
        text-align: left !important;
        max-width: 130px;
        background: #fff; } }
    .woocommerce-cart .woocommerce table.shop_table .product-thumbnail img {
      border: 2px solid #F2F3F7;
      width: 100px;
      height: 100px;
      object-fit: contain;
      padding: 10px; }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table .product-name {
        text-align: left !important;
        border: 0;
        background-color: transparent;
        width: 96%; } }
    .woocommerce-cart .woocommerce table.shop_table .product-name a {
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-decoration: none; }
      @media (max-width: 769px) {
        .woocommerce-cart .woocommerce table.shop_table .product-name a {
          font-size: 14px;
          line-height: 20px; } }
    .woocommerce-cart .woocommerce table.shop_table .product-name .product-category {
      display: block;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px; }
      @media (max-width: 769px) {
        .woocommerce-cart .woocommerce table.shop_table .product-name .product-category {
          font-size: 11px;
          line-height: 16px; } }
    .woocommerce-cart .woocommerce table.shop_table .product-price span,
    .woocommerce-cart .woocommerce table.shop_table .product-subtotal span {
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.6px; }
    .woocommerce-cart .woocommerce table.shop_table .product-price .text,
    .woocommerce-cart .woocommerce table.shop_table .product-subtotal .text {
      display: block;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-weight: normal;
      text-align: left; }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table .product-price,
      .woocommerce-cart .woocommerce table.shop_table .product-subtotal {
        background-color: transparent; } }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table .product-price {
        padding-right: 30px; } }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table .product-subtotal {
        display: none; } }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce table.shop_table .product-remove {
        border: 0 !important;
        width: 90%; } }
    .woocommerce-cart .woocommerce table.shop_table .remove {
      font-size: 0;
      width: 34px;
      height: 34px;
      margin: 0;
      position: relative; }
      @media (max-width: 769px) {
        .woocommerce-cart .woocommerce table.shop_table .remove {
          float: right;
          position: absolute;
          right: 30px;
          top: 30px; } }
      .woocommerce-cart .woocommerce table.shop_table .remove:after {
        content: '';
        width: 34px;
        height: 34px;
        display: block;
        font-weight: normal;
        font-family: "Encode Sans", sans-serif;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../../assets/images/remove.svg); }
      .woocommerce-cart .woocommerce table.shop_table .remove:hover {
        background-color: #F4F4F7; }
  .woocommerce-cart .woocommerce .actions {
    border-top: 0;
    margin-top: 20px; }
    .woocommerce-cart .woocommerce .actions label {
      display: none; }
    .woocommerce-cart .woocommerce .actions .coupon {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      @media (max-width: 769px) {
        .woocommerce-cart .woocommerce .actions .coupon {
          width: 100%;
          flex-direction: column; } }
      .woocommerce-cart .woocommerce .actions .coupon .input-text {
        width: 55%; }
        @media (max-width: 769px) {
          .woocommerce-cart .woocommerce .actions .coupon .input-text {
            width: 100% !important;
            margin-bottom: 10px !important; } }
      .woocommerce-cart .woocommerce .actions .coupon .btn, .woocommerce-cart .woocommerce .actions .coupon .wp-block-button .wp-block-button__link, .wp-block-button .woocommerce-cart .woocommerce .actions .coupon .wp-block-button__link {
        border-radius: 0;
        font-family: "Encode Sans", sans-serif;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.6px; }
        @media (max-width: 769px) {
          .woocommerce-cart .woocommerce .actions .coupon .btn, .woocommerce-cart .woocommerce .actions .coupon .wp-block-button .wp-block-button__link, .wp-block-button .woocommerce-cart .woocommerce .actions .coupon .wp-block-button__link {
            width: 100%; } }
    .woocommerce-cart .woocommerce .actions .btn, .woocommerce-cart .woocommerce .actions .wp-block-button .wp-block-button__link, .wp-block-button .woocommerce-cart .woocommerce .actions .wp-block-button__link {
      border-radius: 0;
      font-family: "Encode Sans", sans-serif;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.6px; }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce .actions .button {
        font-size: 16px;
        line-height: 20px;
        color: #777;
        border-color: #777;
        font-weight: normal;
        background-color: transparent;
        padding: 0;
        width: 100% !important; } }
    .woocommerce-cart .woocommerce .actions .button[name=update_cart] {
      display: none; }
  .woocommerce-cart .woocommerce .button:disabled[disabled] {
    background: transparent;
    font-weight: normal; }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce .button:disabled[disabled] {
        text-align: left;
        display: none !important; } }
  .woocommerce-cart .woocommerce .cart-collaterals {
    display: flex;
    flex-direction: column-reverse; }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce .cart-collaterals {
        width: 90%;
        margin: 0 auto; } }
    .woocommerce-cart .woocommerce .cart-collaterals .cart_totals {
      align-self: flex-end; }
      .woocommerce-cart .woocommerce .cart-collaterals .cart_totals h2 {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.7px;
        text-transform: uppercase; }
      .woocommerce-cart .woocommerce .cart-collaterals .cart_totals th,
      .woocommerce-cart .woocommerce .cart-collaterals .cart_totals td {
        border: 0;
        background: transparent; }
      .woocommerce-cart .woocommerce .cart-collaterals .cart_totals tbody {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%; }
        @media (max-width: 769px) {
          .woocommerce-cart .woocommerce .cart-collaterals .cart_totals tbody {
            max-width: 100%; } }
        .woocommerce-cart .woocommerce .cart-collaterals .cart_totals tbody tr {
          display: flex;
          justify-content: space-between;
          width: 100%; }
          @media (max-width: 769px) {
            .woocommerce-cart .woocommerce .cart-collaterals .cart_totals tbody tr th {
              display: block; } }
    .woocommerce-cart .woocommerce .cart-collaterals .cross-sells {
      width: 100%;
      margin-bottom: 80px; }
      .woocommerce-cart .woocommerce .cart-collaterals .cross-sells:before {
        width: 100%;
        left: 0;
        right: 0;
        content: '';
        background: #F4F4F7;
        display: block;
        position: absolute;
        height: 170%; }
      .woocommerce-cart .woocommerce .cart-collaterals .cross-sells h2 {
        position: relative;
        margin-top: 80px;
        margin-bottom: 40px; }
      .woocommerce-cart .woocommerce .cart-collaterals .cross-sells .products {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        @media (max-width: 769px) {
          .woocommerce-cart .woocommerce .cart-collaterals .cross-sells .products {
            flex-direction: column; } }
        .woocommerce-cart .woocommerce .cart-collaterals .cross-sells .products .product {
          width: 100%;
          float: left;
          margin: 0;
          max-width: 23%; }
          @media (max-width: 769px) {
            .woocommerce-cart .woocommerce .cart-collaterals .cross-sells .products .product {
              max-width: 100% !important;
              margin-bottom: 20px !important; } }
          .woocommerce-cart .woocommerce .cart-collaterals .cross-sells .products .product .add_to_cart_button {
            background-color: #0EB1A4;
            font-size: 15px;
            position: relative;
            top: auto;
            left: 0;
            border-radius: 0;
            width: 100%;
            text-align: center;
            color: #fff; }
            .woocommerce-cart .woocommerce .cart-collaterals .cross-sells .products .product .add_to_cart_button:after {
              content: none; }
          .woocommerce-cart .woocommerce .cart-collaterals .cross-sells .products .product .wp-element-button.product_type_variable.add_to_cart_button {
            display: none !important; }
  .woocommerce-cart .woocommerce .wc-proceed-to-checkout a.checkout-button {
    width: 70%;
    background-color: #FFB600 !important;
    border-radius: 0;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.6px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold;
    float: right; }
    .woocommerce-cart .woocommerce .wc-proceed-to-checkout a.checkout-button:hover, .woocommerce-cart .woocommerce .wc-proceed-to-checkout a.checkout-button:active, .woocommerce-cart .woocommerce .wc-proceed-to-checkout a.checkout-button:focus {
      background-color: #FFCD51; }
    @media (max-width: 769px) {
      .woocommerce-cart .woocommerce .wc-proceed-to-checkout a.checkout-button {
        width: 100%; } }

@media (max-width: 769px) {
  .woocommerce-cart .woocommerce-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px !important; } }

@media (max-width: 769px) {
  .woocommerce-cart .woocommerce-info .button {
    flex: 100%;
    width: 100%;
    text-align: center; } }

.woofc-area.woofc-style-02 .woofc-area-bot .woofc-continue span {
  text-decoration: none;
  background: #0EB1A4;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  font-weight: bold;
  text-transform: none;
  font-family: "Encode Sans", sans-serif;
  font-size: 16px;
  line-height: 24px; }

body .qib-button.qib-button-wrapper {
  margin: 0px 5px; }
  @media (max-width: 769px) {
    body .qib-button.qib-button-wrapper {
      float: left !important; } }

span.xoo-cp-icon-check.xoo-cp-added {
  display: none !important; }

a.added_to_cart.wc-forward {
  display: none; }

.woocommerce-checkout .wp-block-group__inner-container h2 {
  padding-bottom: 20px;
  border-bottom: 1px solid;
  margin-bottom: 50px; }
  @media (max-width: 769px) {
    .woocommerce-checkout .wp-block-group__inner-container h2 {
      margin-bottom: 20px; } }

.woocommerce-checkout .checkout-top .woocommerce-info {
  background: transparent;
  padding: 0; }
  .woocommerce-checkout .checkout-top .woocommerce-info::before {
    display: none; }

@media (max-width: 769px) {
  .woocommerce-checkout .checkout-top .row {
    padding: 0; } }

.woocommerce-checkout h3 {
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 30px; }
  @media (max-width: 769px) {
    .woocommerce-checkout h3 {
      margin-bottom: 15px; } }

.woocommerce-checkout .woocommerce-billing-fields label,
.woocommerce-checkout .shipping_address label {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  font-weight: normal;
  color: #242934; }

.woocommerce-checkout .woocommerce-billing-fields input,
.woocommerce-checkout .woocommerce-billing-fields textarea,
.woocommerce-checkout .shipping_address input,
.woocommerce-checkout .shipping_address textarea {
  border: 1px solid #CCCDD1;
  border-radius: 0; }

.woocommerce-checkout h3#ship-to-different-address {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
  font-weight: normal;
  margin-left: 4px; }
  .woocommerce-checkout h3#ship-to-different-address label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0px; }

@media (max-width: 769px) {
  .woocommerce-checkout .row {
    padding: 0; } }

.woocommerce form .form-row textarea {
  border-radius: 0;
  border: 1px solid #CCCDD1;
  padding: 10px; }

.woocommerce .payment_methods {
  background-color: #fff; }

.woocommerce #place_order {
  background-color: #FFB600 !important;
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.6px; }

.woocommerce .checkout-review-order-table-wrapper .order-total {
  justify-content: space-between;
  display: flex;
  border-top: 1px solid;
  padding-top: 30px;
  width: 100%; }
  .woocommerce .checkout-review-order-table-wrapper .order-total .total-price {
    font-size: 30px; }
  .woocommerce .checkout-review-order-table-wrapper .order-total p {
    font-weight: bold; }

.form-row.place-order {
  background: #fff; }

.checkout-review-order-table-wrapper .cart_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  @media (max-width: 769px) {
    .checkout-review-order-table-wrapper .cart_item {
      display: table-row; } }
  .checkout-review-order-table-wrapper .cart_item .product-name {
    display: flex;
    max-width: 85%;
    justify-content: space-between; }
    @media (max-width: 769px) {
      .checkout-review-order-table-wrapper .cart_item .product-name {
        max-width: 100%;
        border: 0; } }
  @media (max-width: 769px) {
    .checkout-review-order-table-wrapper .cart_item .product-total {
      border: 0; } }
  .checkout-review-order-table-wrapper .cart_item .product-total span {
    font-weight: bold; }
  .checkout-review-order-table-wrapper .cart_item .product-quantity {
    font-weight: normal;
    margin-left: 0;
    min-width: 50px; }

.checkout .woocommerce-shipping-methods label {
  margin: 0;
  margin-left: 5px;
  font-weight: 400;
  margin-bottom: 0;
  position: relative;
  padding-left: 18px;
  cursor: pointer; }
  .checkout .woocommerce-shipping-methods label:before {
    position: absolute;
    left: 0;
    top: 6px;
    width: 12px;
    height: 12px;
    border: 1px solid #868686;
    content: "";
    border-radius: 50%; }

.checkout .woocommerce-shipping-methods .shipping_method {
  display: none; }

#shipping_method li {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: #fff; }

.checkout .woocommerce-shipping-methods .shipping_method:checked + label:before {
  background: #0EB1A4;
  border-color: #000; }

.validate-required input[type="checkbox"]:before {
  content: "";
  border: 0;
  background: #fff;
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #ccc; }

.validate-required input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 1px solid #27262c;
  margin-right: 10px;
  position: relative;
  margin-top: 5px; }

.validate-required.woocommerce-validated input[type="checkbox"]:after {
  content: "";
  background: transparent;
  position: absolute;
  display: inline-block;
  vertical-align: text-top;
  width: 5px;
  height: 10px;
  border-radius: 0;
  top: 3px;
  left: 7px;
  border: 2px solid #0EB1A4;
  border-top: none;
  border-left: none;
  transform: rotate(40deg); }

.woocommerce-checkout #payment div.payment_box::before {
  content: none; }

.checkout ul.payment_methods li {
  width: 100%;
  clear: both; }

.checkout .woocommerce-checkout-payment .payment_methods li {
  padding: 17px 0 0;
  margin-bottom: 0; }

.checkout .woocommerce-checkout-payment .payment_methods input.input-radio {
  display: none; }

.checkout .woocommerce-checkout-payment .payment_methods li .input-radio:checked + label {
  color: #000; }

.checkout .woocommerce-checkout-payment .payment_methods li > label {
  float: unset;
  margin: 0;
  padding-left: 18px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  color: #000; }

.checkout .woocommerce-checkout-payment .payment_methods li > label:before {
  position: absolute;
  left: 0;
  top: calc(50% - 6px);
  width: 12px;
  height: 12px;
  border: 1px solid #868686;
  content: "";
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%; }

.checkout .woocommerce-checkout-payment .payment_methods li .input-radio:checked + label:before {
  background: #0EB1A4;
  border-color: #000; }
  .checkout .woocommerce-checkout-payment .payment_methods li .input-radio:checked + label:before:before {
    position: absolute;
    left: 0;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border: 1px solid #868686;
    content: "";
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; }

#payment ul.wc_payment_methods li.wc_payment_method .tpay-amPmCheckbox input[type="checkbox"] + label {
  background: none !important;
  position: relative; }
  #payment ul.wc_payment_methods li.wc_payment_method .tpay-amPmCheckbox input[type="checkbox"] + label:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    left: 0;
    top: 5px; }

#payment ul.wc_payment_methods li.wc_payment_method .tpay-amPmCheckbox input[type="checkbox"]:checked + label:before {
  content: "";
  background: transparent;
  position: absolute;
  display: inline-block;
  vertical-align: text-top;
  width: 6px;
  height: 12px;
  border-radius: 0;
  top: 7px;
  left: 7px;
  border: 2px solid #242934;
  border-top: none;
  border-left: none;
  transform: rotate(40deg); }

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
  border-color: #0EB1A4; }

.woocommerce-checkout #payment ul.payment_methods {
  padding: 0; }

.thank-you {
  text-align: center; }
  .thank-you h3 {
    color: #242934;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold;
    text-transform: none;
    margin: 0; }
    @media (max-width: 769px) {
      .thank-you h3 {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.8px; } }
    .thank-you h3.blue {
      color: #0EB1A4; }

@media (max-width: 769px) {
  tr.woocommerce-shipping-totals.shipping {
    display: flex;
    flex-direction: column; } }

tr.woocommerce-shipping-totals.shipping th {
  border: 0; }

.woocommerce table.shop_table {
  border: 0 !important; }
  .woocommerce table.shop_table .cart_item .product-name {
    display: flex;
    flex-direction: column; }
    .woocommerce table.shop_table .cart_item .product-name .ywp-product-image {
      min-width: 120px;
      width: 120px !important; }
    .woocommerce table.shop_table .cart_item .product-name img {
      height: 100%;
      width: auto; }
  .woocommerce table.shop_table .cart_item .product-quantity {
    padding-left: 10px; }

@media (max-width: 769px) {
  .cart-subtotal td {
    min-width: 100px; } }

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th {
  border-top: 0 !important; }

.woocommerce-page {
  display: flex;
  flex-direction: column; }
  .woocommerce-page .col2-set {
    width: 60% !important;
    float: left; }
    @media (max-width: 769px) {
      .woocommerce-page .col2-set {
        width: 100% !important; } }
    .woocommerce-page .col2-set .col-1,
    .woocommerce-page .col2-set .col-2 {
      width: 100% !important;
      max-width: 100%; }
  .woocommerce-page .woocommerce-shipping-totals {
    display: flex;
    flex-direction: column;
    margin-top: 40px; }
  .woocommerce-page .tpay-insidebg {
    margin: 0; }
  .woocommerce-page .woocommerce-checkout-review-order-table {
    width: 100%; }
    .woocommerce-page .woocommerce-checkout-review-order-table tr.cart-subtotal {
      background: #f1f1f1; }
    .woocommerce-page .woocommerce-checkout-review-order-table #order_review_heading {
      margin-left: 20px;
      margin-top: 40px; }
  .woocommerce-page .woocommerce-checkout-payment {
    width: 100%; }
  .woocommerce-page .form-row.place-order {
    margin: 0; }
  .woocommerce-page.woocommerce-checkout .woocommerce {
    max-width: 100%; }
  .woocommerce-page.woocommerce-checkout .wp-block-group__inner-container {
    position: relative; }
  .woocommerce-page.woocommerce-checkout .order-details-uniszki {
    position: absolute;
    right: 0;
    top: 100px;
    width: 400px; }
  .woocommerce-page.woocommerce-checkout .woocommerce-form-coupon-toggle {
    display: none; }
  .woocommerce-page.woocommerce-checkout #order_review {
    width: 35%;
    float: left;
    margin-left: 5%; }
    @media (max-width: 769px) {
      .woocommerce-page.woocommerce-checkout #order_review {
        width: 100%;
        margin-left: 0; } }
    .woocommerce-page.woocommerce-checkout #order_review .order-total {
      background: #f1f1f1; }

.product-template-default .woocommerce-notices-wrapper {
  margin-top: 15px; }

.product-template-default .woocommerce-notices-wrapper .woocommerce-message {
  padding: 30px; }

.product-template-default .woocommerce-notices-wrapper .woocommerce-message .button {
  background: #fff;
  color: #000;
  margin-top: -10px; }

.newsletter-wrapper .column {
  padding-right: 2%; }

.newsletter-wrapper:after {
  bottom: -7px;
  top: auto; }

@media (max-width: 769px) {
  .newsletter-wrapper .column {
    padding-right: 0; }
  .product-template-default .woocommerce-notices-wrapper .woocommerce-message {
    display: flex;
    flex-direction: column-reverse; }
  .product-template-default .woocommerce-notices-wrapper .woocommerce-message .button {
    margin-top: 10px; }
  .woocommerce-cart .woocommerce table.shop_table .product-name {
    width: 80%; }
  .woocommerce-cart .woocommerce table.shop_table .product-price {
    padding-right: 30px; }
  .woocommerce-cart .woocommerce table.shop_table .product-remove {
    border: 0 !important;
    width: 90%; }
  .woocommerce-cart .woocommerce table.shop_table .product-remove .remove {
    float: right; }
  .woocommerce-cart .woocommerce-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px !important; }
  .woocommerce-cart .woocommerce-info .button {
    flex: 100%;
    width: 100%;
    text-align: center; } }

#shipping_method li {
  flex-direction: column; }

#paczkomat_id_wrapper label {
  display: none !important; }

.select2-container--default .select2-selection--single {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 10px; }

span.select2.select2-container.select2-container--default {
  max-width: 80%; }

.woocommerce form .form-row .select2-container {
  width: 100% !important; }

.reviews-wrapper .comment-text .star-rating {
  width: 86px; }

.reviews-wrapper .comment-text .star-rating:before {
  font-size: 16px; }

.woocommerce .star-rating span:before {
  font-size: 16px;
  margin-left: 1px; }

.woocommerce-error {
  color: #fff !important;
  text-transform: none;
  font-weight: normal;
  margin: 0px 20px !important; }
  .woocommerce-error::before {
    content: none !important; }

input#shipping_method_0_local_pickup6:checked + label[for=shipping_method_0_local_pickup6]::after {
  display: block !important;
  content: "Poniedziałek- piątek godziny 7:00-15:00" !important;
  font-size: 14px;
  margin-top: 10px; }

section.related.products {
  overflow: hidden;
  width: 100%; }

.top-part {
  margin-top: 53px; }
  @media (max-width: 769px) {
    .top-part {
      margin-top: 0; } }
  @media (max-width: 769px) {
    .top-part .row {
      padding: 0; } }
  .top-part .product .onsale {
    background: #FF3C00;
    border-radius: 0; }
  @media (max-width: 769px) {
    .top-part .product .woocommerce-product-gallery {
      margin-bottom: 0; } }
  .top-part .product .woocommerce-product-gallery .woocommerce-product-gallery__trigger {
    border: 2px solid #F2F3F7;
    max-height: 480px !important; }
  .top-part .product .woocommerce-product-gallery .flex-active-slide {
    max-height: 480px !important;
    border: 2px solid #F2F3F7; }
  .top-part .product .summary .product_meta {
    margin-bottom: 32px; }
    @media (max-width: 769px) {
      .top-part .product .summary .product_meta {
        margin-bottom: 0; } }
    .top-part .product .summary .product_meta a {
      pointer-events: none;
      color: #757882;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      font-family: "Encode Sans", sans-serif;
      text-decoration: none !important; }
      @media (max-width: 769px) {
        .top-part .product .summary .product_meta a {
          font-size: 16px;
          line-height: 24px; } }
  .top-part .product .summary .product_title.entry-title {
    max-width: 550px;
    color: #242934;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    margin-bottom: 19px;
    font-weight: bold; }
    @media (max-width: 769px) {
      .top-part .product .summary .product_title.entry-title {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.6px; } }
  .top-part .product .summary .woocommerce-product-rating .star-rating span::before {
    color: #FFB600; }
  .top-part .product .summary .woocommerce-product-rating a.woocommerce-review-link {
    color: #757882;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px; }
  .top-part .product .summary .qib-button-wrapper div.quantity.wqpmb_quantity {
    height: 50px; }
  .top-part .product .summary .qib-button {
    margin: 0; }
    .top-part .product .summary .qib-button .qib-button {
      width: 30px;
      height: 50px;
      background-color: #F2F3F7;
      border: 0;
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      font-size: 15px;
      line-height: 24px; }
    .top-part .product .summary .qib-button .wqpmb_input_text {
      height: 50px !important;
      background-color: #F2F3F7 !important;
      border: 0 !important;
      margin: 0px 5px !important;
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      font-size: 15px !important;
      line-height: 24px !important; }
  .top-part .product .summary .single_add_to_cart_button {
    margin-left: 15px;
    width: 50%;
    background-color: #FFB600 !important;
    height: 50px;
    border-radius: 0;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.6px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold; }
    @media (max-width: 769px) {
      .top-part .product .summary .single_add_to_cart_button {
        padding: 15px 20px !important;
        font-size: 14px; } }
    .top-part .product .summary .single_add_to_cart_button:hover, .top-part .product .summary .single_add_to_cart_button:active, .top-part .product .summary .single_add_to_cart_button:focus {
      background-color: #FFCD51 !important; }
  .top-part .product .woocommerce-tabs {
    max-width: 45%; }
    @media (max-width: 769px) {
      .top-part .product .woocommerce-tabs {
        max-width: 100%; } }
    .top-part .product .woocommerce-tabs .tabs.wc-tabs {
      padding: 0; }
      .top-part .product .woocommerce-tabs .tabs.wc-tabs::before {
        border-bottom: 3px solid #707070;
        z-index: 3; }
      .top-part .product .woocommerce-tabs .tabs.wc-tabs li {
        border-radius: 0;
        border: 0;
        color: #242424;
        font-family: "Encode Sans", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
        background-color: transparent;
        padding: 5px 50px !important; }
        .top-part .product .woocommerce-tabs .tabs.wc-tabs li.active {
          background: #00847B;
          border-radius: 0;
          border: 0;
          color: #fff;
          text-decoration: underline; }
    .top-part .product .woocommerce-tabs .product-info-text ul {
      padding: 0;
      list-style: none; }
      .top-part .product .woocommerce-tabs .product-info-text ul li {
        position: relative;
        padding-left: 25px; }
        .top-part .product .woocommerce-tabs .product-info-text ul li:before {
          content: '';
          width: 6px;
          height: 6px;
          background: #00847b;
          display: block;
          position: absolute;
          left: 5px;
          top: 9px;
          border-radius: 50%; }
    .top-part .product .woocommerce-tabs .product-info-text table td {
      border: 1px solid #e4e4e4;
      padding: 10px 15px; }
  .top-part .woocommerce-variation-price {
    margin-bottom: 40px; }
    @media (max-width: 769px) {
      .top-part .woocommerce-variation-price {
        margin-bottom: 20px; } }
    .top-part .woocommerce-variation-price .price {
      display: flex;
      flex-direction: column;
      color: #242934; }
      .top-part .woocommerce-variation-price .price del {
        text-decoration: none;
        opacity: 1; }
        .top-part .woocommerce-variation-price .price del span {
          color: #242934;
          font-size: 16px;
          font-family: "Encode Sans", sans-serif;
          line-height: 24px;
          letter-spacing: 0.3px;
          position: relative; }
          .top-part .woocommerce-variation-price .price del span:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: #242934;
            top: 40%;
            position: absolute;
            left: 0; }
      .top-part .woocommerce-variation-price .price ins,
      .top-part .woocommerce-variation-price .price bdi {
        text-decoration: none;
        color: #FF3C00;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
        font-family: "Encode Sans", sans-serif;
        font-weight: bold; }
        .top-part .woocommerce-variation-price .price ins span,
        .top-part .woocommerce-variation-price .price bdi span {
          color: #FF3C00;
          font-size: 30px;
          line-height: 44px;
          letter-spacing: 1.2px;
          font-family: "Encode Sans", sans-serif;
          font-weight: bold; }
  .top-part .variations th.label {
    display: none !important; }
  .top-part .variations .variable-items-wrapper .variable-item {
    background-color: #f2f3f7 !important;
    border-radius: 0 !important;
    border: 0;
    outline: none !important;
    box-shadow: none !important;
    padding: 25px !important;
    height: 135px !important;
    width: 135px !important; }
    @media (max-width: 769px) {
      .top-part .variations .variable-items-wrapper .variable-item {
        height: 85px !important;
        width: 85px !important;
        padding: 10px !important; } }
    .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents {
      position: relative;
      justify-content: center;
      align-items: center; }
      .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents::before {
        content: '';
        width: 40px;
        height: 40px;
        background: url(../../assets/images/size-icon.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: -6px;
        background-position: center; }
        @media (max-width: 769px) {
          .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents::before {
            height: 25px; } }
    .top-part .variations .variable-items-wrapper .variable-item .variable-item-span {
      margin-top: 20px; }
      @media (max-width: 769px) {
        .top-part .variations .variable-items-wrapper .variable-item .variable-item-span {
          margin-top: 0; } }
      .top-part .variations .variable-items-wrapper .variable-item .variable-item-span::before {
        content: 'Opakowanie';
        position: absolute;
        bottom: 17px;
        text-align: left;
        left: -5px;
        right: 0; }
        @media (max-width: 769px) {
          .top-part .variations .variable-items-wrapper .variable-item .variable-item-span::before {
            display: none; } }
  .top-part .reset_variations {
    display: none !important; }
  .top-part .info-one {
    background: #ff3c00;
    text-align: center;
    padding: 20px;
    margin-bottom: 40px; }
    .top-part .info-one .product-dioz-info p {
      color: #fff;
      margin-bottom: 5px; }
    .top-part .info-one .product-dioz-info a {
      color: #ffc73b;
      text-decoration: none; }
      .top-part .info-one .product-dioz-info a:hover {
        color: #000; }

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .zoomImg {
  max-height: 480px !important;
  max-width: 620px !important; }

.woocommerce div.product div.images .flex-control-thumbs {
  margin-top: 24px; }

#product-comments {
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 50px; }
  #product-comments .slick-dots {
    display: none !important; }
  #product-comments .slick-arrow {
    top: 45% !important; }

.reviews-wrapper {
  overflow: hidden;
  width: 100%;
  display: block;
  margin-bottom: 40px;
  margin-top: 40px; }
  .reviews-wrapper h3 {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 30px; }
    @media (max-width: 769px) {
      .reviews-wrapper h3 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px;
        margin-bottom: 30px; } }
  .reviews-wrapper .centered-btn {
    text-align: center;
    margin-top: 0px; }
    @media (max-width: 769px) {
      .reviews-wrapper .centered-btn {
        margin-top: 0px; } }
  .reviews-wrapper .comment-text .star-rating {
    width: 100%;
    height: 30px;
    float: none;
    margin: 0 auto;
    text-align: center; }
    .reviews-wrapper .comment-text .star-rating::before {
      font-size: 23px;
      right: 0;
      margin: 0 auto; }
    .reviews-wrapper .comment-text .star-rating span {
      color: #FFB600;
      font-size: 23px; }
    .reviews-wrapper .comment-text .star-rating .rating::before {
      left: 0;
      right: 0; }
  .reviews-wrapper .review {
    margin-top: 50px !important;
    background-color: #f2f3f7;
    border: 2px solid #F5F5F5;
    padding: 35px;
    text-align: center;
    margin-top: 50px !important; }
    @media (max-width: 769px) {
      .reviews-wrapper .review {
        margin-top: 0px !important; } }
    .reviews-wrapper .review .h3 {
      color: #242934;
      margin-bottom: 27px;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      font-weight: bold; }
    .reviews-wrapper .review .text,
    .reviews-wrapper .review p {
      color: #242934;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.5px; }
    .reviews-wrapper .review p {
      margin-bottom: 27px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400; }
    .reviews-wrapper .review.slick-center {
      margin-top: 0 !important; }
  .reviews-wrapper .slick-slide {
    margin: 0 35px; }
    @media (max-width: 769px) {
      .reviews-wrapper .slick-slide {
        margin: 0; } }
  .reviews-wrapper .slick-list {
    margin: 0 -35px; }
    @media (max-width: 769px) {
      .reviews-wrapper .slick-list {
        margin: 0; } }
  .reviews-wrapper .slick-dots {
    bottom: -50px;
    left: 0;
    right: 0; }
    @media (max-width: 769px) {
      .reviews-wrapper .slick-dots {
        bottom: -35px; } }

.woocommerce .star-rating span::before {
  right: 0; }

.product-info-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 60px; }
  .product-info-icons .icon-info {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 135px;
    text-align: center;
    margin-right: 25px; }
    .product-info-icons .icon-info .title {
      color: #242934;
      font-size: 25px;
      font-weight: bold;
      line-height: 44px;
      letter-spacing: 0.5px;
      font-family: "Encode Sans", sans-serif;
      margin: 0; }
      @media (max-width: 769px) {
        .product-info-icons .icon-info .title {
          font-size: 22px;
          line-height: 30px; } }
    .product-info-icons .icon-info .subtitle {
      color: #242934;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: 0.7px;
      margin: 0;
      font-family: "Encode Sans", sans-serif; }
      @media (max-width: 769px) {
        .product-info-icons .icon-info .subtitle {
          font-size: 14px;
          line-height: 18px; } }

.delivery-info {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-top: 45px; }
  @media (max-width: 769px) {
    .delivery-info {
      padding-top: 15px; } }
  .delivery-info .texts {
    margin-left: 11px; }
    .delivery-info .texts .title {
      color: #242934;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0, 6px;
      margin-bottom: 0;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      margin-top: 5px; }
    .delivery-info .texts .text {
      color: #757882;
      font-size: 14px;
      line-height: 17px;
      font-family: "Encode Sans", sans-serif;
      margin: 0; }

.related.products h2 {
  text-align: center;
  margin-bottom: 45px; }

.related.products h3 {
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 80px;
  text-align: center; }
  @media (max-width: 769px) {
    .related.products h3 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin-bottom: 30px; } }

.product-blog h3 {
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 80px;
  text-align: center; }
  @media (max-width: 769px) {
    .product-blog h3 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin-bottom: 30px; } }

.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected {
  box-shadow: none !important;
  border: 2px solid #0EB1A4;
  color: #000;
  font-weight: bold;
  background-color: #fff !important; }

.comment-form-cookies-consent {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start; }
  .comment-form-cookies-consent input[type=checkbox] {
    width: auto;
    margin-top: 6px;
    margin-right: 20px; }
    .comment-form-cookies-consent input[type=checkbox]:focus {
      outline: none; }

.btn-price {
  display: none;
  color: #ff3c00;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: Encode Sans, sans-serif;
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: -20px; }
  .btn-price.show {
    display: block; }
  .btn-price ins {
    text-decoration: none; }
  .btn-price del {
    font-weight: normal;
    color: #333 !important; }

.price ins {
  text-decoration: none; }

.price del {
  font-weight: normal;
  color: #333 !important; }

.product-template-default .woocommerce-notices-wrapper {
  margin-top: 15px; }
  .product-template-default .woocommerce-notices-wrapper .woocommerce-message {
    padding: 30px; }
    @media (max-width: 769px) {
      .product-template-default .woocommerce-notices-wrapper .woocommerce-message {
        display: flex;
        flex-direction: column-reverse; } }
    .product-template-default .woocommerce-notices-wrapper .woocommerce-message .button {
      background: #fff;
      color: #000;
      margin-top: -10px; }
      @media (max-width: 769px) {
        .product-template-default .woocommerce-notices-wrapper .woocommerce-message .button {
          margin-top: 10px; } }

.single-product.legowiska .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
  width: 60px !important;
  height: 60px !important;
  background: url(../../assets/images/coach.png); }
  @media (max-width: 992px) {
    .single-product.legowiska .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
      width: 30px !important;
      height: 30px !important;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; } }

.single-product.legowiska .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span {
  margin-top: 0; }
  .single-product.legowiska .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span:before {
    content: "Rozmiar";
    position: inherit;
    text-align: center;
    margin-right: 7px; }

.single-product.karma-w-puszkach .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before, .single-product.karma-dla-dioz .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
  width: 60px !important;
  height: 60px !important;
  background: url(../../assets/images/puszka.png); }
  @media (max-width: 992px) {
    .single-product.karma-w-puszkach .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before, .single-product.karma-dla-dioz .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
      width: 30px !important;
      height: 30px !important;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; } }

.single-product.karma-w-puszkach .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span, .single-product.karma-dla-dioz .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span {
  margin-top: 0; }
  .single-product.karma-w-puszkach .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span:before, .single-product.karma-dla-dioz .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span:before {
    content: "Rozmiar";
    position: inherit;
    text-align: center;
    margin-right: 7px; }

.single-product.karma-w-puszkach .product-info-text ul li::before, .single-product.karma-dla-dioz .product-info-text ul li::before {
  background: url(../../assets/images/check.png) !important;
  width: 22px !important;
  height: 22px !important;
  background-size: contain !important;
  left: 0 !important;
  top: 4px !important;
  background-repeat: no-repeat; }

.single-product.karma-w-puszkach .product-info-text .first-icons ul li, .single-product.karma-dla-dioz .product-info-text .first-icons ul li {
  width: 50%;
  min-height: 55px;
  padding-left: 55px;
  float: left;
  font-weight: bold; }
  @media (max-width: 992px) {
    .single-product.karma-w-puszkach .product-info-text .first-icons ul li, .single-product.karma-dla-dioz .product-info-text .first-icons ul li {
      width: 100%; } }
  .single-product.karma-w-puszkach .product-info-text .first-icons ul li::before, .single-product.karma-dla-dioz .product-info-text .first-icons ul li::before {
    background: url(../../assets/images/check.png) !important;
    width: 35px !important;
    height: 35px !important;
    background-size: contain !important;
    left: 0 !important;
    top: 0px !important;
    background-repeat: no-repeat; }

.single-product.prezenty-dla-psiarzy-i-kociarzy .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
  content: none; }

.single-product.prezenty-dla-psiarzy-i-kociarzy .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span {
  margin-top: 0; }
  .single-product.prezenty-dla-psiarzy-i-kociarzy .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span:before {
    content: "Kolor";
    text-align: center;
    margin-right: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    position: inherit; }

.single-product.prezenty-dla-psiarzy-i-kociarzy .top-part ul li#tab-title-ingredients {
  display: none; }

.single-product.cegielki.dla-dioz .top-part .variations {
  display: none !important; }

.single-product.pokrywki-na-puszki .top-part .variations .variable-items-wrapper .variable-item {
  padding: 12px !important; }
  .single-product.pokrywki-na-puszki .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
    content: none; }
  .single-product.pokrywki-na-puszki .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span {
    margin-top: 0;
    line-height: normal;
    padding: 0; }
    .single-product.pokrywki-na-puszki .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span:before {
      content: none; }

.single-product.zestawy-puszek .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
  width: 60px !important;
  height: 60px !important;
  margin-bottom: 0;
  margin-top: -10px;
  background-position: top center;
  background: url(../../assets/images/puszka.png); }
  @media (max-width: 992px) {
    .single-product.zestawy-puszek .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents:before {
      width: 30px !important;
      height: 30px !important;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; } }

.single-product.zestawy-puszek .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span {
  margin-top: 5px;
  line-height: initial;
  font-size: 15px; }
  .single-product.zestawy-puszek .top-part .variations .variable-items-wrapper .variable-item .variable-item-contents .variable-item-span:before {
    content: "Zestaw";
    position: inherit;
    text-align: center;
    margin-right: 7px; }

@media (max-width: 769px) {
  .page-blog .posts {
    padding: 0; } }

.page-blog .posts .entry {
  width: 48%;
  min-height: 530px;
  margin: 0;
  float: left;
  margin-bottom: 42px;
  position: relative; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .page-blog .posts .entry {
      width: 100%; } }
  @media (max-width: 769px) {
    .page-blog .posts .entry {
      min-height: auto;
      width: 100%; } }
  .page-blog .posts .entry:nth-child(odd) {
    margin-left: 16px; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .page-blog .posts .entry:nth-child(odd) {
        margin-left: 0; } }
    @media (max-width: 769px) {
      .page-blog .posts .entry:nth-child(odd) {
        margin-left: 0; } }
  .page-blog .posts .entry:nth-child(even) {
    margin-right: 16px; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .page-blog .posts .entry:nth-child(even) {
        margin-right: 0; } }
    @media (max-width: 769px) {
      .page-blog .posts .entry:nth-child(even) {
        margin-right: 0; } }
  .page-blog .posts .entry .post-img {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    height: 290px; }
    .page-blog .posts .entry .post-img .tag-links {
      position: absolute;
      top: 6px;
      display: block;
      z-index: 2;
      font-size: 0; }
      .page-blog .posts .entry .post-img .tag-links .tag {
        background-color: #242934;
        color: #fff;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: "Encode Sans", sans-serif;
        padding: 10px;
        margin-right: 5px;
        text-decoration: none; }
    .page-blog .posts .entry .post-img img {
      width: 100%;
      object-fit: cover;
      height: 290px; }
  .page-blog .posts .entry h3 {
    font-size: 16px;
    line-height: 20px; }
    .page-blog .posts .entry h3 a {
      font-size: 16px;
      line-height: 20px;
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      text-decoration: none; }
      .page-blog .posts .entry h3 a:hover {
        text-decoration: none;
        color: #0EB1A4; }
      .page-blog .posts .entry h3 a:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; }
  .page-blog .posts .entry p {
    color: #242934;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: "Encode Sans", sans-serif; }
  .page-blog .posts .entry .readmore {
    display: inline-block;
    float: right;
    overflow: hidden;
    margin-top: 30px; }
    .page-blog .posts .entry .readmore:after {
      content: '' !important;
      background-image: url(../../assets/images/menu.svg);
      width: 25px;
      height: 25px;
      position: absolute;
      z-index: 9;
      top: 10px;
      margin-left: 10px;
      background-size: cover;
      transform: rotate(-90deg); }
  .page-blog .posts .entry:first-child {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 70px; }
    .page-blog .posts .entry:first-child .post-img {
      height: 480px;
      overflow: hidden; }
      @media (max-width: 769px) {
        .page-blog .posts .entry:first-child .post-img {
          height: 290px; } }
      .page-blog .posts .entry:first-child .post-img img {
        height: 480px; }
        @media (max-width: 769px) {
          .page-blog .posts .entry:first-child .post-img img {
            height: 290px; } }
    .page-blog .posts .entry:first-child h3 a {
      color: #242934;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      font-family: "Encode Sans", sans-serif;
      font-weight: bold; }
      @media (max-width: 769px) {
        .page-blog .posts .entry:first-child h3 a {
          font-size: 16px;
          line-height: 20px;
          color: #242934;
          font-weight: bold;
          font-family: "Encode Sans", sans-serif;
          text-decoration: none; } }
    .page-blog .posts .entry:first-child p {
      color: #757882;
      font-size: 15px;
      line-height: 34px;
      letter-spacing: 0.6px;
      font-family: "Encode Sans", sans-serif; }
      @media (max-width: 769px) {
        .page-blog .posts .entry:first-child p {
          color: #242934;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.5px; } }
  .page-blog .posts .entry:hover {
    box-shadow: none;
    cursor: pointer; }
    .page-blog .posts .entry:hover .post-img img {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out; }
    .page-blog .posts .entry:hover h3 a {
      color: #0EB1A4; }
    .page-blog .posts .entry:hover .readmore {
      background-color: #00847B; }

@media (max-width: 769px) {
  .blog_sidebar {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ccc; } }

.blog_sidebar .wp-block-latest-posts {
  flex-direction: column; }
  .blog_sidebar .wp-block-latest-posts li {
    width: 100%;
    margin-bottom: 34px; }
    .blog_sidebar .wp-block-latest-posts li .readmore {
      display: none; }
    .blog_sidebar .wp-block-latest-posts li .wp-block-latest-posts__post-excerpt {
      padding: 0;
      font-size: 14px;
      line-height: 20px; }
    .blog_sidebar .wp-block-latest-posts li .wp-block-latest-posts__post-title {
      min-height: auto;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.6px;
      color: #242934;
      font-weight: bold;
      margin-top: 10px; }
    .blog_sidebar .wp-block-latest-posts li:hover {
      box-shadow: none; }
      .blog_sidebar .wp-block-latest-posts li:hover .wp-block-latest-posts__post-title {
        color: #0EB1A4;
        text-decoration: none; }
    .blog_sidebar .wp-block-latest-posts li:nth-child(2) {
      width: 100%;
      margin: 34px 0; }

.blog_sidebar .widget_search {
  background-color: #242934;
  padding: 18px 22px;
  margin-bottom: 32px; }
  .blog_sidebar .widget_search .wp-block-search {
    width: 100%; }
    .blog_sidebar .widget_search .wp-block-search__input {
      border-radius: 0;
      height: 40px;
      border: 0;
      outline: none;
      padding: 5px 15px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.56px;
      text-transform: lowercase;
      color: #757882; }
    .blog_sidebar .widget_search .wp-block-search__button {
      height: 40px;
      width: 40px;
      font-size: 0;
      border: 0;
      background-color: #fff;
      margin: 0;
      position: relative; }
      .blog_sidebar .widget_search .wp-block-search__button::before {
        content: url(../../assets/images/search.svg);
        position: absolute;
        right: 6px;
        top: 5px; }

.blog_sidebar .widget_nav_menu {
  margin-bottom: 130px; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .blog_sidebar .widget_nav_menu {
      margin-bottom: 30px; } }
  @media (max-width: 769px) {
    .blog_sidebar .widget_nav_menu {
      margin-bottom: 30px; } }
  .blog_sidebar .widget_nav_menu h2 {
    margin-bottom: 30px; }
  .blog_sidebar .widget_nav_menu ul {
    padding: 0;
    list-style: none; }
    .blog_sidebar .widget_nav_menu ul li a {
      color: #242934;
      font-size: 15px;
      line-height: 24px;
      font-family: "Encode Sans", sans-serif;
      font-weight: bold; }
    .blog_sidebar .widget_nav_menu ul li.current-menu-item > a, .blog_sidebar .widget_nav_menu ul li.current-menu-parent > a {
      color: #0EB1A4; }
    .blog_sidebar .widget_nav_menu ul .sub-menu {
      list-style: none;
      padding-left: 10px;
      margin: 10px 0px; }
      .blog_sidebar .widget_nav_menu ul .sub-menu li a {
        color: #757882;
        font-weight: normal; }
      .blog_sidebar .widget_nav_menu ul .sub-menu li.current-menu-item > a {
        color: #0EB1A4; }

.blog_sidebar .blog-posts-sidebar h2 {
  margin-bottom: 20px; }

.blog_sidebar .blog-posts-sidebar h3 {
  color: #242934;
  font-size: 15px;
  line-height: 24px;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 34px;
  text-decoration: none; }

.result-post {
  width: 48%;
  min-height: 530px;
  margin: 0;
  float: left;
  margin-bottom: 42px; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .result-post {
      width: 100%; } }
  @media (max-width: 769px) {
    .result-post {
      min-height: auto;
      width: 100%; } }
  .result-post:nth-child(odd) {
    margin-left: 16px; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .result-post:nth-child(odd) {
        margin-left: 0; } }
    @media (max-width: 769px) {
      .result-post:nth-child(odd) {
        margin-left: 0; } }
  .result-post:nth-child(even) {
    margin-right: 16px; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .result-post:nth-child(even) {
        margin-right: 0; } }
    @media (max-width: 769px) {
      .result-post:nth-child(even) {
        margin-right: 0; } }
  .result-post .post-img {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    height: 290px; }
    .result-post .post-img .tag-links {
      position: absolute;
      top: 6px;
      display: block;
      z-index: 2;
      font-size: 0; }
      .result-post .post-img .tag-links .tag {
        background-color: #242934;
        color: #fff;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: "Encode Sans", sans-serif;
        padding: 10px;
        margin-right: 5px;
        text-decoration: none;
        pointer-events: none; }
    .result-post .post-img img {
      width: 100%;
      object-fit: cover;
      height: 290px; }
  .result-post h3 {
    font-size: 16px;
    line-height: 20px; }
    .result-post h3 a {
      font-size: 16px;
      line-height: 20px;
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      text-decoration: none; }
      .result-post h3 a:hover {
        text-decoration: none;
        color: #0EB1A4; }
  .result-post p {
    color: #242934;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: "Encode Sans", sans-serif; }
  .result-post .readmore {
    display: inline-block;
    float: right;
    overflow: hidden;
    margin-top: 30px; }
    .result-post .readmore:after {
      content: '' !important;
      background-image: url(../../assets/images/menu.svg);
      width: 25px;
      height: 25px;
      position: absolute;
      z-index: 9;
      top: 10px;
      margin-left: 10px;
      background-size: cover;
      transform: rotate(-90deg); }
  .result-post:first-child {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 70px; }
    .result-post:first-child .post-img {
      height: 480px;
      overflow: hidden; }
      @media (max-width: 769px) {
        .result-post:first-child .post-img {
          height: 290px; } }
      .result-post:first-child .post-img img {
        height: 480px; }
        @media (max-width: 769px) {
          .result-post:first-child .post-img img {
            height: 290px; } }
    .result-post:first-child h3 a {
      color: #242934;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      font-family: "Encode Sans", sans-serif;
      font-weight: bold; }
      @media (max-width: 769px) {
        .result-post:first-child h3 a {
          font-size: 16px;
          line-height: 20px;
          color: #242934;
          font-weight: bold;
          font-family: "Encode Sans", sans-serif;
          text-decoration: none; } }
    .result-post:first-child p {
      color: #757882;
      font-size: 15px;
      line-height: 34px;
      letter-spacing: 0.6px;
      font-family: "Encode Sans", sans-serif; }
      @media (max-width: 769px) {
        .result-post:first-child p {
          color: #242934;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.5px; } }
  .result-post:hover {
    box-shadow: none;
    cursor: pointer; }
    .result-post:hover .post-img img {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out; }
    .result-post:hover h3 a {
      color: #0EB1A4; }
    .result-post:hover .readmore {
      background-color: #00847B; }

.single-post .entry-header .entry-title {
  margin-bottom: 30px;
  color: #242934;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold; }
  @media (max-width: 769px) {
    .single-post .entry-header .entry-title {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.8px; } }
  .single-post .entry-header .entry-title a {
    color: #242934;
    text-decoration: none; }

.single-post .post-img {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  height: 490px; }
  @media (max-width: 769px) {
    .single-post .post-img {
      height: 290px; } }
  .single-post .post-img .tag-links {
    position: absolute;
    top: 6px;
    display: block;
    z-index: 2;
    font-size: 0; }
    .single-post .post-img .tag-links .tag {
      background-color: #242934;
      color: #fff;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.6px;
      font-family: "Encode Sans", sans-serif;
      padding: 10px;
      margin-right: 5px;
      text-decoration: none; }
  .single-post .post-img img {
    width: 100%;
    object-fit: cover;
    height: 100%; }

.single-post .entry-content a {
  color: #000; }

.single-post .entry-content p {
  color: #757882;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.6px;
  font-family: "Encode Sans", sans-serif; }
  .single-post .entry-content p a {
    color: #000; }

.single-post .wp-block-product-category .wc-block-grid__products {
  max-width: 1296px;
  margin: 0 auto 58px auto;
  display: block; }
  .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product {
    padding: 10px;
    background-color: #fff;
    margin: 0px 18px;
    border: 0;
    flex: 100%;
    max-width: 100%;
    display: block;
    float: left;
    flex: 1 0 22%;
    max-width: 22%; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product {
        margin: 0px 14px; } }
    @media (max-width: 992px) {
      .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product {
        flex: 100%;
        max-width: 100%; } }
    .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-image {
      margin: 0 0 13px 0;
      border: 2px solid #F4F4F7;
      height: 330px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }
    .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product a {
      text-decoration: none;
      color: #000; }
    .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-title {
      min-height: 48px;
      text-align: left;
      color: #242934;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.6px;
      margin-bottom: 4px;
      text-decoration: none !important;
      margin: 10px 0px; }
      @media (max-width: 1250px) and (min-width: 769px) {
        .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-title {
          font-size: 13px;
          line-height: 20px; } }
    .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-price {
      text-align: left;
      color: #242934;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 1.28px;
      font-weight: bold;
      margin: 10px 0px; }
      @media (max-width: 1250px) and (min-width: 769px) {
        .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product .wc-block-grid__product-price {
          font-size: 18px;
          line-height: 22px; } }
    .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product:hover .wc-block-grid__product-title {
      color: #0EB1A4; }
    .single-post .wp-block-product-category .wc-block-grid__products .wc-block-grid__product:hover .wc-block-grid__product-image img {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out; }

.category-posts {
  margin: 120px 0; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .category-posts {
      margin-top: 50px 0; } }
  @media (max-width: 769px) {
    .category-posts {
      margin: 30px 0; } }
  .category-posts .wp-block-latest-posts__list {
    max-width: 1290px;
    margin: 0 auto;
    display: flex; }
    .category-posts .wp-block-latest-posts__list li {
      width: 32% !important;
      margin: 0; }
      .category-posts .wp-block-latest-posts__list li:nth-child(2) {
        margin: 0 24px; }
        @media (max-width: 1250px) and (min-width: 769px) {
          .category-posts .wp-block-latest-posts__list li:nth-child(2) {
            margin: 0 18px; } }
      @media (max-width: 769px) {
        .category-posts .wp-block-latest-posts__list li {
          width: 100% !important;
          margin-bottom: 30px; } }
      .category-posts .wp-block-latest-posts__list li .wp-block-latest-posts__featured-image {
        margin-bottom: 20px;
        overflow: hidden; }
        .category-posts .wp-block-latest-posts__list li .wp-block-latest-posts__featured-image img {
          width: 100%;
          height: 280px;
          object-fit: cover; }
      .category-posts .wp-block-latest-posts__list li .wp-block-latest-posts__post-title {
        display: block;
        min-height: 46px;
        color: #242934;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: "Encode Sans", sans-serif;
        font-weight: bold;
        margin-bottom: 11px;
        padding: 0 20px; }
      .category-posts .wp-block-latest-posts__list li .wp-block-latest-posts__post-excerpt {
        color: #242934;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        padding: 0 20px; }
      .category-posts .wp-block-latest-posts__list li .btn, .category-posts .wp-block-latest-posts__list li .wp-block-button .wp-block-button__link, .wp-block-button .category-posts .wp-block-latest-posts__list li .wp-block-button__link {
        display: block;
        overflow: hidden;
        float: right;
        position: relative;
        margin-top: 15px;
        color: #fff !important; }
      .category-posts .wp-block-latest-posts__list li:hover {
        box-shadow: 0px 3px 10px #E1ECEC;
        cursor: pointer; }
        .category-posts .wp-block-latest-posts__list li:hover .wp-block-latest-posts__featured-image img {
          transform: scale(1.2);
          transition: all 0.3s ease-in-out; }

.woocommerce-page .products .product {
  padding: 10px;
  background-color: #fff;
  margin: 0px 18px;
  border: 0;
  flex: 100%;
  max-width: 100%;
  display: block;
  float: left; }
  @media (max-width: 769px) {
    .woocommerce-page .products .product {
      width: 48% !important;
      margin-bottom: 20px !important; } }
  @media (max-width: 575px) {
    .woocommerce-page .products .product {
      width: 100% !important; } }
  .woocommerce-page .products .product .attachment-woocommerce_thumbnail {
    margin: 10px 13px !important;
    width: 90%;
    border: 2px solid #F4F4F7;
    height: 370px;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    object-fit: contain; }
  .woocommerce-page .products .product a {
    text-decoration: none;
    color: #242934;
    font-size: 14px;
    line-height: 20px; }
    .woocommerce-page .products .product a:nth-child(2) {
      padding-left: 20px; }
  .woocommerce-page .products .product .woocommerce-loop-product__title {
    text-align: left;
    color: #242934;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.6px;
    margin-bottom: 4px;
    text-decoration: none !important;
    margin: 0px !important;
    min-height: 58px;
    padding: 0px 20px 0px 20px !important; }
    @media (max-width: 769px) {
      .woocommerce-page .products .product .woocommerce-loop-product__title {
        min-height: auto; } }
  .woocommerce-page .products .product .star-rating {
    display: none !important; }
  .woocommerce-page .products .product .price {
    padding: 0px 20px !important;
    text-align: left;
    color: #242934 !important;
    font-size: 22px !important;
    line-height: 26px;
    letter-spacing: 1.28px;
    font-weight: bold !important; }
    .woocommerce-page .products .product .price ins {
      text-decoration: none; }
  .woocommerce-page .products .product:hover .woocommerce-loop-product__title {
    color: #0EB1A4; }
  .woocommerce-page .products .product .new-badge {
    background: #FFB600;
    color: #fff;
    font-size: 15px;
    line-height: 24px;
    padding: 5px 20px;
    position: absolute;
    left: 0px;
    top: 15px;
    z-index: 2; }
  .woocommerce-page .products .product .onsale {
    background: #ff3c00 !important;
    border-radius: 0 !important;
    left: 0 !important;
    width: 91px;
    top: 60px !important;
    margin: 0 !important;
    height: 34px; }
  .woocommerce-page .products .product:hover {
    box-shadow: 0px 3px 10px #E1ECEC; }
  .woocommerce-page .products .product .category-list {
    padding: 0px 20px 10px 20px !important;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: .3px;
    font-weight: 400; }
  .woocommerce-page .products .product .description {
    text-align: left;
    color: #242934;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.56px;
    font-weight: normal;
    padding: 0px 20px 5px 20px !important; }
  .woocommerce-page .products .product .add_to_cart_button {
    display: none;
    position: absolute;
    top: 310px;
    right: 30px;
    width: 52px;
    height: 52px;
    background: transparent;
    font-size: 0;
    border-radius: 50% !important;
    padding: 0 !important; }
    .woocommerce-page .products .product .add_to_cart_button:after {
      content: '';
      background-image: url(../../assets/images/koszyk1.svg);
      width: 52px;
      height: 52px;
      position: absolute;
      z-index: 9;
      background-size: cover;
      transform: none;
      top: 0;
      left: 0; }
    .woocommerce-page .products .product .add_to_cart_button:hover::after {
      background-image: url(../../assets/images/koszyk2.svg); }
  .woocommerce-page .products .product .yith-wcqv-button {
    position: absolute;
    top: 310px;
    right: 30px;
    width: 52px;
    height: 52px;
    background: transparent;
    font-size: 0;
    border-radius: 50% !important;
    padding: 0 !important; }
    .woocommerce-page .products .product .yith-wcqv-button:after {
      content: '';
      background-image: url(../../assets/images/koszyk1.svg);
      width: 52px;
      height: 52px;
      position: absolute;
      z-index: 9;
      background-size: cover;
      transform: none;
      top: 0;
      left: 0; }
    .woocommerce-page .products .product .yith-wcqv-button:hover::after {
      background-image: url(../../assets/images/koszyk2.svg); }
  .woocommerce-page .products .product.product_cat-dla-dioz::before {
    content: '';
    background-image: url(../../assets/images/dioz-produkt.png);
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 9;
    background-size: cover;
    transform: none;
    top: 12px;
    right: 16px; }
  .woocommerce-page .products .product.product_cat-dla-dioz .new-badge {
    display: none; }

.woocommerce-page .woocommerce-products-header .page-title {
  color: #242934;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: "Encode Sans", sans-serif;
  font-weight: bold;
  margin-top: 33px;
  margin-bottom: 50px; }
  @media (max-width: 769px) {
    .woocommerce-page .woocommerce-products-header .page-title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin-bottom: 33px; } }

.woocommerce-page .woocommerce-result-count {
  display: none; }

.woocommerce-page .woocommerce-ordering {
  margin-bottom: 40px; }
  .woocommerce-page .woocommerce-ordering .orderby {
    border: 0;
    color: #242934;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.6px;
    width: auto;
    outline: none !important; }

#top .woocommerce-pagination {
  border: 0; }
  #top .woocommerce-pagination .page-numbers {
    border: 0;
    margin: 0;
    padding: 0;
    float: right; }
    #top .woocommerce-pagination .page-numbers li {
      border: 0;
      border-radius: 0;
      background-color: transparent;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center; }
      #top .woocommerce-pagination .page-numbers li span {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.6px;
        font-weight: bold;
        font-family: "Encode Sans", sans-serif;
        color: #242934; }
      #top .woocommerce-pagination .page-numbers li .prev.page-numbers {
        background: #242934;
        font-size: 0;
        width: 40px;
        height: 40px;
        position: relative; }
        #top .woocommerce-pagination .page-numbers li .prev.page-numbers:after {
          content: url(../../assets/images/menu.svg);
          transform: rotate(90deg);
          position: absolute;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center; }
      #top .woocommerce-pagination .page-numbers li .next.page-numbers {
        background: #242934;
        font-size: 0;
        width: 40px;
        height: 40px;
        position: relative; }
        #top .woocommerce-pagination .page-numbers li .next.page-numbers:after {
          content: url(../../assets/images/menu.svg);
          transform: rotate(-90deg);
          position: absolute;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center; }

@media (max-width: 769px) {
  .shop-sidebar {
    display: none; } }

@media (max-width: 769px) {
  .shop-sidebar.active {
    display: block; } }

.shop-sidebar ul {
  padding: 0;
  list-style: none; }
  .shop-sidebar ul li a {
    color: #242934;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.6px;
    font-weight: bold; }
  .shop-sidebar ul li ul {
    list-style: none;
    padding-left: 10px; }
    .shop-sidebar ul li ul li a {
      color: #757882;
      font-size: 15px;
      line-height: 24px;
      font-weight: normal; }
  .shop-sidebar ul li.current-menu-item > a {
    color: #0EB1A4; }
  .shop-sidebar ul li.dioz > a {
    position: relative; }
    .shop-sidebar ul li.dioz > a:after {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      background-size: cover;
      background-image: url(../../assets/images/dioz-logo.png);
      margin-left: 10px;
      position: absolute;
      left: 65px;
      top: 0; }

.shop-sidebar input[type=checkbox]::before, .shop-sidebar input[type=checkbox]::after {
  content: none; }

.wc-block-components-checkbox .wc-block-components-checkbox__input[type=checkbox] {
  border: 0;
  border-radius: 0; }

.wc-block-components-checkbox .wc-block-components-checkbox__mark {
  display: none; }

.wc-block-components-checkbox .wc-block-components-checkbox__input[type=checkbox]:focus {
  outline: none !important;
  outline-offset: 0 !important; }

.wc-block-components-price-slider__controls .wc-block-components-price-slider__amount {
  width: auto;
  max-width: 50% !important;
  border-radius: 0;
  border: 0;
  color: #757882;
  font-size: 15px; }

input.wc-block-formatted-money-amount.wc-block-components-formatted-money-amount.wc-block-price-filter__amount.wc-block-price-filter__amount--max.wc-block-form-text-input.wc-block-components-price-slider__amount.wc-block-components-price-slider__amount--max {
  text-align: right; }

.category-description {
  margin-top: 100px; }
  @media (max-width: 769px) {
    .category-description {
      margin-top: 40px; } }
  .category-description span {
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 13px; }

.wc-block-components-checkbox .wc-block-components-checkbox__input[type=checkbox] {
  border: 0px !important; }

.category-info-icons {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px; }
  @media (max-width: 1250px) and (min-width: 769px) {
    .category-info-icons {
      flex-wrap: wrap; } }
  @media (max-width: 769px) {
    .category-info-icons {
      margin-top: 20px;
      display: block; } }
  .category-info-icons .icon-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 30px; }
    @media (max-width: 1250px) and (min-width: 769px) {
      .category-info-icons .icon-info {
        margin-right: 0;
        width: 50%; } }
    @media (max-width: 769px) {
      .category-info-icons .icon-info {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px; } }
    .category-info-icons .icon-info img {
      margin-right: 10px; }
    .category-info-icons .icon-info .title {
      max-width: 190px; }
      @media (max-width: 769px) {
        .category-info-icons .icon-info .title {
          max-width: 100%; } }

.filters-mobile {
  display: none; }
  @media (max-width: 769px) {
    .filters-mobile {
      display: block;
      width: 100%;
      padding: 6px;
      text-align: center;
      border: 1px solid #ccc;
      font-size: 14px;
      margin-bottom: 15px; } }

.category-sidebar input[type=checkbox]:before {
  left: -2px;
  top: 2px;
  position: absolute; }

.category-sidebar input[type=checkbox]:focus {
  outline: none; }

.wc-block-attribute-filter__actions {
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 40px; }

.tax-product_cat.term-legowiska .shop-sidebar #block-40,
.tax-product_cat.term-legowiska .shop-sidebar #block-39, .tax-product_cat.term-akcesoria .shop-sidebar #block-40,
.tax-product_cat.term-akcesoria .shop-sidebar #block-39 {
  display: none; }

.cooperation-columns-wrapper {
  margin-top: 17px;
  margin-bottom: 100px; }
  .cooperation-columns-wrapper h2 {
    font-size: 22px;
    line-height: 32px;
    color: #242424;
    font-family: "Encode Sans", sans-serif;
    margin-bottom: 92px; }
    @media (max-width: 769px) {
      .cooperation-columns-wrapper h2 {
        margin-bottom: 30px; } }
  .cooperation-columns-wrapper .col-md-4 {
    text-align: center; }
    .cooperation-columns-wrapper .col-md-4 img {
      width: 100%; }
    .cooperation-columns-wrapper .col-md-4 .title h4 {
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      font-weight: bold;
      color: #242424;
      font-family: "Encode Sans", sans-serif;
      margin-top: 35px; }

.cooperation-columns4 {
  text-align: center; }
  .cooperation-columns4 h2 {
    margin-bottom: 60px; }
    @media (max-width: 769px) {
      .cooperation-columns4 h2 {
        margin-bottom: 30px; } }
  @media (max-width: 769px) {
    .cooperation-columns4 .col-md-3 {
      margin-bottom: 30px; } }
  .cooperation-columns4 .col-md-3 .number {
    font-size: 80px;
    line-height: 155px;
    letter-spacing: 3.2px;
    font-weight: bold;
    color: #242424;
    font-family: "Encode Sans", sans-serif;
    margin-bottom: 15px; }
    @media (max-width: 769px) {
      .cooperation-columns4 .col-md-3 .number {
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px; } }
  .cooperation-columns4 .col-md-3 h4 {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.7px;
    font-weight: bold;
    color: #242424;
    margin-bottom: 14px; }
  .cooperation-columns4 .col-md-3 .text {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.6px;
    color: #242424;
    min-height: 300px; }
    @media (max-width: 769px) {
      .cooperation-columns4 .col-md-3 .text {
        min-height: auto; } }

.wrapper-why-us-cooperation {
  margin: 130px 0px;
  text-align: center; }
  @media (max-width: 769px) {
    .wrapper-why-us-cooperation {
      margin: 40px 0px;
      text-align: center; } }
  .wrapper-why-us-cooperation .title {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-weight: bold;
    color: #242424;
    font-family: "Encode Sans", sans-serif;
    margin-bottom: 65px; }
    @media (max-width: 769px) {
      .wrapper-why-us-cooperation .title {
        margin-bottom: 20px; } }
  .wrapper-why-us-cooperation .icon {
    margin-bottom: 35px; }
    .wrapper-why-us-cooperation .icon .title {
      max-width: 305px;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.7px;
      font-weight: bold;
      color: #242424;
      font-family: "Encode Sans", sans-serif;
      margin: 15px auto; }

.xoo-cp-modal .xoo-cp-container {
  border: 0;
  border-radius: 0;
  padding: 40px; }
  @media (max-width: 769px) {
    .xoo-cp-modal .xoo-cp-container {
      padding: 25px; } }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-hdtxt {
    text-transform: none;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 44px;
    border-bottom: 1px solid #000; }
    @media (max-width: 769px) {
      .xoo-cp-modal .xoo-cp-container .xoo-cp-hdtxt {
        font-size: 24px;
        font-size: 24px; } }
  .xoo-cp-modal .xoo-cp-container span.xoo-cp-close {
    right: 35px;
    top: 35px; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-ths {
    display: none; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: 1px solid #757882 !important;
    padding-bottom: 25px;
    position: relative; }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pimg {
      max-width: 190px;
      max-height: 190px;
      flex: 20%; }
      @media (max-width: 769px) {
        .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pimg {
          flex: 100%;
          margin: 0 auto; } }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pimg img {
        max-width: 190px;
        height: auto; }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-ptitle {
      flex: 60%;
      margin-top: 20px;
      width: 100%;
      float: left; }
      @media (max-width: 769px) {
        .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-ptitle {
          flex: 100%;
          padding: 0; } }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-ptitle a {
        color: #242934;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.64px;
        font-weight: bold; }
        .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-ptitle a:hover {
          text-decoration: none; }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pprice {
      flex: 60%;
      position: absolute;
      left: 205px;
      top: 120px;
      text-align: left !important;
      padding-top: 0 !important; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pprice:after {
        content: ' (cena za sztukę)';
        font-size: 14px;
        color: #3f3f3fcc; }
      @media (max-width: 769px) {
        .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pprice {
          display: block;
          flex: 100%;
          left: 0;
          top: 0;
          position: relative; } }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pqty {
      position: absolute;
      left: 205px;
      top: 125px;
      text-align: left !important; }
      @media (max-width: 769px) {
        .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pqty {
          position: relative;
          left: 0;
          top: 0;
          text-align: left !important;
          padding: 0; } }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pqty .xoo-cp-qtybox {
        border: 0;
        max-width: 100%;
        height: 40px; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pqty .span.xcp-minus,
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pqty span.xcp-plus {
        background: #F2F3F7;
        border: 0;
        font-size: 15px;
        height: 40px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pqty span.xcp-minus {
        border: 0;
        background: #f2f3f7;
        width: 30px;
        height: 100%; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-pdetails .xoo-cp-pqty input[type=number].xoo-cp-qty {
        background: #F2F3F7;
        border: 0px;
        margin: 0px 5px;
        font-size: 15px;
        font-weight: bold;
        max-width: 50px;
        width: 50px; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-ptotal {
    position: absolute;
    right: 10px;
    bottom: 30px;
    font-weight: bold;
    font-size: 24px !important; }
    @media (max-width: 769px) {
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-ptotal {
        padding: 0 !important; } }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-remove {
    position: absolute;
    right: 0;
    top: 10%; }
    @media (max-width: 769px) {
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-remove {
        display: block !important; } }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-table-cont .xoo-cp-cart .xoo-cp-icon-close::before {
    content: '';
    width: 34px;
    height: 34px;
    display: block;
    font-weight: normal;
    font-family: "Encode Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/remove.svg); }
  .xoo-cp-modal .xoo-cp-container a.xoo-cp-empct {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 0;
    display: none; }
    .xoo-cp-modal .xoo-cp-container a.xoo-cp-empct .xoo-cp-icon-close {
      margin-left: 5px;
      font-size: 12px !important;
      font-weight: bold; }
    .xoo-cp-modal .xoo-cp-container a.xoo-cp-empct:hover {
      text-decoration: none;
      color: #000;
      font-weight: bold; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-total .xcp-totxt {
    text-transform: capitalize;
    color: #757882; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-total span.xcp-ctotal {
    font-weight: bold; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-table-bottom {
    padding: 0;
    margin-bottom: 30px; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-btns {
    flex-direction: row-reverse;
    margin-bottom: 40px; }
    @media (max-width: 769px) {
      .xoo-cp-modal .xoo-cp-container .xoo-cp-cart-btns {
        flex-direction: column; } }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-close.xcp-btn {
    max-width: 50%;
    background: #fff;
    color: #000;
    font-weight: bold;
    text-transform: none;
    border: 2px solid #000;
    width: 100%;
    font-size: 16px;
    letter-spacing: 0.6px;
    margin-left: 0; }
    @media (max-width: 769px) {
      .xoo-cp-modal .xoo-cp-container .xoo-cp-close.xcp-btn {
        max-width: 100%;
        margin-top: 15px; } }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-close.xcp-btn:hover {
      text-decoration: none;
      background-color: #00847B;
      border-color: #00847B;
      color: #fff; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-btn-vc.xcp-btn,
  .xoo-cp-modal .xoo-cp-container a.xoo-cp-btn-ch.xcp-btn {
    max-width: 50%;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.6px;
    background-color: #FFB600;
    text-transform: none;
    color: #fff;
    border-color: #FFB600; }
    @media (max-width: 769px) {
      .xoo-cp-modal .xoo-cp-container .xoo-cp-btn-vc.xcp-btn,
      .xoo-cp-modal .xoo-cp-container a.xoo-cp-btn-ch.xcp-btn {
        max-width: 100%; } }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-btn-vc.xcp-btn:hover,
    .xoo-cp-modal .xoo-cp-container a.xoo-cp-btn-ch.xcp-btn:hover {
      text-decoration: none;
      background-color: #00847B;
      border-color: #00847B;
      color: #fff; }
  .xoo-cp-modal .xoo-cp-container span.xcp-rel-head {
    text-align: left;
    text-transform: none;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 44px; }
  .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods {
    position: relative; }
    @media (max-width: 769px) {
      .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods {
        min-width: 100%; } }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods:after {
      content: '';
      background: #fff;
      width: 15px;
      height: 100%;
      position: absolute;
      display: block;
      right: 0;
      z-index: 9; }
    .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li {
      max-width: 50%;
      position: relative; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li a {
        width: 100%;
        display: block;
        max-width: 300px;
        color: #242934; }
        .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li a:hover {
          text-decoration: none; }
        .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li a img {
          width: 100%;
          height: auto;
          border: 2px solid #F2F3F7;
          margin-bottom: 15px; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li span.xoo-cp-rel-title {
        text-align: left;
        color: #242934;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li span.xoo-cp-rel-price {
        text-align: left; }
        .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li span.xoo-cp-rel-price span {
          font-size: 16px; }
      .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li .add_to_cart_inline .button {
        position: absolute;
        top: 220px;
        right: 10px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important; }
        @media (max-width: 575px) {
          .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li .add_to_cart_inline .button {
            top: 100px;
            width: 32px;
            height: 32px; } }
        .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li .add_to_cart_inline .button:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0;
          left: 0; }
          @media (max-width: 575px) {
            .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li .add_to_cart_inline .button:after {
              width: 32px;
              height: 32px; } }
        .xoo-cp-modal .xoo-cp-container .xoo-cp-rel-slider .xoo-cp-rel-prods li .add_to_cart_inline .button:hover::after {
          background-image: url(../../assets/images/koszyk2.svg); }

.yith-wcqv-wrapper .product {
  margin-top: 35px; }
  .yith-wcqv-wrapper .product .summary .product_meta {
    margin-bottom: 12px; }
    @media (max-width: 769px) {
      .yith-wcqv-wrapper .product .summary .product_meta {
        margin-bottom: 0; } }
    .yith-wcqv-wrapper .product .summary .product_meta a {
      pointer-events: none;
      color: #757882;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      font-family: "Encode Sans", sans-serif;
      text-decoration: none !important; }
      @media (max-width: 769px) {
        .yith-wcqv-wrapper .product .summary .product_meta a {
          font-size: 12px;
          line-height: 18px; } }
  .yith-wcqv-wrapper .product .summary .product_title.entry-title {
    max-width: 550px;
    color: #242934;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: "Encode Sans", sans-serif;
    margin-bottom: 9px;
    font-weight: bold; }
    @media (max-width: 769px) {
      .yith-wcqv-wrapper .product .summary .product_title.entry-title {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0;
        margin-top: 10px; } }
  @media (max-width: 769px) {
    .yith-wcqv-wrapper .product .summary .product-info-icons .icon-info .title {
      font-size: 16px;
      line-height: 22px; } }
  @media (max-width: 769px) {
    .yith-wcqv-wrapper .product .summary .product-info-icons .icon-info .subtitle {
      font-size: 12px;
      line-height: 14px; } }
  .yith-wcqv-wrapper .product .summary .woocommerce-product-rating .star-rating span::before {
    color: #FFB600; }
  .yith-wcqv-wrapper .product .summary .woocommerce-product-rating a.woocommerce-review-link {
    color: #757882;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px; }
  .yith-wcqv-wrapper .product .summary .qib-button-wrapper div.quantity.wqpmb_quantity {
    height: 50px; }
  .yith-wcqv-wrapper .product .summary .qib-button {
    margin: 0; }
    .yith-wcqv-wrapper .product .summary .qib-button .qib-button {
      width: 30px;
      height: 50px;
      background-color: #F2F3F7;
      border: 0;
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      font-size: 15px;
      line-height: 24px; }
    .yith-wcqv-wrapper .product .summary .qib-button .wqpmb_input_text {
      height: 50px !important;
      background-color: #F2F3F7 !important;
      border: 0 !important;
      margin: 0px 5px !important;
      color: #242934;
      font-weight: bold;
      font-family: "Encode Sans", sans-serif;
      font-size: 15px !important;
      line-height: 24px !important; }
  .yith-wcqv-wrapper .product .summary .single_add_to_cart_button {
    margin-left: 15px;
    width: 50%;
    background-color: #FFB600 !important;
    height: 50px;
    border-radius: 0;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.6px;
    font-family: "Encode Sans", sans-serif;
    font-weight: bold; }
    .yith-wcqv-wrapper .product .summary .single_add_to_cart_button:hover, .yith-wcqv-wrapper .product .summary .single_add_to_cart_button:active, .yith-wcqv-wrapper .product .summary .single_add_to_cart_button:focus {
      background-color: #FFCD51 !important; }
    @media (max-width: 769px) {
      .yith-wcqv-wrapper .product .summary .single_add_to_cart_button {
        width: 100%;
        margin-left: 0;
        margin-top: 20px; } }
  .yith-wcqv-wrapper .product .woocommerce-variation-price {
    margin-bottom: 40px; }
    @media (max-width: 769px) {
      .yith-wcqv-wrapper .product .woocommerce-variation-price {
        margin-bottom: 20px; } }
    .yith-wcqv-wrapper .product .woocommerce-variation-price .price {
      display: flex;
      flex-direction: column;
      color: #242934; }
      .yith-wcqv-wrapper .product .woocommerce-variation-price .price del {
        text-decoration: none;
        opacity: 1; }
        .yith-wcqv-wrapper .product .woocommerce-variation-price .price del span {
          color: #242934;
          font-size: 16px;
          font-family: "Encode Sans", sans-serif;
          line-height: 24px;
          letter-spacing: 0.3px;
          position: relative; }
          .yith-wcqv-wrapper .product .woocommerce-variation-price .price del span:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: #242934;
            top: 40%;
            position: absolute;
            left: 0; }
      .yith-wcqv-wrapper .product .woocommerce-variation-price .price ins,
      .yith-wcqv-wrapper .product .woocommerce-variation-price .price bdi {
        text-decoration: none;
        color: #FF3C00;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
        font-family: "Encode Sans", sans-serif;
        font-weight: bold; }
        .yith-wcqv-wrapper .product .woocommerce-variation-price .price ins span,
        .yith-wcqv-wrapper .product .woocommerce-variation-price .price bdi span {
          color: #FF3C00;
          font-size: 30px;
          line-height: 44px;
          letter-spacing: 1.2px;
          font-family: "Encode Sans", sans-serif;
          font-weight: bold; }
  .yith-wcqv-wrapper .product .variations th.label {
    display: none !important; }
  .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item {
    background-color: #f2f3f7 !important;
    border-radius: 0 !important;
    border: 0;
    outline: none !important;
    box-shadow: none !important;
    padding: 25px !important;
    height: 135px !important;
    width: 135px !important; }
    @media (max-width: 769px) {
      .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item {
        height: 65px !important;
        width: 65px !important;
        padding: 0px !important; } }
    .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item .variable-item-contents {
      position: relative;
      justify-content: center;
      align-items: center; }
      .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item .variable-item-contents::before {
        content: '';
        width: 40px;
        height: 40px;
        background: url(../../assets/images/size-icon.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: -6px;
        background-position: center; }
        @media (max-width: 769px) {
          .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item .variable-item-contents::before {
            height: 25px; } }
    .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item .variable-item-span {
      margin-top: 20px; }
      @media (max-width: 769px) {
        .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item .variable-item-span {
          margin-top: 0; } }
      .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item .variable-item-span::before {
        content: 'Opakowanie';
        position: absolute;
        bottom: 17px;
        text-align: left;
        left: -5px;
        right: 0; }
        @media (max-width: 769px) {
          .yith-wcqv-wrapper .product .variations .variable-items-wrapper .variable-item .variable-item-span::before {
            display: none; } }
  .yith-wcqv-wrapper .product .reset_variations {
    display: none !important; }
  .yith-wcqv-wrapper .product .wpgs-nav {
    display: none; }
  .yith-wcqv-wrapper .product .wpgs-for {
    display: block; }
    .yith-wcqv-wrapper .product .wpgs-for div {
      display: none; }
      .yith-wcqv-wrapper .product .wpgs-for div:nth-child(1) {
        display: block; }
  .yith-wcqv-wrapper .product .product-info-icons {
    margin-bottom: 30px; }

.yith-wcqv-wrapper #yith-quick-view-close {
  color: #000;
  border: 0;
  opacity: 1;
  font-size: 22px; }

#yith-quick-view-modal .yith-wcqv-main {
  box-shadow: none !important; }

#yith-quick-view-modal .yith-wcqv-wrapper {
  min-height: 750px; }

#yith-quick-view-modal.open .yith-wcqv-main {
  overflow-y: hidden !important; }
  @media (max-width: 769px) {
    #yith-quick-view-modal.open .yith-wcqv-main {
      overflow-y: scroll !important; } }

.page-id-15021 .main.first {
  padding-top: 140px !important; }
  @media (max-width: 769px) {
    .page-id-15021 .main.first {
      padding-top: 110px !important; } }

.dla_dioz {
  padding: 0;
  max-height: 620px;
  height: 100%;
  overflow: hidden; }
  @media (max-width: 769px) {
    .dla_dioz {
      max-height: 100%; } }
  .dla_dioz .row.no-gutters {
    background-color: #77151C; }
  .dla_dioz .col-md-6.right {
    background-color: #77151C;
    position: relative; }
    @media (max-width: 769px) {
      .dla_dioz .col-md-6.right {
        padding: 50px 0; } }
    .dla_dioz .col-md-6.right img {
      object-fit: contain;
      object-position: top right; }
    .dla_dioz .col-md-6.right .content {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      max-width: 530px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0 auto;
      left: 0; }
      @media (max-width: 769px) {
        .dla_dioz .col-md-6.right .content {
          height: 100%;
          margin: 20px; } }
      .dla_dioz .col-md-6.right .content .h2 {
        color: #fff;
        margin-bottom: 32px; }
      .dla_dioz .col-md-6.right .content .text {
        margin-bottom: 53px;
        color: #fff; }
        @media (max-width: 769px) {
          .dla_dioz .col-md-6.right .content .text {
            margin-bottom: 20px; } }
        .dla_dioz .col-md-6.right .content .text P {
          font-size: 22px;
          line-height: 32px;
          letter-spacing: 0.8px;
          margin-bottom: 0;
          color: #fff; }
          @media (max-width: 769px) {
            .dla_dioz .col-md-6.right .content .text P {
              font-size: 18px;
              line-height: 24px; } }
      @media (max-width: 769px) {
        .dla_dioz .col-md-6.right .content .btn-primary, .dla_dioz .col-md-6.right .content .wp-block-button .wp-block-button__link, .wp-block-button .dla_dioz .col-md-6.right .content .wp-block-button__link {
          padding: 12px;
          width: 100%; } }
  .dla_dioz .col-md-6 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .dla_dioz.section2 {
    padding: 150px 0; }
    @media (max-width: 769px) {
      .dla_dioz.section2 {
        padding: 80px 0; } }
    .dla_dioz.section2 .title {
      text-align: center;
      margin-bottom: 73px; }
      .dla_dioz.section2 .title strong {
        color: #77151C; }
    .dla_dioz.section2 .col-md-4 {
      text-align: center; }
      @media (max-width: 769px) {
        .dla_dioz.section2 .col-md-4 {
          margin-bottom: 20px; } }
      .dla_dioz.section2 .col-md-4 .number {
        width: 117px;
        height: 117px;
        background-color: #77151C;
        margin-bottom: 25px;
        color: #fff;
        font-size: 68px;
        letter-spacing: 2.72px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold; }
      .dla_dioz.section2 .col-md-4 .h3 {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        margin: 0 auto 40px;
        max-width: 80%; }
        @media (max-width: 769px) {
          .dla_dioz.section2 .col-md-4 .h3 {
            font-size: 22px;
            line-height: 30px;
            letter-spacing: 0.8px; } }

.dark-bg-section {
  background-color: #242934;
  padding: 80px 0px;
  position: relative; }
  @media (max-width: 769px) {
    .dark-bg-section {
      padding: 30px 0px 30px 0px; } }
  .dark-bg-section::before {
    content: '1';
    color: #fff;
    opacity: 0.2;
    font-weight: bold;
    font-size: 300px;
    position: absolute;
    left: 10%;
    top: 160px; }
  .dark-bg-section .title {
    color: #fff;
    text-align: center;
    margin-bottom: 80px; }
  .dark-bg-section .row.icons {
    max-width: 1250px;
    margin: 0 auto;
    text-align: center; }
    .dark-bg-section .row.icons .text {
      font-size: 18px;
      line-height: 32px;
      color: #fff;
      text-align: center;
      letter-spacing: 0.72px;
      font-weight: bold;
      margin-top: 45px; }
      @media (max-width: 769px) {
        .dark-bg-section .row.icons .text {
          margin-top: 25px;
          margin-bottom: 45px; } }
      .dark-bg-section .row.icons .text p {
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.72px;
        font-weight: normal; }
      .dark-bg-section .row.icons .text button {
        background: transparent;
        border: 0;
        border-radius: 0;
        color: #FFF; }
    .dark-bg-section .row.icons .col-md-4:nth-child(1) .text {
      max-width: 280px; }
      @media (max-width: 769px) {
        .dark-bg-section .row.icons .col-md-4:nth-child(1) .text {
          max-width: 100%; } }

.dioz-bg-img {
  padding: 80px 0px;
  text-align: center;
  position: relative; }
  @media (max-width: 769px) {
    .dioz-bg-img {
      padding: 30px 0px 30px 0px; } }
  .dioz-bg-img:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #242934;
    display: block;
    position: absolute;
    opacity: 0.3;
    top: 0; }
  .dioz-bg-img .text {
    max-width: 856px;
    margin: 0 auto;
    color: #fff; }
    .dioz-bg-img .text p {
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      color: #fff; }
      @media (max-width: 769px) {
        .dioz-bg-img .text p {
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.8px; } }
      .dioz-bg-img .text p strong {
        display: block;
        margin: 40px 0px;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 1.28px; }
        @media (max-width: 769px) {
          .dioz-bg-img .text p strong {
            font-size: 22px;
            line-height: 30px;
            letter-spacing: 0.8px; } }
    .dioz-bg-img .text h3 {
      font-size: 70px;
      line-height: 44px;
      letter-spacing: 2.8px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 47px; }
      @media (max-width: 769px) {
        .dioz-bg-img .text h3 {
          font-size: 35px;
          line-height: 38px;
          letter-spacing: 0.8px; } }
    .dioz-bg-img .text .btn-primary, .dioz-bg-img .text .wp-block-button .wp-block-button__link, .wp-block-button .dioz-bg-img .text .wp-block-button__link {
      margin-top: 36px;
      width: 60%; }

.red-bg-section {
  padding: 70px 0px 30px 0px;
  text-align: center;
  position: relative; }
  @media (max-width: 769px) {
    .red-bg-section {
      padding: 30px 0px 30px 0px; } }
  .red-bg-section::before {
    content: '2';
    color: #fff;
    opacity: 0.2;
    font-weight: bold;
    font-size: 300px;
    position: absolute;
    left: 10%;
    top: 160px; }
  .red-bg-section .text h2 {
    color: #fff;
    font-weight: bold;
    margin: 0 auto 47px auto; }
  .red-bg-section .text h3 {
    color: #fff;
    font-weight: bold;
    margin: 0 auto 35px auto;
    font-size: 32px;
    letter-spacing: 1.28px; }
  .red-bg-section .text p {
    max-width: 856px;
    color: #fff;
    margin: 0 auto 47px auto;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.8px; }
    @media (max-width: 769px) {
      .red-bg-section .text p {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.8px; } }
  .red-bg-section .images-wrap {
    width: 100%;
    display: block;
    margin: 0 auto 47px auto;
    overflow: hidden; }
    .red-bg-section .images-wrap img {
      width: 33%;
      float: left; }
  .red-bg-section ul {
    padding: 0;
    max-width: 635px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    list-style: none; }
    .red-bg-section ul li {
      width: 50%;
      float: left;
      color: #fff;
      font-weight: bold;
      margin: 0 auto 45px auto;
      font-size: 32px;
      letter-spacing: 1.28px;
      text-align: center !important; }
      @media (max-width: 769px) {
        .red-bg-section ul li {
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.8px; } }
      .red-bg-section ul li strong {
        font-size: 60px;
        line-height: 44px;
        letter-spacing: 2.4px;
        margin-bottom: 15px;
        display: block;
        text-align: center; }
        @media (max-width: 769px) {
          .red-bg-section ul li strong {
            font-size: 32px;
            line-height: 36px;
            letter-spacing: 0.8px; } }
  .red-bg-section.three::before {
    content: '3'; }

.dioz-last-section {
  padding: 70px 0px 70px 0px;
  position: relative; }
  @media (max-width: 769px) {
    .dioz-last-section {
      padding: 30px 0px 30px 0px; } }
  .dioz-last-section .title {
    color: #fff;
    text-align: center;
    margin-bottom: 80px; }
  .dioz-last-section .text p {
    color: #fff; }
  .dioz-last-section img {
    width: 100%; }
