.page-blog {
  .posts {
    @include md {
      padding: 0;
    }

    .entry {
      width: 48%;
      min-height: 530px;
      margin: 0;
      float: left;
      margin-bottom: 42px;
      position: relative;

      @include lg {
        width: 100%;
      }

      @include md {
        min-height: auto;
        width: 100%;
      }

      &:nth-child(odd) {
        margin-left: 16px;

        @include lg {
          margin-left: 0;
        }

        @include md {
          margin-left: 0;
        }
      }

      &:nth-child(even) {
        margin-right: 16px;

        @include lg {
          margin-right: 0;
        }

        @include md {
          margin-right: 0;
        }
      }

      .post-img {
        position: relative;
        margin-bottom: 30px;
        overflow: hidden;
        height: 290px;

        .tag-links {
          position: absolute;
          top: 6px;
          display: block;
          z-index: 2;
          font-size: 0;

          .tag {
            background-color: $secondary-color;
            color: $white;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.6px;
            font-family: $primary-font;
            padding: 10px;
            margin-right: 5px;
            text-decoration: none;
          }
        }

        img {
          width: 100%;
          object-fit: cover;
          height: 290px;
        }
      }

      h3 {
        font-size: 16px;
        line-height: 20px;

        a {
          font-size: 16px;
          line-height: 20px;
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          text-decoration: none;

          &:hover {
            text-decoration: none;
            color: $primary-color;
          }

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
          }
        }
      }

      p {
        color: #242934;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        font-family: $primary-font;
      }

      .readmore {
        display: inline-block;
        float: right;
        overflow: hidden;
        margin-top: 30px;

        &:after {
          content: '' !important;
          background-image: url(../../assets/images/menu.svg);
          width: 25px;
          height: 25px;
          position: absolute;
          z-index: 9;
          top: 10px;
          margin-left: 10px;
          background-size: cover;
          transform: rotate(-90deg);
        }
      }

      &:first-child {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 70px;

        .post-img {
          height: 480px;
          overflow: hidden;

          @include md {
            height: 290px;
          }

          img {
            height: 480px;

            @include md {
              height: 290px;
            }
          }
        }

        h3 {
          a {
            color: $secondary-color;
            font-size: 30px;
            line-height: 44px;
            letter-spacing: 1.2px;
            font-family: $primary-font;
            font-weight: bold;

            @include md {
              font-size: 16px;
              line-height: 20px;
              color: $secondary-color;
              font-weight: bold;
              font-family: $primary-font;
              text-decoration: none;
            }
          }
        }

        p {
          color: #757882;
          font-size: 15px;
          line-height: 34px;
          letter-spacing: 0.6px;
          font-family: $primary-font;

          @include md {
            color: #242934;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
          }
        }
      }

      &:hover {
        box-shadow: none;
        cursor: pointer;

        .post-img {
          img {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
          }
        }

        h3 {
          a {
            color: $primary-color;
          }
        }

        .readmore {
          background-color: $primary-hover;
        }
      }
    }
  }
}


.blog_sidebar {
  @include md {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ccc;
  }

  .wp-block-latest-posts {
    flex-direction: column;

    li {
      width: 100%;
      margin-bottom: 34px;

      .readmore {
        display: none;
      }

      .wp-block-latest-posts__post-excerpt {
        padding: 0;
        font-size: 14px;
        line-height: 20px;
      }

      .wp-block-latest-posts__post-title {
        min-height: auto;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.6px;
        color: $secondary-color;
        font-weight: bold;
        margin-top: 10px;
      }

      &:hover {
        box-shadow: none;

        .wp-block-latest-posts__post-title {
          color: $primary-color;
          text-decoration: none;
        }

      }

      &:nth-child(2) {
        width: 100%;
        margin: 34px 0;
      }
    }
  }

  .widget_search {
    background-color: $secondary-color;
    padding: 18px 22px;
    margin-bottom: 32px;

    .wp-block-search {
      width: 100%;

      &__input {
        border-radius: 0;
        height: 40px;
        border: 0;
        outline: none;
        padding: 5px 15px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.56px;
        text-transform: lowercase;
        color: #757882;
      }

      &__button {
        height: 40px;
        width: 40px;
        font-size: 0;
        border: 0;
        background-color: #fff;
        margin: 0;
        position: relative;

        &::before {
          content: url(../../assets/images/search.svg);
          position: absolute;
          right: 6px;
          top: 5px;
        }
      }
    }
  }

  .widget_nav_menu {
    margin-bottom: 130px;

    @include lg {
      margin-bottom: 30px;
    }

    @include md {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 30px;
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        a {
          color: $secondary-color;
          font-size: 15px;
          line-height: 24px;
          font-family: $primary-font;
          font-weight: bold;
        }

        &.current-menu-item,
        &.current-menu-parent {
          >a {
            color: $primary-color;
          }
        }
      }

      .sub-menu {
        list-style: none;
        padding-left: 10px;
        margin: 10px 0px;

        li {
          a {
            color: #757882;
            font-weight: normal;
          }

          &.current-menu-item {
            >a {
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  .blog-posts-sidebar {
    h2 {
      margin-bottom: 20px;
    }

    h3 {
      color: $secondary-color;
      font-size: 15px;
      line-height: 24px;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 34px;
      text-decoration: none;
    }
  }
}


.result-post {
  width: 48%;
  min-height: 530px;
  margin: 0;
  float: left;
  margin-bottom: 42px;

  @include lg {
    width: 100%;
  }

  @include md {
    min-height: auto;
    width: 100%;
  }

  &:nth-child(odd) {
    margin-left: 16px;

    @include lg {
      margin-left: 0;
    }

    @include md {
      margin-left: 0;
    }
  }

  &:nth-child(even) {
    margin-right: 16px;

    @include lg {
      margin-right: 0;
    }

    @include md {
      margin-right: 0;
    }
  }

  .post-img {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    height: 290px;

    .tag-links {
      position: absolute;
      top: 6px;
      display: block;
      z-index: 2;
      font-size: 0;

      .tag {
        background-color: $secondary-color;
        color: $white;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        font-family: $primary-font;
        padding: 10px;
        margin-right: 5px;
        text-decoration: none;
        pointer-events: none;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 290px;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 20px;

    a {
      font-size: 16px;
      line-height: 20px;
      color: $secondary-color;
      font-weight: bold;
      font-family: $primary-font;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: $primary-color;
      }
    }
  }

  p {
    color: #242934;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: $primary-font;
  }

  .readmore {
    display: inline-block;
    float: right;
    overflow: hidden;
    margin-top: 30px;

    &:after {
      content: '' !important;
      background-image: url(../../assets/images/menu.svg);
      width: 25px;
      height: 25px;
      position: absolute;
      z-index: 9;
      top: 10px;
      margin-left: 10px;
      background-size: cover;
      transform: rotate(-90deg);
    }
  }

  &:first-child {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 70px;

    .post-img {
      height: 480px;
      overflow: hidden;

      @include md {
        height: 290px;
      }

      img {
        height: 480px;

        @include md {
          height: 290px;
        }
      }
    }

    h3 {
      a {
        color: $secondary-color;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
        font-family: $primary-font;
        font-weight: bold;

        @include md {
          font-size: 16px;
          line-height: 20px;
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          text-decoration: none;
        }
      }
    }

    p {
      color: #757882;
      font-size: 15px;
      line-height: 34px;
      letter-spacing: 0.6px;
      font-family: $primary-font;

      @include md {
        color: #242934;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
      }
    }
  }

  &:hover {
    box-shadow: none;
    cursor: pointer;

    .post-img {
      img {
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
      }
    }

    h3 {
      a {
        color: $primary-color;
      }
    }

    .readmore {
      background-color: $primary-hover;
    }
  }
}