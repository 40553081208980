/*--------------------------------------------------------------
# UNISZKI Theme Colors
--------------------------------------------------------------*/
$primary-color:         #0EB1A4;
$primary-hover:         #00847B;
$secondary-color:       #242934;
$third-color:           #FFB600;

$title-primary:         $secondary-color;


$text-primary:          #242424;
$text-secondary:        #757882; 


$light-text:            #CCCDD1;


$bg-color:              #F4F4F7;
$bg-light:              #E9F3FD;

$white:                 #fff;
$black:                 #000;
$black-1:               #171717;



$primary-font: 'Encode Sans', sans-serif;

