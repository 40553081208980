.xoo-cp-modal {
  .xoo-cp-container {
    border: 0;
    border-radius: 0;
    padding: 40px;

    @include md {
      padding: 25px;
    }

    .xoo-cp-hdtxt {
      text-transform: none;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 1.2px;
      line-height: 44px;
      border-bottom: 1px solid #000;

      @include md {
        font-size: 24px;
        font-size: 24px;
      }
    }

    span.xoo-cp-close {
      right: 35px;
      top: 35px;
    }

    .xoo-cp-cart-table-cont {
      .xoo-cp-cart {
        .xoo-cp-ths {
          display: none;
        }

        .xoo-cp-pdetails {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          border-bottom: 1px solid #757882 !important;
          padding-bottom: 25px;
          position: relative;

          .xoo-cp-pimg {
            max-width: 190px;
            max-height: 190px;
            flex: 20%;

            @include md {
              flex: 100%;
              margin: 0 auto;
            }

            img {
              max-width: 190px;
              height: auto;
            }
          }

          .xoo-cp-ptitle {
            flex: 60%;
            margin-top: 20px;
            width: 100%;
            float: left;

            @include md {
              flex: 100%;
              padding: 0;
            }

            a {
              color: #242934;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.64px;
              font-weight: bold;

              &:hover {
                text-decoration: none;
              }
            }
          }

          .xoo-cp-pprice {
            flex: 60%;
            position: absolute;
            left: 205px;
            top: 120px;
            text-align: left !important;
            padding-top: 0 !important;

            &:after {
              content: ' (cena za sztukę)';
              font-size: 14px;
              color: #3f3f3fcc;
            }

            @include md {
              display: block;
              flex: 100%;
              left: 0;
              top: 0;
              position: relative;
            }
          }

          .xoo-cp-pqty {
            position: absolute;
            left: 205px;
            top: 125px;
            text-align: left !important;

            @include md {
              position: relative;
              left: 0;
              top: 0;
              text-align: left !important;
              padding: 0;
            }

            .xoo-cp-qtybox {
              border: 0;
              max-width: 100%;
              height: 40px;
            }

            .span.xcp-minus,
            span.xcp-plus {
              background: #F2F3F7;
              border: 0;
              font-size: 15px;
              height: 40px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            span.xcp-minus {
              border: 0;
              background: #f2f3f7;
              width: 30px;
              height: 100%;
            }

            input[type=number].xoo-cp-qty {
              background: #F2F3F7;
              border: 0px;
              margin: 0px 5px;
              font-size: 15px;
              font-weight: bold;
              max-width: 50px;
              width: 50px;
            }
          }
        }

        .xoo-cp-ptotal {
          position: absolute;
          right: 10px;
          bottom: 30px;
          font-weight: bold;
          font-size: 24px !important;

          @include md {
            padding: 0 !important;
          }
        }

        .xoo-cp-remove {
          position: absolute;
          right: 0;
          top: 10%;

          @include md {
            display: block !important;
          }
        }

        .xoo-cp-icon-close {
          &::before {
            content: '';
            width: 34px;
            height: 34px;
            display: block;
            font-weight: normal;
            font-family: $primary-font;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(../../assets/images/remove.svg);
          }
        }
      }
    }

    a.xoo-cp-empct {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin: 0;
      display: none;

      .xoo-cp-icon-close {
        margin-left: 5px;
        font-size: 12px !important;
        font-weight: bold;
      }

      &:hover {
        text-decoration: none;
        color: #000;
        font-weight: bold;
      }
    }

    .xoo-cp-cart-total {
      .xcp-totxt {
        text-transform: capitalize;
        color: #757882;
      }

      span.xcp-ctotal {
        font-weight: bold;
      }
    }

    .xoo-cp-table-bottom {
      padding: 0;
      margin-bottom: 30px;
    }

    .xoo-cp-cart-btns {
      flex-direction: row-reverse;
      margin-bottom: 40px;

      @include md {
        flex-direction: column;
      }
    }

    .xoo-cp-close.xcp-btn {
      max-width: 50%;
      background: #fff;
      color: #000;
      font-weight: bold;
      text-transform: none;
      border: 2px solid #000;
      width: 100%;
      font-size: 16px;
      letter-spacing: 0.6px;
      margin-left: 0;

      @include md {
        max-width: 100%;
        margin-top: 15px;
      }

      &:hover {
        text-decoration: none;
        background-color: $primary-hover;
        border-color: $primary-hover;
        color: #fff;
      }
    }

    .xoo-cp-btn-vc.xcp-btn,
    a.xoo-cp-btn-ch.xcp-btn {
      max-width: 50%;
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.6px;
      background-color: $third-color;
      text-transform: none;
      color: #fff;
      border-color: $third-color;

      @include md {
        max-width: 100%;
      }

      &:hover {
        text-decoration: none;
        background-color: $primary-hover;
        border-color: $primary-hover;
        color: #fff;
      }
    }

    span.xcp-rel-head {
      text-align: left;
      text-transform: none;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 1.2px;
      line-height: 44px;
    }

    .xoo-cp-rel-slider {
      .xoo-cp-rel-prods {
        position: relative;

        @include md {
          min-width: 100%;
        }

        &:after {
          content: '';
          background: #fff;
          width: 15px;
          height: 100%;
          position: absolute;
          display: block;
          right: 0;
          z-index: 9;
        }

        li {
          max-width: 50%;
          position: relative;

          a {
            width: 100%;
            display: block;
            max-width: 300px;
            color: #242934;

            @include md {
              //max-width: 120px;
            }

            &:hover {
              text-decoration: none;
            }

            img {
              width: 100%;
              height: auto;
              border: 2px solid #F2F3F7;
              margin-bottom: 15px;
            }
          }

          span.xoo-cp-rel-title {
            text-align: left;
            color: #242934;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
          }

          span.xoo-cp-rel-price {
            text-align: left;

            span {
              font-size: 16px;
            }
          }

          .add_to_cart_inline {
            .button {
              position: absolute;
              top: 220px;
              right: 10px;
              width: 52px;
              height: 52px;
              background: transparent;
              font-size: 0;
              border-radius: 50% !important;
              padding: 0 !important;

              @include sm {
                top: 100px;
                width: 32px;
                height: 32px;
              }

              &:after {
                content: '';
                background-image: url(../../assets/images/koszyk1.svg);
                width: 52px;
                height: 52px;
                position: absolute;
                z-index: 9;
                background-size: cover;
                transform: none;
                top: 0;
                left: 0;

                @include sm {
                  width: 32px;
                  height: 32px;
                }

              }

              &:hover {
                &::after {
                  background-image: url(../../assets/images/koszyk2.svg);
                }
              }
            }
          }
        }
      }
    }
  }
}