.footer-wrapper {
  background: $secondary-color;
  color: $white;
  padding: 60px 0 30px 0px;
  position: relative;
  p, span {
    color: $white;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.56px;
    margin: 0;
    @include md {
      text-align: center;
    }
  }
  .widgettitle {
    color: $white;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 23px;
    margin-top: 52px;
    @include md {
      margin-top: 15px;
      text-align: center;
    }
  }
  .menu {
    list-style: none;
    padding: 0;
    li {
      line-height: 24px;
      margin-bottom: 14px;
      @include md {
        max-width: 100%;
        text-align: center;
      }
      a {
        color: $white;
        font-size: 15px;
        font-weight: 400;
        &:hover {
          text-decoration: underline;
          color: $white;
        }
      }
    }
  }
  .footer-column-text {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .footer-column1 {
    .wp-block-image {
      margin-bottom: 55px;
      @include md {
        margin-bottom: 25px;
      }
      img {
        width: 275px;
        height: auto;
        @include md {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
  .footer-column4 {
    p {
      margin: 0;
      line-height: 18px;
      @include md {
        text-align: center;
      }
    }
  }
  .footer-column5 {
    p {
      margin: 0;
      line-height: 28px;
      @include md {
        text-align: center;
      }
    }
    h2 {
      color: $white;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 23px;
      margin-top: 52px;
      @include md {
        margin-top: 15px;
          text-align: center;
      }
    }
    .wp-block-spacer {
      @include md {
        display: none;
      }
    }
  }
  .col-md-2 {
    @media(max-width:992px) {
      max-width: 50%;
      flex: 0 0 50%;
    }
    @include md {
      max-width: 100%;
        flex: 0 0 100%;
    }

    &:first-child {
      max-width: 22%;
      flex: 0 0 22%;
      @media(max-width:992px) {
        max-width: 100%;
        flex: 0 0 100%;
      }
      @include md {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
    &:last-child {
      @include md {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
      }
      .widget_media_image {
        display: inline-block;
        @include md {
          float: left;
        }
        img {
          margin-right: 11px;
          max-width: 80px;
        }
      }
      #block-6 {
        @include md {
          width: 100%;
        }
      }
    }
    .footer-column5 {
      .widget_media_image {

      }
    }
  }
}

.copyright {
  color: $light-text;
    font-size: 11px;
    line-height: 20px;
  p, span {
    color: $light-text;
    font-size: 11px;
    line-height: 20px;
  }
  @include md {
    text-align: center;
  }
  .footer-column-copyright {
    display: inline-block;
  }
}

.realization {
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include md {
    text-align: center;
    justify-content: center;
  }
  a {
    display: flex;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  p {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0;
  }
  img {
    margin-left: 15px;
  }
}

a.toTop {
  position: absolute;
  background: #0eb1a4;
  width: 50px;
  height: 50px;
  z-index: 4;
  right: 50px;
  top: -80px;
  border-radius: 50%;
  @include md {
    right: 20px;
    top: -15px;
  }
  &:after {
    content: url(../../assets/images/menu.svg);
    width: 50px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
  }
  &:hover {
    box-shadow: 0px 3px 10px #E1ECEC;
    &:after {
      height: 35px;
      transition: all 0.3s ease-in-out;
    }
  }
}


#menu-social-media {
  padding: 0;
  list-style: none;
  margin-top: 35px;
  margin-bottom: 45px;
  display: block;
   overflow: hidden;
  @include md {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  li {
    float: left;
    margin-right: 10px;
      a {
        font-size: 0;
        position: relative;
        width: 25px;
        height: 25px;
        display: block;
        &:before {
          content: '';
          display: block;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      &.facebook {
        a {
          &:before {
            background-image: url(../../assets/images/facebook.png);
          }
        }
      }
      &.instagram {
        a {
          &:before {
            background-image: url(../../assets/images/instagram.png);
          }
        }
      }
      &.tiktok {
        a {
          &:before {
            background-image: url(../../assets/images/tiktok.png);
          }
        }
      }
      &.youtube {
        a {
          &:before {
            background-image: url(../../assets/images/youtube.png);
          }
        }
      }
  }
}