.homepage-slider {
  height: 350px;
  margin-bottom: 60px;

  @include lg {
    margin-bottom: 25px;
  }

  @include md {
    margin-bottom: 20px;
    height: auto;
  }

  .col-md-12 {
    @include md {
      padding: 0;
    }
  }

  .desctop {
    @include md {
      display: none;
    }
  }

  .mobile {
    display: none;

    @include md {
      display: block;
      object-position: center center;
      object-fit: cover;
      width: 100%;
    }
  }

  .slide-img {
    display: none;

    &:first-child {
      display: block;
    }

    &.slick-slide {
      display: block;
    }

    img {
      height: 350px;

      @include md {
        height: auto;
        object-fit: contain;
      }

      @include lg {
        height: 250px;
        object-position: center center;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .slick-dots {
    @include lg {
      bottom: 8px;
    }

    @include md {
      bottom: -30px;
    }
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;

    @media(max-width:992px) {
      margin-bottom: 20px;
    }
  }

}

.home-under-slider {
  margin-bottom: 55px;

  @include md {
    padding: 0;
    margin-bottom: 25px;
  }

  .h2 {
    max-width: 415px;
    color: $secondary-color;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: $primary-font;
    margin: 0 auto 35px auto;
    text-align: center;

    @include md {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin: 0 auto 20px auto;
    }
  }

  .contact-text {
    p {
      max-width: 1076px;
      color: $secondary-color;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      font-family: $primary-font;
      text-align: center;
      margin: 0 auto 60px auto;

      @include md {
        font-size: 16px;
        line-height: 25px;
        margin: 0 auto 20px auto;
      }
    }
  }

  .icon-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $secondary-color;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    font-weight: bold;

    @include lg {
      margin-bottom: 15px;
    }

    @include md {
      margin-bottom: 15px;
    }

    img {
      margin-right: 10px;
    }
  }
}


.uniszki-bestsellers {
  overflow: hidden;
  display: block;
  background-color: $bg-color;
  padding: 84px 0;

  @include md {
    padding: 30px 0;
  }

  .wc-block-grid__products {
    max-width: 1296px;
    margin: 0 auto 58px auto;
    display: block;

    .wc-block-grid__product {
      padding: 10px;
      background-color: #fff;
      margin: 0px 18px;
      border: 0;
      flex: 100%;
      max-width: 100%;
      display: block;
      float: left;

      @include md {
        padding: 20px !important;
      }

      .wc-block-grid__product-image {
        margin: 0 0 13px 0;
        border: 2px solid $bg-color;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      a {
        text-decoration: none;
      }

      .wc-block-grid__product-title {
        min-height: 48px;
        text-align: left;
        color: $secondary-color;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        margin-bottom: 4px;
        text-decoration: none !important;
        margin: 0 0 10px 0;
      }

      .description {
        text-align: left;
        color: $secondary-color;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.56px;
        font-weight: normal;
        margin-bottom: 13px;
      }

      .add_to_cart_button {
        position: absolute;
        top: 275px;
        right: 20px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important;

        &:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0;

        }

        &:hover {
          &::after {
            background-image: url(../../assets/images/koszyk2.svg);
          }
        }
      }

      .wc-block-grid__product-price {
        text-align: left;
        color: $secondary-color;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 1.28px;
        font-weight: bold;
        margin: 10px 0px;
      }

      &:hover {
        .wc-block-grid__product-title {
          color: $primary-color;
        }
      }

      &:hover {
        .wc-block-grid__product-image {
          img {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  .slick-dots {
    @include lg {
      bottom: -40px;
    }
  }
}

.homepage_products {
  overflow: hidden;
  display: block;
  background-color: #f4f4f7;
  padding: 84px 0;

  h2 {
    text-align: center;
    margin-bottom: 45px;
  }

  .wp-block-button {
    text-align: center;
    margin-top: 45px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      background-color: #fff;
      margin: 0px 18px;
      border: 0;
      flex: 100%;
      max-width: 100%;
      display: block;
      float: left;
      position: relative;

      @include md {
        padding: 20px !important;
      }

      .category-list {
        text-align: left;
        color: $secondary-color;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.3px;
        font-weight: normal;
      }

      img {
        margin: 0 0 13px 0;
        border: 2px solid $bg-color;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        min-height: 330px;
        object-fit: contain;
      }

      a {
        text-decoration: none;
      }

      .woocommerce-loop-product__title {
        min-height: 48px;
        text-align: left;
        color: $secondary-color;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.6px;
        margin-bottom: 4px;
        text-decoration: none !important;
        margin: 0 0 10px 0;
      }

      .description {
        text-align: left;
        color: $secondary-color;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.56px;
        font-weight: normal;
      }

      .add_to_cart_button {
        display: none;
      }

      .yith-wcqv-button {
        position: absolute;
        top: 275px;
        right: 20px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important;


        &:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0;

        }

        &:hover {
          &::after {
            background-image: url(../../assets/images/koszyk2.svg);
          }
        }
      }

      .star-rating {
        display: none;
      }

      .price {
        text-align: left;
        color: $secondary-color;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 1.28px;
        font-weight: bold;
        margin: 10px 0px;
      }

      &:hover {
        .woocommerce-loop-product__title {
          color: $primary-color;
        }
      }

      &.product_cat-dla-dioz {
        &::before {
          content: '';
          background-image: url(../../assets/images/dioz-produkt.png);
          width: 50px;
          height: 50px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 10px;
          right: 10px;
        }

        .new-badge {
          display: none;
        }
      }
    }
  }
}

.newsletter-section-wrapper {
  @include md {
    padding: 0;
    overflow: hidden;
  }
}

.wrapper-why-us-section {
  padding: 80px 0;
  height: 900px;
  background-size: cover;
  background-position: center;

  @media(max-width: 1199px) {
    height: auto;
    background-image: none !important;
  }

  .title {
    max-width: 415px;
    color: $secondary-color;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: $primary-font;
    margin: 0 auto 20px auto;
    font-weight: bold;
    text-align: center;
  }

  .row {
    justify-content: space-between;

    .col-sm-12 {

      &:first-child,
      &:nth-child(2) {
        margin-bottom: 114px;

        @media(max-width: 1199px) {
          margin-bottom: 20px;
        }
      }

      @media(max-width: 1199px) {
        margin-bottom: 20px;
      }
    }

    .icon {
      max-width: 330px;
      margin-bottom: rem(120px);

      @include md2 {
        max-width: 100%;
      }

      img {
        margin-bottom: 20px;
      }

      .title {
        max-width: 100%;
        margin: 0;
        color: $third-color;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.8px;
        text-align: left;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .opis {
        p {
          letter-spacing: 0.6px;
        }
      }
    }

    .col-lg-6 {
      &:nth-child(even) {
        justify-content: flex-end;
        display: flex;

        @include md {
          justify-content: flex-start;
        }
      }
    }
  }
}

.newsletter-wrapper {
  min-height: 340px;
  margin: 100px 0;
  display: flex;
  flex-direction: row;
  padding: 57px;
  position: relative;

  @include md {
    margin: 30px 0px;
  }

  &::after {
    content: url(../../assets/images/newsletter.png);
    position: absolute;
    right: 0;
    top: -28px;
    bottom: -7px;

    @include lg {
      top: auto;
      bottom: -7px;
    }

    @include md {
      content: none;
    }
  }

  @include md {
    flex-direction: column;
    padding: 25px;
  }

  .column {
    width: 38%;
    padding-right: 2%;

    @include md {
      width: 100%;
      display: block;
      overflow: hidden;
      padding-right: 0;
    }

    .title {
      p {
        color: $white;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 1.4px;
        text-align: left;
      }
    }

    .text {
      p {
        color: $white;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.7px;
        font-family: $primary-font;
      }
    }

  }
}

.index-blog-posts {
  .blog-post {
    padding: 0 0 43px 0;
    margin: 0px 15px;
    max-width: 30%;
    flex: 30%;

    @media(max-width:992px) {
      margin: 0 0 20px 0;
      max-width: 100%;
      flex: 100%;
    }

    .post-img {
      position: relative;
      margin-bottom: 30px;
      overflow: hidden;
      height: 280px;

      .tag-links {
        position: absolute;
        top: 6px;
        display: block;
        z-index: 4;
        font-size: 0;

        .tag {
          background-color: $secondary-color;
          color: $white;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.6px;
          font-family: $primary-font;
          padding: 10px;
          margin-right: 5px;
          text-decoration: none;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }

    .title {
      display: block;
      min-height: 46px;
      color: $secondary-color;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.6px;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 11px;
      padding: 0 20px;
      text-align: left;
    }

    .post-text {
      color: $secondary-color;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.5px;
      margin-bottom: 10px;
      padding: 0 20px;

      a {
        color: $secondary-color;
        text-decoration: none !important;
      }
    }

    .btn {
      display: block;
      overflow: hidden;
      float: right;
      position: relative;
      margin-top: 15px;
      width: 50%;
      justify-content: flex-end;
      left: auto;
      right: 0;
      position: absolute;
      color: $white !important;

      @include md {
        width: 90%;
        float: none;
        right: auto;
      }

      &:after {
        content: '';
        background-image: url(../../assets/images/menu.svg);
        width: 25px;
        height: 25px;
        position: absolute;
        z-index: 9;
        top: 10px;
        margin-left: 10px;
        background-size: cover;
        transform: rotate(-90deg);
      }
    }

    &:hover {
      box-shadow: 0px 3px 10px #E1ECEC;
      cursor: pointer;

      .wp-block-latest-posts__featured-image {
        img {
          transform: scale(1.2);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}



.opinie_slider {
  .title {
    text-align: center;
    margin-bottom: 54px;

    @include md {
      margin-bottom: 25px;
    }
  }

  .slide-item {
    background-color: $white;
    border: 2px solid #F5F5F5;
    padding: 85px 30px;
    text-align: center;
    margin-top: 50px !important;

    @include md {
      margin-top: 0px !important;
    }

    .h3 {
      color: $secondary-color;
      margin-bottom: 27px;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      font-weight: bold;
    }

    .text,
    p {
      color: $secondary-color;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.5px;
    }

    &.slick-center {
      box-shadow: 0px 3px 10px #E1ECEC;
      margin-top: 0 !important;
    }
  }

  .slick-slide {
    margin: 0 50px;

    @include md {
      margin: 0;
    }
  }

  .slick-list {
    margin: 0 -50px;

    @include md {
      margin: 0;
    }
  }

  .slick-dots {
    bottom: -10px;

    @include md {
      bottom: -35px;
    }
  }

  .slick-arrow {
    top: 50%;

    &.slick-prev {
      left: 25px;

      @include md {
        left: -15px;
      }
    }

    &.slick-next {
      right: 25px;

      @include md {
        right: -15px;
      }
    }
  }
}

.description-toggle__more {
  display: none;
  position: relative;
  float: right;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  font-family: $primary-font;
  color: $secondary-color;
  cursor: pointer;
  padding-right: 25px;

  @include md {
    display: block;
  }

  &:hover {
    color: $primary-color;
    text-decoration: none;
  }

  &:after {
    content: url(../../assets/images/menu.svg);
    filter: brightness(0);
    display: -ms-flexbox;
    display: flex;
    height: 25px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 3px;
    width: 10px;
  }

  &.active {
    .first {
      display: none;
    }

    .hide {
      display: block;
    }

    &:after {
      transform: rotate(180deg);
      top: -3px;
    }
  }
}