.yith-wcqv-wrapper {
  .product {
    margin-top: 35px;

    .summary {
      .product_meta {
        margin-bottom: 12px;

        @include md {
          margin-bottom: 0;
        }

        a {
          pointer-events: none;
          color: #757882;
          font-size: 22px;
          line-height: 32px;
          letter-spacing: 0.8px;
          font-family: $primary-font;
          text-decoration: none !important;

          @include md {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      .product_title.entry-title {
        max-width: 550px;
        color: $secondary-color;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
        font-family: $primary-font;
        margin-bottom: 9px;
        font-weight: bold;

        @include md {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0;
          margin-top: 10px;

        }
      }

      .product-info-icons .icon-info {
        .title {
          @include md {
            font-size: 16px;
            line-height: 22px;
          }
        }

        .subtitle {
          @include md {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .woocommerce-product-rating {
        .star-rating span::before {
          color: #FFB600;
        }

        a.woocommerce-review-link {
          color: #757882;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.5px;
        }
      }

      .qib-button-wrapper div.quantity.wqpmb_quantity {
        height: 50px;
      }

      .qib-button {
        margin: 0;

        .qib-button {
          width: 30px;
          height: 50px;
          background-color: #F2F3F7;
          border: 0;
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          font-size: 15px;
          line-height: 24px;
        }

        .wqpmb_input_text {
          height: 50px !important;
          background-color: #F2F3F7 !important;
          border: 0 !important;
          margin: 0px 5px !important;
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          font-size: 15px !important;
          line-height: 24px !important;
        }
      }

      .single_add_to_cart_button {
        margin-left: 15px;
        width: 50%;
        background-color: #FFB600 !important;
        height: 50px;
        border-radius: 0;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.6px;
        font-family: $primary-font;
        font-weight: bold;

        &:hover,
        &:active,
        &:focus {
          background-color: #FFCD51 !important;
        }

        @include md {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }

    .woocommerce-variation-price {
      margin-bottom: 40px;

      @include md {
        margin-bottom: 20px;
      }

      .price {
        display: flex;
        flex-direction: column;
        color: $secondary-color;

        del {
          text-decoration: none;
          opacity: 1;

          span {
            color: $secondary-color;
            font-size: 16px;
            font-family: $primary-font;
            line-height: 24px;
            letter-spacing: 0.3px;
            position: relative;

            &:after {
              content: '';
              width: 100%;
              height: 1px;
              background-color: $secondary-color;
              top: 40%;
              position: absolute;
              left: 0;
            }
          }
        }

        ins,
        bdi {
          text-decoration: none;
          color: #FF3C00;
          font-size: 30px;
          line-height: 44px;
          letter-spacing: 1.2px;
          font-family: $primary-font;
          font-weight: bold;

          span {
            color: #FF3C00;
            font-size: 30px;
            line-height: 44px;
            letter-spacing: 1.2px;
            font-family: $primary-font;
            font-weight: bold;
          }
        }
      }
    }

    .variations {
      th.label {
        display: none !important;
      }

      .variable-items-wrapper {
        .variable-item {
          background-color: #f2f3f7 !important;
          border-radius: 0 !important;
          border: 0;
          outline: none !important;
          box-shadow: none !important;
          padding: 25px !important;
          height: 135px !important;
          width: 135px !important;

          @include md {
            height: 65px !important;
            width: 65px !important;
            padding: 0px !important;
          }

          .variable-item-contents {
            position: relative;
            justify-content: center;
            align-items: center;

            &::before {
              content: '';
              width: 40px;
              height: 40px;
              background: url(../../assets/images/size-icon.png);
              background-size: contain;
              background-repeat: no-repeat;
              margin-bottom: -6px;
              background-position: center;

              @include md {
                height: 25px;
              }
            }
          }

          .variable-item-span {
            margin-top: 20px;

            @include md {
              margin-top: 0;
            }

            &::before {
              content: 'Opakowanie';
              position: absolute;
              bottom: 17px;
              text-align: left;
              left: -5px;
              right: 0;

              @include md {
                display: none;
              }
            }
          }
        }
      }
    }

    .reset_variations {
      display: none !important;
    }

    .wpgs-nav {
      display: none;
    }


    .wpgs-for {
      display: block;

      div {
        display: none;

        &:nth-child(1) {
          display: block;
        }
      }
    }

    .product-info-icons {
      margin-bottom: 30px;
    }
  }

  #yith-quick-view-close {
    color: #000;
    border: 0;
    opacity: 1;
    font-size: 22px;
  }
}

#yith-quick-view-modal .yith-wcqv-main {
  box-shadow: none !important;
}

#yith-quick-view-modal .yith-wcqv-wrapper {
  min-height: 750px;
}

#yith-quick-view-modal.open .yith-wcqv-main {
  overflow-y: hidden !important;

  @include md {
    overflow-y: scroll !important;
  }
}