.page-id-15021 {
  .main.first {
    padding-top: 140px !important;

    @include md {
      padding-top: 110px !important;
    }
  }
}

.dla_dioz {
  padding: 0;
  max-height: 620px;
  height: 100%;
  overflow: hidden;

  @include md {
    max-height: 100%;
  }

  .row.no-gutters {
    background-color: #77151C;
  }

  .col-md-6 {
    &.right {
      background-color: #77151C;
      position: relative;

      @include md {
        padding: 50px 0;
      }

      img {
        object-fit: contain;
        object-position: top right;
      }

      .content {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        max-width: 530px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto;
        left: 0;

        @include md {
          height: 100%;
          margin: 20px;
        }

        .h2 {
          color: #fff;
          margin-bottom: 32px;
        }

        .text {
          margin-bottom: 53px;
          color: #fff;

          @include md {
            margin-bottom: 20px;
          }

          P {
            font-size: 22px;
            line-height: 32px;
            letter-spacing: 0.8px;
            margin-bottom: 0;
            color: #fff;

            @include md {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }

        .btn-primary {
          @include md {
            padding: 12px;
            width: 100%;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.section2 {
    padding: 150px 0;

    @include md {
      padding: 80px 0;
    }

    .title {
      text-align: center;
      margin-bottom: 73px;

      strong {
        color: #77151C;
      }
    }

    .col-md-4 {
      text-align: center;

      @include md {
        margin-bottom: 20px;
      }

      .number {
        width: 117px;
        height: 117px;
        background-color: #77151C;
        margin-bottom: 25px;
        color: #fff;
        font-size: 68px;
        letter-spacing: 2.72px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
      }

      .h3 {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        margin: 0 auto 40px;
        max-width: 80%;

        @include md {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 0.8px;
        }
      }
    }
  }
}


.dark-bg-section {
  background-color: #242934;
  padding: 80px 0px;
  position: relative;

  @include md {
    padding: 30px 0px 30px 0px;
  }

  &::before {
    content: '1';
    color: #fff;
    opacity: 0.2;
    font-weight: bold;
    font-size: 300px;
    position: absolute;
    left: 10%;
    top: 160px;

  }

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 80px;
  }

  .row {
    &.icons {
      max-width: 1250px;
      margin: 0 auto;
      text-align: center;

      .text {
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.72px;
        font-weight: bold;
        margin-top: 45px;

        @include md {
          margin-top: 25px;
          margin-bottom: 45px;
        }

        p {
          font-size: 18px;
          line-height: 32px;
          color: #fff;
          text-align: center;
          letter-spacing: 0.72px;
          font-weight: normal;
        }
        button {
          background: transparent;
          border: 0;
          border-radius: 0;
          color: #FFF;
        }
      }

      .col-md-4 {
        &:nth-child(1) {
          .text {
            max-width: 280px;

            @include md {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

.dioz-bg-img {
  padding: 80px 0px;
  text-align: center;
  position: relative;

  @include md {
    padding: 30px 0px 30px 0px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #242934;
    display: block;
    position: absolute;
    opacity: 0.3;
    top: 0;
  }

  .text {
    max-width: 856px;
    margin: 0 auto;
    color: #fff;

    p {
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      color: #fff;

      @include md {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.8px;
      }

      strong {
        display: block;
        margin: 40px 0px;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 1.28px;

        @include md {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 0.8px;
        }
      }
    }

    h3 {
      font-size: 70px;
      line-height: 44px;
      letter-spacing: 2.8px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 47px;

      @include md {
        font-size: 35px;
        line-height: 38px;
        letter-spacing: 0.8px;
      }
    }

    .btn-primary {
      margin-top: 36px;
      width: 60%;
    }
  }
}

.red-bg-section {
  padding: 70px 0px 30px 0px;
  text-align: center;
  position: relative;

  @include md {
    padding: 30px 0px 30px 0px;
  }

  &::before {
    content: '2';
    color: #fff;
    opacity: 0.2;
    font-weight: bold;
    font-size: 300px;
    position: absolute;
    left: 10%;
    top: 160px;
  }

  .text {
    h2 {
      color: #fff;
      font-weight: bold;
      margin: 0 auto 47px auto;
    }

    h3 {
      color: #fff;
      font-weight: bold;
      margin: 0 auto 35px auto;
      font-size: 32px;
      letter-spacing: 1.28px;
    }

    p {
      max-width: 856px;
      color: #fff;
      margin: 0 auto 47px auto;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;

      @include md {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.8px;
      }
    }
  }

  .images-wrap {
    width: 100%;
    display: block;
    margin: 0 auto 47px auto;
    overflow: hidden;

    img {
      width: 33%;
      float: left;
    }
  }

  ul {
    padding: 0;
    max-width: 635px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    list-style: none;

    li {
      width: 50%;
      float: left;
      color: #fff;
      font-weight: bold;
      margin: 0 auto 45px auto;
      font-size: 32px;
      letter-spacing: 1.28px;
      text-align: center !important;

      @include md {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.8px;
      }

      strong {
        font-size: 60px;
        line-height: 44px;
        letter-spacing: 2.4px;
        margin-bottom: 15px;
        display: block;
        text-align: center;

        @include md {
          font-size: 32px;
          line-height: 36px;
          letter-spacing: 0.8px;
        }
      }
    }
  }

  &.three {
    &::before {
      content: '3';
    }
  }
}

.dioz-last-section {
  padding: 70px 0px 70px 0px;
  position: relative;

  @include md {
    padding: 30px 0px 30px 0px;
  }

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 80px;
  }

  .text {
    p {
      color: #fff;
    }
  }

  img {
    width: 100%;
  }
}