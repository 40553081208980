/*----------------------------------- */
// Breakpoints
/*----------------------------------- */
@mixin wide-screens {
  @media (min-width: #{$wide-width + 1}) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: #{$xxl-width})and(min-width: #{$md-width}) {
    @content;
  }
}

@mixin max-xxl {
  @media (max-width: #{$xxl-width + 1}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$lg-width})and(min-width: #{$md-width}) {
    @content;
  }
}

@mixin max-md {
  @media (max-width: #{$md-width + 1}) {
    @content;
  }
}

@mixin md2 {
  @media (max-width: #{$md2-width})and(min-width: #{$xs-width}) {
    @content;
  }
}


@mixin md {
  @media (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: #{$lg-width + 1}) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: #{$sm-width + 1}) {
    @content;
  }
}

@mixin min-xs {
  @media (min-width: #{$xs-width + 1}) {
    @content;
  }
}

@mixin md-h {
  @media (max-height: #{$md-height}) {
    @content;
  }
}


@function getImage($iconName, $iconFormat: "jpg") {
  @return "/wp-content/themes/uniszki/assets/images/#{$iconName}.#{$iconFormat}";
}
