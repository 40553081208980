#main {
  overflow: hidden;
}

.main {
  &.first {
    @media(max-width:992px) {
      padding-top: 136px !important;
    }
  }
}

.wp-block-buttons {
  justify-content: center;

  .wp-block-button {
    text-align: center;
  }
}

.wp-block-group__inner-container {
  .has-text-align-center.no-margin {
    @include md {
      max-width: 98%;
    }
  }
}

.flaticon-back {
  transform: rotate(90deg);

  &:before {
    content: url(../../assets/images/menu.svg) !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flaticon-right-arrow {
  transform: rotate(-90deg);

  &:before {
    content: url(../../assets/images/menu.svg) !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.row {
  @include lg {
    margin: 0;
  }
}

.small-12 {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
}

p.woocommerce-info.woocommerce-no-products-found {
  border-radius: 0;
  font-weight: bold;

  &::before {
    content: none;
  }
}

body {
  scroll-behavior: smooth;

  .slick-arrow {
    z-index: 9;
    width: 40px;
    height: 40px;
    top: 45%;
    background-color: $secondary-color;

    &:hover {
      background-color: $primary-color;
    }

    @include md {
      top: 40%;
    }

    &.slick-prev {
      left: 0;
      transform: rotate(90deg);

      &:before {
        opacity: 1;
        content: url(../../assets/images/menu.svg);
      }
    }

    &.slick-next {
      right: 0;
      transform: rotate(-90deg);

      &:before {
        opacity: 1;
        content: url(../../assets/images/menu.svg);
      }
    }
  }

  .slick-dots {
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include md {
      bottom: 15px;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 19px;
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          width: 14px;
          height: 14px;
          font-size: 0;
          opacity: 1;
          border-radius: 50%;
          top: 3px;
          background-color: #6BD9D1;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      &.slick-active {
        button {
          &::before {
            width: 19px;
            height: 19px;
            top: 0;
            background-color: #00847B;
          }
        }
      }
    }
  }

  &.overflow {
    overflow: hidden;
  }
}


.gray-bg {
  background-color: $bg-color;
  padding: 90px 0;
  margin-bottom: 80px;

  @include lg {
    padding: 55px 0;
    margin-bottom: 40px;
  }

  @include md {
    padding: 25px 0;
    margin-bottom: 30px;
  }

  &.seo_text-parent {
    margin-bottom: 0;

    .text {
      p {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.5px;
      }
    }

    .seo_text {
      overflow: hidden;

      @include md {
        height: 330px;
      }

      &.active {
        height: 100%;
      }
    }
  }
}

.bg-dark {
  background-color: #242934 !important;
}

.breadcrumbs {
  @include lg {
    padding: 0;
  }

  @include md {
    padding: 0;
  }

  .breadcrumb {
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    padding: 20px 0px;

    li {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.4px;
      font-family: $primary-font;
      color: $secondary-color;

      a {
        color: $secondary-color;
        text-decoration: none;

        &:hover {
          color: $primary-color;
        }
      }

      &.active {
        text-decoration: underline;
      }
    }
  }
}

.breadcrumb-item+.breadcrumb-item:before {
  content: ">";
  color: $secondary-color;
}

nav.woocommerce-breadcrumb {
  max-width: 1290px;
  margin: 0 auto !important;
  font-size: 12px !important;

  @media(max-width:992px) {
    margin: 0px 20px !important;
  }

  a {
    font-size: 12px;
    position: relative;
    color: $secondary-color;
    text-decoration: none;
    position: relative;
    padding-right: 5px;
    margin-right: 5px;

    &:after {
      content: '>';
      position: absolute;
      background: #fff;
      right: -15px;
      width: 15px;
      top: -3px;
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.history_timeline {
  overflow: hidden;

  .title-main {
    text-align: center;
    color: $primary-color;
    margin-bottom: 30px;

    @include md {}
  }

  .history_timeline-wrapper {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -2px;
      height: 100%;
      width: 2px;
      background-color: $primary-color;

      @include md {
        left: 0;
        margin-left: 20px;
      }
    }

    .cd-timeline-icon.first {
      display: block;
      position: absolute;
      background: $primary-color;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      left: 50%;
      margin-left: -16px;
      top: 0;

      @include md {
        left: 0;
        margin-left: 7px;
      }
    }

    .cd-timeline-icon.last {
      display: block;
      position: absolute;
      background: $primary-color;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      left: 50%;
      margin-left: -16px;
      bottom: 0;

      @include md {
        left: 0;
        margin-left: 7px;
      }
    }

    .cd-timeline-block {
      padding-left: 100px;
      margin: 4em auto;
      overflow: hidden;
      width: 100%;
      position: relative;

      @include lg {
        padding: 0;
      }

      @include md {
        padding: 0;
      }

      &:nth-child(odd) {
        margin-top: -270px;

        @include md {
          margin-top: 0;
        }
      }

      &.before-one {
        &::before {
          background-image: url(../../assets/images/icon-before.png);
          content: '';
          position: absolute;
          width: 60px;
          height: 100px;
          background-size: contain;
          background-repeat: no-repeat;
          top: 70px;
          left: 30px;

          @include md {
            content: none;
          }
        }
      }

      &.before-two {
        &::before {
          background-image: url(../../assets/images/icon-before.png);
          content: '';
          position: absolute;
          width: 60px;
          height: 100px;
          background-size: contain;
          background-repeat: no-repeat;
          top: 120px;
          left: 30px;

          @include md {
            content: none;
          }
        }
      }

      .cd-timeline-icon {
        width: 20px;
        height: 20px;
        left: 50%;
        margin-left: -11px;
        margin-top: 100px;
        background: $primary-color;
        border-radius: 50%;
        position: absolute;

        @include md {
          left: 0;
          margin-left: 11px;
        }
      }

      &:nth-child(odd) {
        .cd-timeline-content {
          float: right;
          align-items: flex-start;

          @include md {
            float: left;
          }
        }
      }

      .cd-timeline-content {
        width: 42%;
        margin: 0 3%;
        display: flex;
        flex-direction: column;

        @include md {
          width: 85%;
          margin: 0 0 0 15%;
        }

        img {
          margin-bottom: 50px;

          @include md {
            margin-bottom: 20px;
            height: auto;
            width: 100%;
            object-fit: cover;
          }
        }

        h3 {
          max-width: 415px;
          margin-bottom: 26px;
          font-weight: 400;

          @include md {
            margin-bottom: 15px;
          }
        }

        p {
          max-width: 415px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.navigation {
  width: 100%;
  display: block;
  overflow: hidden;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      a {
        width: 40px;
        height: 40px;
        font-weight: bold;
        color: #757882;
        padding: 10px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.6px;
        text-decoration: none;

        &:hover {
          color: $primary-color;
          text-decoration: none;
        }
      }

      &.active {
        a {
          color: $secondary-color;
          text-decoration: none;
        }
      }

      .btn.pagination {
        font-size: 0;
        background-color: $secondary-color;
        width: 40px;
        height: 40px;
        position: relative;
        border-radius: 0;
        transform: rotate(-90deg);
        padding: 0;

        &:before {
          width: 40px;
          height: 40px;
          opacity: 1;
          content: url(../../assets/images/menu.svg);
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 5px;
        }

        &:hover {
          background-color: $primary-color;
        }
      }

      &:first-child {
        .btn.pagination {
          transform: rotate(90deg);
        }
      }
    }
  }

}

.commentlist {
  list-style: none;
  margin: 0 auto;
  width: 100%;
  padding: 0;
}

.new-badge {
  background: #FFB600;
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  padding: 5px 20px;
  position: absolute;
  left: 0px;
  top: 25px;
  z-index: 2;
}


.wp-block-spacer {
  @include lg {
    height: 30px !important;
  }

  @include md {
    height: 30px !important;
  }
}

.wp-block-group__inner-container,
.wp-block-column {
  a {
    color: #000;

    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }
}

.wp-block-navigation {
  .wp-block-navigation-item {
    font-weight: bold;

    &:hover {
      color: $primary-color;
    }

    &.current-menu-item {
      a {
        color: $primary-color;
      }
    }
  }
}

.modal-content.review {
  border: 0;
  border-radius: 0;

  input,
  textarea {
    width: 100%;
    border: 0;
    border-radius: 0;
    padding: 7px;
  }

  .form-submit {
    #submit {
      border-radius: 0;
    }
  }

  .modal-title {
    color: $secondary-color;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: $primary-font;
    font-weight: bold;

    @include md {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
    }
  }

  .modal-body {
    background: #F2F3F7;
  }

  .modal-header {
    background: #F2F3F7;
  }
}

.woocommerce-page {
  @include md {
    .breadcrumbs {
      padding: 0 20px;
    }
  }
}

.woocommerce-checkout #payment div.payment_box {
  background: transparent;
  padding: 0;
}

.image-wrap {
  text-align: left;
  font-size: 14px;
  line-height: 20px;

  >a {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
}

.tag-links {
  .blue {
    background-color: $primary-color !important;
  }

  .yellow {
    background-color: $third-color !important;
  }
}

.woocommerce-message {
  background: #0eb1a4 !important;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;

  &::before,
  &::after {
    content: none !important;
  }
}

.wp-block-search__inside-wrapper {
  display: flex;
}

.woocommerce-error {
  background: #dc3023 !important;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;

  &::before,
  &::after {
    content: none;
  }
}

.wc-block-components-price-slider__range-input-wrapper:before {
  background: #000;
}

.wc-block-price-filter .wc-block-components-price-slider__range-input-wrapper {
  color: $primary-color;
}

.podziekowanie-section {
  height: 100vh;
  position: relative;

  &::before {
    content: '';
    background-image: url(../../assets/images/tlo.svg);
    width: 243px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @include md {
      width: 50px;
    }
  }

  &::after {
    content: '';
    background-image: url(../../assets/images/tlo.svg);
    width: 243px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    @include md {
      width: 50px;
      right: -20px;
    }
  }

  .content {
    max-width: 745px;
    margin: 0 auto;
    text-align: center;
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include md {
      width: 100%;
      padding: 0px 20px 0px 20px;
    }
  }

  .h2 {
    color: $primary-color;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: $primary-font;
    font-weight: bold;
    margin-bottom: 20px;

    @include md {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.8px;
    }
  }

  .text {
    p {
      color: $secondary-color;
      font-size: 26px;
      line-height: 44px;
      letter-spacing: 1.2px;
      font-family: $primary-font;
      font-weight: normal;
      margin-bottom: 20px;

      @include md {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.8px;
      }
    }

    img {
      @include md {
        width: 100%;
        height: auto;
      }
    }
  }

  .btn-primary {
    background-color: $third-color;

    @include md {
      max-width: 280px;
      line-height: 22px;
    }

    &:after {
      @include md {
        width: 30px;
        height: 30px;
        top: 20px;
      }
    }
  }
}

.page-template-page_thankyou {
  footer#footer {
    display: none;
  }

  header#masthead {
    display: none;
  }

  .main.first {
    padding: 0 !important;

    @include md {
      //padding-bottom: 100px;
    }
  }
}

.woocommerce-error::before,
.woocommerce-info::before,
.woocommerce-message::before {
  content: none !important;
}

div.grwf2-wrapper.wf2-embedded {
  z-index: 99 !important;
}

.error-wrapper {
  .entry-title {
    color: #0EB1A4;
    font-size: 170px;
    font-weight: bold;
    letter-spacing: 6px;

    @include md {
      font-size: 80px;
    }

    span {
      display: block;
      color: #242934;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      text-transform: capitalize;
    }
  }

  img {
    width: 100%;
  }

  a {
    color: #242934;
  }

  .entry-content {

    max-width: 415px;

    p {
      color: #242934;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin-bottom: 42px;
      font-weight: bold;
    }

    .btn {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .search-title {
    font-weight: normal !important;
    letter-spacing: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin-bottom: 10px !important;
  }

  .search-form {
    width: 100%;
    position: relative;
    border: 1px solid #b3b3b3;
    margin-bottom: 62px;

    &::before {
      content: '';
      background-image: url(../../assets/images/search.svg);
      position: absolute;
      right: 0px;
      top: 3px;
      width: 40px;
      height: 40px;
      display: block;
      z-index: 1;
      background-size: 80%;
      background-repeat: no-repeat;
    }

    label {
      margin: 0;
    }

    .search-field {
      width: 100%;
      border-radius: 0;
      height: 40px;
      border: 0;
      outline: none;
      padding: 5px 15px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.56px;
      text-transform: lowercase;
      color: #757882;
    }

    .search-submit {
      height: 40px;
      width: 47px;
      font-size: 0;
      border: 0;
      z-index: 3;
      background: transparent;
      margin: 0;
      position: relative;
      padding: 0 !important;
      float: right;

      &::after {
        display: none !important;
      }

      &:hover {
        background-color: #0eb1a487 !important;
      }
    }

    .data-header {
      margin-top: 20px;
    }

    .adress {
      a {
        color: #242934;
      }
    }
  }

  #menu-social-media {
    justify-content: flex-start;

    li {
      a {
        &:before {
          filter: invert(1);
        }
      }
    }
  }

  .row.d-flex.align-items-end {
    border-bottom: 3px solid #707070;
  }

  .data-header {
    padding-top: 60px;
  }
}


.woocommerce-Price-currencySymbol {
  margin-left: 7px;
}

.page-template-page_black_week {
  .black-week-banner {
    .slide-item {
      text-align: center;
      .mobile {
        display: none;
        @include md {
          display: block;
        }
      }
      .desctop {
        @include md {
          display: none;
        }
      }
    }
  }
  .wc-block-product-category {
    .wc-block-grid__product {
      padding: 10px;
      background-color: #fff;
      margin: 0px 18px;
      border: 0;
      flex: 100%;
      max-width: 100%;
      display: block;
      float: left;

      @include md {
        width: 48% !important;
        margin-bottom: 20px !important;
      }

      @include sm {
        width: 100% !important;
      }

      .attachment-woocommerce_thumbnail {
        margin: 10px 13px !important;
        width: 90%;
        border: 2px solid $bg-color;
        height: 370px;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        object-fit: contain;
      }

      a {
        text-decoration: none;
        color: $secondary-color;
        font-size: 14px;
        line-height: 20px;

        &:nth-child(2) {
          padding-left: 20px;
        }
      }

      .woocommerce-loop-product__title {
        text-align: left;
        color: $secondary-color;
        font-size: 16px !important;
        line-height: 24px;
        letter-spacing: 0.6px;
        margin-bottom: 4px;
        text-decoration: none !important;
        margin: 0px !important;
        min-height: 58px;
        padding: 0px 20px 0px 20px !important;

        @include md {
          min-height: auto;
        }
      }

      .star-rating {
        display: none !important;
      }

      .price {
        padding: 0px 20px !important;
        text-align: left;
        color: $secondary-color !important;
        font-size: 22px !important;
        line-height: 26px;
        letter-spacing: 1.28px;
        font-weight: bold !important;

        ins {
          text-decoration: none;
        }
      }

      &:hover {
        .woocommerce-loop-product__title {
          color: $primary-color;
        }
      }

      .new-badge {
        background: #FFB600;
        color: #fff;
        font-size: 15px;
        line-height: 24px;
        padding: 5px 20px;
        position: absolute;
        left: 0px;
        top: 15px;
        z-index: 2;
      }

      .onsale {
        background: #ff3c00 !important;
        border-radius: 0 !important;
        left: 0 !important;
        width: 91px;
        top: 60px !important;
        margin: 0 !important;
        height: 34px;
      }

      &:hover {
        box-shadow: 0px 3px 10px #E1ECEC;
      }

      .category-list {
        padding: 0px 20px 10px 20px !important;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: .3px;
        font-weight: 400;
      }

      .description {
        text-align: left;
        color: $secondary-color;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.56px;
        font-weight: normal;
        padding: 0px 20px 5px 20px !important;
      }

      .add_to_cart_button {
        display: none;
        position: absolute;
        top: 310px;
        right: 30px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important;

        &:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0;
          left: 0;

        }

        &:hover {
          &::after {
            background-image: url(../../assets/images/koszyk2.svg);
          }
        }
      }

      .yith-wcqv-button {
        position: absolute;
        top: 310px;
        right: 30px;
        width: 52px;
        height: 52px;
        background: transparent;
        font-size: 0;
        border-radius: 50% !important;
        padding: 0 !important;

        &:after {
          content: '';
          background-image: url(../../assets/images/koszyk1.svg);
          width: 52px;
          height: 52px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 0;
          left: 0;

        }

        &:hover {
          &::after {
            background-image: url(../../assets/images/koszyk2.svg);
          }
        }
      }

      &.product_cat-dla-dioz {
        &::before {
          content: '';
          background-image: url(../../assets/images/dioz-produkt.png);
          width: 50px;
          height: 50px;
          position: absolute;
          z-index: 9;
          background-size: cover;
          transform: none;
          top: 12px;
          right: 16px;
        }

        .new-badge {
          display: none;
        }
      }
    }
  }
}