.woocommerce-account .woocommerce {
  max-width: 1290px;
  margin: 0 auto;
  padding-top: 80px;
}

@media (max-width: 1024px) and (min-width: 320px) {
  .woocommerce-account .woocommerce {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}


.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
  min-height: 360px;
  margin-bottom: 100px !important;
  padding: 30px !important;
  background: $bg-color;
  border: 0 !important;
  border-radius: 0 !important;

  @include md {
    margin-bottom: 30px !important;
  }
}

.woocommerce-account .woocommerce .col2-set .col-1,
.woocommerce-account .woocommerce-page .col2-set .col-1,
.woocommerce-account .woocommerce .col2-set .col-2,
.woocommerce-account .woocommerce-page .col2-set .col-2 {
  flex: 50% !important;
  max-width: 50% !important;
}

@media (max-width: 1024px) and (min-width: 320px) {

  .woocommerce-account .woocommerce .col2-set .col-1,
  .woocommerce-account .woocommerce-page .col2-set .col-1,
  .woocommerce-account .woocommerce .col2-set .col-2,
  .woocommerce-account .woocommerce-page .col2-set .col-2 {
    flex: 100% !important;
    max-width: 100% !important;
  }
}

.woocommerce-page {
  &.woocommerce-account {
    .col2-set {
      width: 100% !important;
    }
  }
}

.woocommerce-MyAccount-navigation ul {
  padding: 0;
}

.woocommerce-MyAccount-navigation ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 35px;
  list-style: none;
}

.woocommerce-MyAccount-navigation ul li a {
  color: $secondary-color;
}

.woocommerce-MyAccount-navigation ul li a:hover {
  color: $primary-color;
  text-decoration: none;
}

.woocommerce-MyAccount-navigation ul li:before {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
}



.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  background-color: $bg-color;
  border-top: 0px !important;
  border-radius: 4px;

  @include md {
    padding: 20px !important;
  }
}

.woocommerce-info::before {
  color: $primary-color;
}


.flexible-shipping-notice-container {
  span {
    font-weight: bold;
  }

  .woocommerce-info::before {
    content: none;
  }

  .flexible-shipping-free-shipping-button {
    background-color: transparent !important;
    color: $primary-color !important;
    border: 2px solid $primary-color !important;
  }
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  padding: 15px 30px !important;
  color: #ffffff !important;
  background-color: $primary-color !important;
  border-radius: 0 !important;
}

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover {
  background-color: $primary-hover !important;
}



form.woocommerce-EditAccountForm.edit-account,
.woocommerce-address-fields {
  margin-bottom: 100px;
  padding: 30px;
  background: $bg-color;
  border-radius: 0;
}

.woocommerce {
  a {
    color: $primary-color;
  }
}

.woocommerce form .form-row {
  display: block;
}

.woocommerce {
  .form-row {
    input {
      border: 0;
      border-radius: 0;
      padding: 10px;
    }

    label {
      font-size: 14px;
      font-weight: bold;
      font-family: $primary-font;
      color: $secondary-color;
    }
  }

  .lost_reset_password {
    padding: 30px;
    background: $bg-color;
    border: 0;
    border-radius: 0;
    margin-bottom: 50px;
  }
}


input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 1px solid #27262c;
  margin-right: 10px;
  position: relative;
  margin-top: 0px;

  &:before {
    content: "";
    background: #fff;
    width: 22px;
    height: 22px;
    display: block;
    border: 1px solid $secondary-color;
    top: -1px;
    left: -1px;
    position: absolute;
  }

  &:checked {
    &:after {
      content: "";
      background: transparent;
      position: absolute;
      display: inline-block;
      vertical-align: text-top;
      width: 5px;
      height: 10px;
      border-radius: 0;
      top: 4px;
      left: 7px;
      border: 2px solid $secondary-color;
      border-top: none;
      border-left: none;
      transform: rotate(40deg);
    }
  }

  &:focus {
    outline: none;
    outline-offset: 0;
  }
}

.woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme {
  display: flex;
  width: 100%;
  align-items: center;
}


.woocommerce-order-received {
  table.woocommerce-table.woocommerce-table--order-details.shop_table.order_details {
    border: 0px;
    border-radius: 0;
  }

  .order_details {
    padding: 0;
  }

  ul.order_details li {
    border-right: 1px solid;

    @include md {
      margin-bottom: 20px;
      padding-right: 10px;
      margin-right: 10px;
    }
  }

  .woocommerce-order-details {
    .wc-item-meta {
      padding-left: 0;
    }
  }

  .woocommerce-order {
    p {
      display: none;
    }

    .woocommerce-order-details {
      p {
        display: block;
      }
    }
  }
}