.site-header {
  z-index: 999;
}

.navbar {
  &.primary-navbar {
    background: $secondary-color;
    padding: 0;
    display: block;
    z-index: 999;

    &.scrolled {
      @include md {
        top: 0 !important;
      }
    }

    .container {
      @include md {
        padding: 10px;
      }
    }

    @media(max-width:992px) {
      //padding: 15px;
    }

    .navbar-toggler {
      border: 0;
    }

    .navbar-brand {
      margin: 0;
      padding: 20px 0px;

      @media(max-width:992px) {
        flex: 30%;
        padding: 0;
      }

      img {
        @include lg {
          padding: 10px;
          max-width: 200px;
        }

        @include md {
          max-width: 155px;
        }

        @media(max-width: 400px) {
          max-width: 125px;
        }
      }
    }

    .top-main-navbar {
      margin-right: 20px;

      @media(max-width:1350px) {
        margin-right: 30px;
      }

      @media(max-width:992px) {
        flex: 40%;
        margin-right: 20px;
      }
    }

    .navbar-nav {
      margin: 0 auto;

      @media(max-width:992px) {
        flex-direction: row;
        justify-content: flex-end;
      }

      li {
        a {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.64px;
          color: $white !important;
          padding: 0px 22px;
          margin: 0px;

          @media(max-width:992px) {
            font-size: 10px;
            line-height: 15px;
            padding: 0px 6px;
          }

          &:hover,
          &:active,
          &:focus {
            color: $primary-color !important;
            text-decoration: none;
          }
        }

        &.active {
          a {
            color: $primary-color !important;
          }
        }

        &:last-child {
          a {
            padding-right: 0;
          }
        }

        &.my-account {
          a {
            &:before {
              content: '';
              width: 34px;
              height: 34px;
              background-size: cover;
              background-image: url(../../assets/images/paw.svg);
              filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
              -o-filter: brightness(0) invert(1);
              -ms-filter: brightness(0) invert(1);

              @media(max-width:992px) {
                width: 25px;
                height: 25px;
              }
            }
          }
        }

        &.logout {
          a {
            &:before {
              content: '';
              width: 34px;
              height: 34px;
              background-size: cover;
              background-image: url(../../assets/images/logout.svg);
              filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
              -o-filter: brightness(0) invert(1);
              -ms-filter: brightness(0) invert(1);

              @media(max-width:992px) {
                width: 25px;
                height: 25px;
              }
            }
          }
        }

        &.cart {
          a {
            &:before {
              width: 34px;
              height: 34px;
              background-size: cover;
              background-image: url(../../assets/images/cart.svg);
              content: '';

              @media(max-width:992px) {
                width: 25px;
                height: 25px;
              }
            }
          }
        }

        &.wpmenucartli {
          a {
            height: 100%;
            width: 100%;
            justify-content: space-between;

            &:before {
              width: 34px;
              height: 34px;
              background-size: cover;
              background-image: url(../../assets/images/cart.svg);
              content: '';

              @media(max-width:992px) {
                width: 25px;
                height: 25px;
              }
            }

            &:after {
              content: 'koszyk';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              font-size: 16px;
              line-height: 20px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: .64px;
              color: #fff !important;
              left: 8px;

              @media(max-width:992px) {
                font-size: 10px;
                line-height: 15px;
                padding: 0;
                position: initial;
              }
            }

            i {
              display: none;
            }
          }

          span.cartcontents {
            background: #FF3C00;
            border-radius: 50px;
            width: 20px;
            height: 20px;
            text-align: center;
            font-size: 12px;
            position: absolute;
            right: -7px;
            top: 0;

            @media(max-width:992px) {
              width: 15px;
              height: 15px;
              right: 0;
              font-size: 10px;
            }

            .small {
              display: none !important;
            }
          }
        }

        .wpmenucart-contents {
          &:hover {
            color: $white !important;
          }
        }
      }
    }
  }

  &.secondary-navbar {
    background: $primary-color !important;
    top: 140px;
    padding: 0;

    @media(max-width:992px) {
      top: 95px;
      padding: 7px 20px;
    }

    @media(max-width:600px) {
      top: 125px;
    }

    .navbar-collapse {
      @media(max-width:992px) {
        position: fixed;
        background: #0000009e;
        z-index: 999;
        left: 0;
        top: 108px;
        width: 100%;
        height: 100vh;
      }

      .navbar-nav {
        justify-content: space-between;
        width: 100%;

        @media(max-width:992px) {
          width: 80%;
          height: 100vh;
          background-color: $white;
          padding: 30px 0px;
          text-align: left;
          padding-bottom: 50vh;
          overflow: scroll;
        }

        li {
          position: initial;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            text-transform: uppercase;
            color: $white !important;
            letter-spacing: 0.64px;
            padding: 15px 20px;
            margin: 0px;

            @include lg {
              font-size: 13px;
              padding: 15px 10px;
            }

            @media(max-width:992px) {
              color: $black !important;
              justify-content: flex-start;
              font-size: 16px;
              line-height: 20px;
              padding: 15px 0;
              margin: 5px 20px;
              border-bottom: 1px solid $black;
              justify-content: space-between;
            }

            &:hover,
            &:active,
            &:focus {
              background-color: $primary-hover;
              text-decoration: none;

              @media(max-width:992px) {
                background-color: $white;
              }
            }

            .caret {
              margin-left: 10px;
            }
          }

          &.active {
            a {
              background-color: $primary-hover;

              @include md {
                background-color: $white;
                color: $primary-color !important;
              }
            }

            ul {
              li {
                a {
                  background-color: transparent !important;
                }
              }
            }
          }

          .dropdown-menu {
            box-shadow: 0px 3px 6px #5b5b7429;
            border: 0;
            border-radius: 0;
            width: 1450px;
            justify-content: flex-start;
            padding: 36px;
            margin: 0 auto;
            left: 0;
            right: 0;

            @media(max-width:1450px) {
              width: 100%;
              padding: 36px 0;
            }

            @include lg {
              width: 100%;
            }

            @media(max-width:992px) {
              display: block;
              box-shadow: none;
              width: 100%;
              padding: 0;
              margin-bottom: 15px;
            }

            @include md {
              display: block;
            }

            li {
              margin: 0px 30px;

              @media(max-width:992px) {
                margin: 0;
                padding-left: 10px;
              }

              a {
                font-size: 18px;
                text-transform: none;
                justify-content: flex-start;
                color: $text-primary !important;
                padding: 0;
                margin-bottom: 20px;

                @media(max-width:992px) {
                  width: auto;
                  border: 0;
                  font-size: 15px;
                  margin-bottom: 15px;
                }

                &:hover {
                  background-color: #fff;
                  color: $primary-color !important;
                }
              }

              .dropdown-menu {
                box-shadow: none;
                display: flex;
                width: auto;
                position: initial;
                text-align: left;
                align-items: flex-start;
                flex-direction: column;
                padding: 0;
                margin: 0;

                @media(max-width:992px) {
                  margin-bottom: 15px;
                }

                li {
                  margin: 0;
                  margin-bottom: 10px;

                  a {
                    font-size: 15px;
                    font-weight: normal;
                    line-height: 24px;
                    padding: 0;
                    margin-bottom: 0;
                  }

                  &.active {
                    a {
                      background-color: transparent;
                      color: $primary-color !important;
                    }
                  }
                }

                .dropdown-menu {
                  .menu-item {
                    &.active {
                      a {
                        background-color: transparent;
                        color: $primary-color !important;
                      }
                    }
                  }
                }

                .dropdown-submenu {
                  margin: 0;

                  a {
                    font-size: 15px;
                    font-weight: normal;
                    line-height: 24px;
                    padding: 0;
                    margin-bottom: 0;
                  }

                  &.active {
                    a {
                      background-color: transparent;
                      color: $primary-color !important;
                    }
                  }
                }
              }

              &.active {
                a {
                  background-color: transparent;
                  color: $primary-color !important;
                }
              }
            }

            &.show {
              display: flex;

              @media(max-width:992px) {
                display: block;
              }
            }
          }

          &.menu-item-has-children {
            &:hover {
              .dropdown-menu {
                display: flex;

                @media(max-width:992px) {
                  display: block;
                }
              }
            }
          }

          &.dioz {
            a {

              &:after {
                content: '';
                width: 22px;
                height: 22px;
                display: block;
                background-size: cover;
                background-image: url(../../assets/images/dioz-logo-white.png);
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .header-search {
    display: flex;
    width: 45%;

    @media(max-width:992px) {
      display: none;
      width: 80%;
    }

    @include md {
      overflow: scroll;
    }

    &.mobile {
      display: none;

      @media(max-width:992px) {
        display: block;

        .header-search {
          display: block;
        }
      }
    }

    .wp-block-search__inside-wrapper {
      display: flex;
    }

    .widget {
      width: 100%;

      .wp-block-search {
        width: 100%;

        &__input {
          width: 100%;
          border-radius: 0;
          height: 40px;
          border: 0;
          outline: none;
          padding: 5px 15px;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.56px;
          text-transform: lowercase;
          color: #757882;
        }

        &__button {
          height: 40px;
          width: 40px;
          font-size: 0;
          border: 0;
          background-color: #fff;
          margin: 0;
          position: relative;

          &::before {
            content: url(../../assets/images/search.svg);
            position: absolute;
            right: 6px;
            top: 5px;
            max-height: 40px;
          }
        }
      }
    }
  }

  .container-extra {
    width: 100%;
    display: block;
    overflow: hidden;
    background: #ffb600;
    text-align: center;

    p {
      color: #000;
      margin: 0;
      padding: 10px 0px;
      transition: all 0.3s ease-in-out;

      @media(max-width:992px) {
        max-width: 90%;
        margin: 0 auto;
        font-size: 13px;
        line-height: 18px;
      }
    }

    a {
      &:hover {
        text-decoration: none;

        p {
          color: #fff;
        }
      }
    }
  }
}

.navbar-toggler {
  padding: 0;

  .navbar-toggler-icon {
    background-image: url(../../assets/images/menu-toggle.svg) !important;
  }
}

// .navbar-toggler[aria-expanded="true"] {
//   .navbar-toggler-icon {
//     background-image: url(../../assets/images/toggle-close.svg);
//   }
// }




.logged-in {
  &.admin-bar {
    .primary-navbar {
      top: 32px;
    }
  }
}

.dropdown-toggle {
  &:after {
    content: url(../../assets/images/menu.svg);
    border: 0;
    vertical-align: unset;
    margin: 0;
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
    padding-top: 3px;

    @media(max-width:992px) {
      filter: brightness(0);
      transform: rotate(-90deg);
    }
  }
}

.woofc-menu-item {
  position: relative;

  span.woofc-menu-item-inner-subtotal {
    display: none;
  }

  &:after {
    content: 'koszyk';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .64px;
    color: #fff !important;
    padding: 0 22px;

    @media(max-width:992px) {
      font-size: 12px;
      line-height: 15px;
      padding: 0;
      position: initial;
    }
  }

  .woofc-icon-cart8 {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    justify-content: center;
    margin-left: 15px;
    text-align: center;
    align-items: center;

    @media(max-width:992px) {
      margin: 0;
    }

    &:before {
      width: 34px;
      height: 34px;
      background-size: cover;
      background-image: url(../../assets/images/cart.svg);
      content: '';

      @media(max-width:992px) {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.woofc-menu-item .woofc-menu-item-inner:after {
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: $primary-font;
  text-transform: uppercase;
  left: unset;
  right: -6px;
  top: 0px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF3C00;
  border-radius: 50%;
}