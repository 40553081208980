//
// Theme Variables
// --------------------------------------------------


/* content max-width */
$sch-container-xl: 1920px;
$sch-container-lg: 1130px;
$sch-container-md: 980px;
$sch-container-sm: 740px;

$layout-indent__width: 0px;
$layout-indent__width-xs: 20px;
