.wpcf7 {
  max-width: 680px;
  padding: 0;
  margin: 0 auto;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  p.full-input {
    overflow: hidden;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: column;

    label {
      width: 100%;
      display: block;
      overflow: hidden;
      text-align: left;
    }

    &.acceptance {
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .half-input {
    width: 48%;
    float: left;

    &:nth-child(odd) {
      margin-right: 2%;

      @include md {
        margin-right: 0;
      }
    }

    &:nth-child(even) {
      margin-left: 2%;

      @include md {
        margin-left: 0;
      }
    }

    &.last {
      margin-top: 80px;
      margin-bottom: 50px;
    }

    @include md {
      width: 100%;
    }
  }

  input,
  textarea {
    border: 0px;
    width: 100%;
    color: $text-secondary;
    padding: 12px 15px;
    border-radius: 0px;
    font-family: $primary-font;
    font-size: 14px;
    line-height: 17px;

    &::placeholder {
      color: $text-secondary;
      font-family: $primary-font;
    }

    &:focus {
      outline: none;
    }
  }

  input[type=submit] {
    width: auto;
    float: right;
    text-align: center;
    display: block;
    background-color: $primary-color;
    border: 0;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.6px;
    border-radius: 0;
    padding: 13px 55px;
    min-width: 195px;

    &:hover {
      text-decoration: none;
      background-color: $primary-hover;
    }
  }

  input[type="checkbox"] {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    margin: 0;
    opacity: 0;
    z-index: 1;

    +.wpcf7-list-item-label {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      width: 100%;
      display: block;

      @include md {
        padding-top: 0px;
        padding-left: 30px;
      }

      &::before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 18px;
        height: 18px;
        border-radius: 0;
        left: 0;
        top: 10px;
        transform: translateY(-50%);
        background: $white;
        border: 0;
        position: absolute;

        @include md {
          width: 24px;
          height: 24px;
        }
      }
    }

    &:checked+.wpcf7-list-item-label {
      &::before {
        content: '';
        background: $white;
      }

      &::after {
        content: '';
        background: transparent;
        position: absolute;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 5px;
        height: 10px;
        border-radius: 0;
        top: 3px;
        left: 7px;
        border: 2px solid $black;
        border-top: none;
        border-left: none;
        transform: rotate(40deg);

        @include md {
          left: 8px;
          height: 15px;
          top: 10px;
        }
      }
    }
  }

  span.wpcf7-list-item {
    margin: 0;
    float: left;
  }

  .text {
    display: block;
    margin-top: 0;
    float: left;
    font-size: 12px;
    font-weight: normal;
    font-family: $primary-font;

    @include md {
      text-align: left;
    }

    a {
      display: inline-block !important;
      text-decoration: underline;
      color: $black !important;

      &:hover {
        color: $primary-color;
      }
    }
  }

  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    position: relative;
    display: none;
  }
}

.wpcf7-not-valid-tip {
  text-align: left;
  font-size: 14px;
}

.contact-us-section {
  @include lg {
    padding: 0;
  }

  @include md {
    padding: 0;
  }

  .contact-text {
    max-width: 855px;
    font-size: 15px;
    line-height: 24px;
    color: $text-secondary;
    letter-spacing: 0.6px;
    font-family: $primary-font;
    margin-bottom: 80px;

    @include lg {
      margin-bottom: 30px;
    }

    @include md {
      margin-bottom: 30px;
    }
  }
}

.map-full-section {
  .row {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    .col-md-6 {
      padding: 0;

      @include md {
        padding: 15px;
      }
    }

    .data-header {
      max-width: 415px;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.6px;
      color: $secondary-color;
      font-weight: bold;
      margin-bottom: 24px;
      font-family: $primary-font;
    }

    .adress {
      max-width: 330px;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.5px;
      color: $secondary-color;
      margin-bottom: 24px;
      font-family: $primary-font;
    }

    p {
      margin: 0;
      color: $black-1;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.5px;

      a {
        color: $black-1;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  #menu-social-media {
    justify-content: flex-start;

    li {
      a {
        &:before {
          filter: invert(1);
        }
      }
    }
  }
}

.contact-mapa {
  margin-bottom: -8px;
}

.contact-wrapper {
  &.gray-bg {
    margin: 0;
  }
}

.col-lg-4.offset-lg-2.col-xxl-4.offset-xxl-2 {
  padding: 0;

  @include md {
    padding: 15px;
  }
}

.wp-block-columns.wp-container-4 {
  justify-content: center;
}