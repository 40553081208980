section.related.products {
  overflow: hidden;
  width: 100%;
}

.top-part {
  margin-top: 53px;

  @include md {
    margin-top: 0;
  }

  .row {
    @include md {
      padding: 0;
    }
  }

  .product {
    .onsale {
      background: #FF3C00;
      border-radius: 0;
    }

    .woocommerce-product-gallery {
      @include md {
        margin-bottom: 0;
      }

      .woocommerce-product-gallery__trigger {
        border: 2px solid #F2F3F7;
        max-height: 480px !important;
      }

      .flex-active-slide {
        max-height: 480px !important;
        border: 2px solid #F2F3F7;
      }
    }

    .summary {
      .product_meta {
        margin-bottom: 32px;

        @include md {
          margin-bottom: 0;
        }

        a {
          pointer-events: none;
          color: #757882;
          font-size: 22px;
          line-height: 32px;
          letter-spacing: 0.8px;
          font-family: $primary-font;
          text-decoration: none !important;

          @include md {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .product_title.entry-title {
        max-width: 550px;
        color: $secondary-color;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
        font-family: $primary-font;
        margin-bottom: 19px;
        font-weight: bold;

        @include md {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 0.6px;
        }
      }

      .woocommerce-product-rating {
        .star-rating span::before {
          color: #FFB600;
        }

        a.woocommerce-review-link {
          color: #757882;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.5px;
        }
      }

      .qib-button-wrapper div.quantity.wqpmb_quantity {
        height: 50px;
      }

      .qib-button {
        margin: 0;

        .qib-button {
          width: 30px;
          height: 50px;
          background-color: #F2F3F7;
          border: 0;
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          font-size: 15px;
          line-height: 24px;
        }

        .wqpmb_input_text {
          height: 50px !important;
          background-color: #F2F3F7 !important;
          border: 0 !important;
          margin: 0px 5px !important;
          color: $secondary-color;
          font-weight: bold;
          font-family: $primary-font;
          font-size: 15px !important;
          line-height: 24px !important;
        }
      }

      .single_add_to_cart_button {
        margin-left: 15px;
        width: 50%;
        background-color: #FFB600 !important;
        height: 50px;
        border-radius: 0;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.6px;
        font-family: $primary-font;
        font-weight: bold;

        @include md {
          padding: 15px 20px !important;
          font-size: 14px;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: #FFCD51 !important;
        }
      }
    }

    .woocommerce-tabs {
      max-width: 45%;

      @include md {
        max-width: 100%;
      }

      .tabs.wc-tabs {
        padding: 0;

        &::before {
          border-bottom: 3px solid #707070;
          z-index: 3;
        }

        li {
          border-radius: 0;
          border: 0;
          color: #242424;
          font-family: $primary-font;
          font-weight: bold;
          font-size: 18px;
          line-height: 32px;
          background-color: transparent;
          padding: 5px 50px !important;

          &.active {
            background: #00847B;
            border-radius: 0;
            border: 0;
            color: #fff;
            text-decoration: underline;
          }
        }
      }

      .product-info-text {
        ul {
          padding: 0;
          list-style: none;

          li {
            position: relative;
            padding-left: 25px;

            &:before {
              content: '';
              width: 6px;
              height: 6px;
              background: #00847b;
              display: block;
              position: absolute;
              left: 5px;
              top: 9px;
              border-radius: 50%;
            }
          }
        }

        table {
          td {
            border: 1px solid #e4e4e4;
            padding: 10px 15px;
          }
        }
      }
    }
  }

  .woocommerce-variation-price {
    margin-bottom: 40px;

    @include md {
      margin-bottom: 20px;
    }

    .price {
      display: flex;
      flex-direction: column;
      color: $secondary-color;

      del {
        text-decoration: none;
        opacity: 1;

        span {
          color: $secondary-color;
          font-size: 16px;
          font-family: $primary-font;
          line-height: 24px;
          letter-spacing: 0.3px;
          position: relative;

          &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $secondary-color;
            top: 40%;
            position: absolute;
            left: 0;
          }
        }
      }

      ins,
      bdi {
        text-decoration: none;
        color: #FF3C00;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.2px;
        font-family: $primary-font;
        font-weight: bold;

        span {
          color: #FF3C00;
          font-size: 30px;
          line-height: 44px;
          letter-spacing: 1.2px;
          font-family: $primary-font;
          font-weight: bold;
        }
      }
    }
  }

  .variations {
    th.label {
      display: none !important;
    }

    .variable-items-wrapper {
      .variable-item {
        background-color: #f2f3f7 !important;
        border-radius: 0 !important;
        border: 0;
        outline: none !important;
        box-shadow: none !important;
        padding: 25px !important;
        height: 135px !important;
        width: 135px !important;

        @include md {
          height: 85px !important;
          width: 85px !important;
          padding: 10px !important;
        }

        .variable-item-contents {
          position: relative;
          justify-content: center;
          align-items: center;

          &::before {
            content: '';
            width: 40px;
            height: 40px;
            background: url(../../assets/images/size-icon.png);
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: -6px;
            background-position: center;

            @include md {
              height: 25px;
            }
          }
        }

        .variable-item-span {
          margin-top: 20px;

          @include md {
            margin-top: 0;
          }

          &::before {
            content: 'Opakowanie';
            position: absolute;
            bottom: 17px;
            text-align: left;
            left: -5px;
            right: 0;

            @include md {
              display: none;
            }
          }
        }
      }
    }
  }

  .reset_variations {
    display: none !important;
  }
  .info-one {
    background: #ff3c00;
    text-align: center;
    padding: 20px;
    margin-bottom: 40px;
    .product-dioz-info {
      p {
        color: #fff;
        margin-bottom: 5px;
      }
      a {
        color: #ffc73b;
        text-decoration: none;
        &:hover {
          color: #000;
        }
      }
    }
  }
}

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .zoomImg {
  max-height: 480px !important;
  max-width: 620px !important;
}

.woocommerce div.product div.images .flex-control-thumbs {
  margin-top: 24px;
}

#product-comments {
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 50px;

  .slick-dots {
    display: none !important;
  }

  .slick-arrow {
    top: 45% !important;
  }
}

.reviews-wrapper {
  overflow: hidden;
  width: 100%;
  display: block;
  margin-bottom: 40px;
  margin-top: 40px;


  h3 {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: $primary-font;
    font-weight: bold;
    margin-bottom: 30px;

    @include md {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin-bottom: 30px;
    }
  }

  .centered-btn {
    text-align: center;
    margin-top: 0px;

    @include md {
      margin-top: 0px;
    }
  }

  .comment-text {
    .star-rating {
      width: 100%;
      height: 30px;
      float: none;
      margin: 0 auto;
      text-align: center;

      &::before {
        font-size: 23px;
        right: 0;
        margin: 0 auto;
      }

      span {
        color: #FFB600;
        font-size: 23px;
      }

      .rating {
        &::before {
          left: 0;
          right: 0;
        }
      }
    }
  }

  .review {
    margin-top: 50px !important;
    background-color: #f2f3f7;
    border: 2px solid #F5F5F5;
    padding: 35px;
    text-align: center;
    margin-top: 50px !important;

    @include md {
      margin-top: 0px !important;
    }

    .h3 {
      color: $secondary-color;
      margin-bottom: 27px;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.8px;
      font-weight: bold;
    }

    .text,
    p {
      color: $secondary-color;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.5px;
    }

    p {
      margin-bottom: 27px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    &.slick-center {
      margin-top: 0 !important;
    }
  }

  .slick-slide {
    margin: 0 35px;

    @include md {
      margin: 0;
    }
  }

  .slick-list {
    margin: 0 -35px;

    @include md {
      margin: 0;
    }
  }

  .slick-dots {
    bottom: -50px;
    left: 0;
    right: 0;

    @include md {
      bottom: -35px;
    }
  }
}

.woocommerce .star-rating span::before {
  right: 0;
}

.product-info-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 60px;

  .icon-info {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 135px;
    text-align: center;
    margin-right: 25px;

    .title {
      color: $secondary-color;
      font-size: 25px;
      font-weight: bold;
      line-height: 44px;
      letter-spacing: 0.5px;
      font-family: $primary-font;
      margin: 0;

      @include md {
        font-size: 22px;
        line-height: 30px;
      }
    }

    .subtitle {
      color: $secondary-color;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: 0.7px;
      margin: 0;
      font-family: $primary-font;

      @include md {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.delivery-info {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-top: 45px;

  @include md {
    padding-top: 15px;
  }

  .texts {
    margin-left: 11px;

    .title {
      color: $secondary-color;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0, 6px;
      margin-bottom: 0;
      font-weight: bold;
      font-family: $primary-font;
      margin-top: 5px;
    }

    .text {
      color: #757882;
      font-size: 14px;
      line-height: 17px;
      font-family: $primary-font;
      margin: 0;
    }
  }
}

.related {
  &.products {
    h2 {
      text-align: center;
      margin-bottom: 45px;
    }

    h3 {
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 1.2px;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 80px;
      text-align: center;

      @include md {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.8px;
        margin-bottom: 30px;
      }
    }
  }
}

.product-blog {
  h3 {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.2px;
    font-family: $primary-font;
    font-weight: bold;
    margin-bottom: 80px;
    text-align: center;

    @include md {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.8px;
      margin-bottom: 30px;
    }
  }
}

.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected {
  box-shadow: none !important;
  border: 2px solid $primary-color;
  color: #000;
  font-weight: bold;
  background-color: #fff !important;
}


.comment-form-cookies-consent {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  input[type=checkbox] {
    width: auto;
    margin-top: 6px;
    margin-right: 20px;

    &:focus {
      outline: none;
    }
  }
}

.btn-price {
  display: none;
  color: #ff3c00;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 1.2px;
  font-family: Encode Sans, sans-serif;
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: -20px;

  &.show {
    display: block;
  }

  ins {
    text-decoration: none;
  }

  del {
    font-weight: normal;
    color: #333 !important;
  }
}

.price {
  ins {
    text-decoration: none;
  }

  del {
    font-weight: normal;
    color: #333 !important;
  }
}

.product-template-default {
  .woocommerce-notices-wrapper {
    margin-top: 15px;

    .woocommerce-message {
      padding: 30px;

      @include md {
        display: flex;
        flex-direction: column-reverse;
      }

      .button {
        background: #fff;
        color: #000;
        margin-top: -10px;

        @include md {
          margin-top: 10px;
        }
      }
    }
  }
}

.single-product {
  &.legowiska {
    .top-part {
      .variations {
        .variable-items-wrapper {
          .variable-item {
            .variable-item-contents {
              &:before {
                width: 60px !important;
                height: 60px !important;
                background: url(../../assets/images/coach.png);

                @media(max-width:992px) {
                  width: 30px !important;
                  height: 30px !important;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }

              .variable-item-span {
                margin-top: 0;

                &:before {
                  content: "Rozmiar";
                  position: inherit;
                  text-align: center;
                  margin-right: 7px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.karma-w-puszkach,
  &.karma-dla-dioz {
    .top-part {
      .variations {
        .variable-items-wrapper {
          .variable-item {
            .variable-item-contents {
              &:before {
                width: 60px !important;
                height: 60px !important;
                background: url(../../assets/images/puszka.png);

                @media(max-width:992px) {
                  width: 30px !important;
                  height: 30px !important;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }

              .variable-item-span {
                margin-top: 0;

                &:before {
                  content: "Rozmiar";
                  position: inherit;
                  text-align: center;
                  margin-right: 7px;
                }
              }
            }
          }
        }
      }
    }

    .product-info-text {
      ul {
        li {
          &::before {
            background: url(../../assets/images/check.png) !important;
            width: 22px !important;
            height: 22px !important;
            background-size: contain !important;
            left: 0 !important;
            top: 4px !important;
            background-repeat: no-repeat;
          }
        }
      }

      .first-icons {
        ul {
          li {
            width: 50%;
            min-height: 55px;
            padding-left: 55px;
            float: left;
            font-weight: bold;

            @media(max-width:992px) {
              width: 100%;
            }

            &::before {
              background: url(../../assets/images/check.png) !important;
              width: 35px !important;
              height: 35px !important;
              background-size: contain !important;
              left: 0 !important;
              top: 0px !important;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  &.prezenty-dla-psiarzy-i-kociarzy {
    .top-part {
      .variations {
        .variable-items-wrapper {
          .variable-item {
            .variable-item-contents {
              &:before {
                content: none;
              }

              .variable-item-span {
                margin-top: 0;

                &:before {
                  content: "Kolor";
                  text-align: center;
                  margin-right: 5px;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  position: inherit;
                }
              }
            }
          }
        }
      }

      ul {
        li#tab-title-ingredients {
          display: none;
        }
      }
    }
  }

  &.cegielki.dla-dioz {
    .top-part {
      .variations {
        display: none !important;
      }
    }
  }

  &.pokrywki-na-puszki {
    .top-part {
      .variations {
        .variable-items-wrapper {
          .variable-item {
            padding: 12px !important;

            .variable-item-contents {
              &:before {
                content: none;
              }

              .variable-item-span {
                margin-top: 0;
                line-height: normal;
                padding: 0;

                &:before {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &.zestawy-puszek {
    .top-part {
      .variations {
        .variable-items-wrapper {
          .variable-item {
            .variable-item-contents {
              &:before {
                width: 60px !important;
                height: 60px !important;
                margin-bottom: 0;
                margin-top: -10px;
                background-position: top center;
                background: url(../../assets/images/puszka.png);

                @media(max-width:992px) {
                  width: 30px !important;
                  height: 30px !important;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }

              .variable-item-span {
                margin-top: 5px;
                line-height: initial;
                font-size: 15px;

                &:before {
                  content: "Zestaw";
                  position: inherit;
                  text-align: center;
                  margin-right: 7px;
                }
              }
            }
          }
        }
      }
    }
  }
}