a, button, .btn, input {
  transition: all 0.3s ease-in-out;
  outline: none;
}

.btn-primary,  input[type=submit] {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $white !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  border-radius: 0px;
  letter-spacing: 0.6px;
  padding: 12px 55px;
  position: relative;
  border: 0;
  padding-right: 75px;
  &:hover, &:active, &:focus {
    text-decoration: none;
    background: $primary-hover !important;
  }
  &:after {
    content: '' !important;
    background-image: url(../../assets/images/menu.svg);
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 9;
    top: 10px;
    margin-left: 10px;
    background-size: cover;
    transform: rotate(-90deg);
  }
  &.white {
    &:hover {
      text-decoration: none;
      color: $primary-color;
      background: $white;
    }
  }
}

.btn.btn-secondary {
  min-height: 70px;
  background: transparent;
  color: #242934;
  font-size: 16px;
  line-height: 20px;
  padding: 12px;
  font-weight: bold;
  border-radius: 0;
  border: 3px solid #0EB1A4;
  display: flex;
    justify-content: center;
    align-items: center;
    max-width: 260px;
    margin: 0 auto;
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: #fff;
    background: $primary-color !important;
  }
}


.page {
  .woocommerce button.button {
    border-radius: 0;
    font-family: $primary-font;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.6px;
    @include md {
      width: 100%;
    }
  }
}

.wp-block-button .wp-block-button__link {
  &:after {
    content: '';
    background-image: url(../../assets/images/menu.svg);
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 9;
    top: 10px;
    margin-left: 10px;
    background-size: cover;
    transform: rotate(-90deg);
    @include md {
      margin-left: 0;
    }
  }
  @include md {
    font-size: 14px;
    padding: 12px 25px 12px 15px;
  }
}